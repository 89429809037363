import React from 'react'
import {
    TrophyIcon,

} from '@heroicons/react/24/outline';
const Awards = () => {
    return (
        <div className="mt-12 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold dark:text-white">Awards & Recognition</h3>
            <div className="mt-4 space-y-4">
                <div className="flex items-start">
                    <TrophyIcon className="h-6 w-6 text-indigo-500" />
                    <div className="ml-3">
                        <p className="font-semibold dark:text-white">Top Rated Course</p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Our course is consistently rated as one of the top web development courses in the industry.
                        </p>
                    </div>
                </div>
                <div className="flex items-start">
                    <TrophyIcon className="h-6 w-6 text-indigo-500" />
                    <div className="ml-3">
                        <p className="font-semibold dark:text-white">Best Instructor Award</p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Our instructor has received numerous awards for excellence in teaching and mentorship.
                        </p>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Awards