import React, { useRef, useState, useEffect } from 'react';
import { FaGithub, FaLinkedin, FaTwitter, FaBriefcase, FaUserGraduate, FaAward } from 'react-icons/fa';
import { UsersIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import CountUp from 'react-countup'; // For animated counters
import './AboutMe.css';

export default function AboutMe() {
    const textRef = useRef(null);
    const sectionRef = useRef(null); // Reference to the section
    const [isVisible, setIsVisible] = useState(false); // State to track visibility

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                setIsVisible(entry.isIntersecting); // Update visibility state
            },
            { threshold: 0.1 } // Trigger when 10% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <div
            ref={sectionRef}
            className="relative bg-white dark:bg-black text-black dark:text-gray-300 overflow-hidden py-8 sm:py-16"
        >
            <motion.h1
                // className= " relative top-10 text-3xl sm:text-4xl lg:text-5xl font-bold tracking-tight text-center "
                style={{position:'relative', top:'-30px'}}
                className="text-3xl  sm:text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white text-center"
                initial={{ opacity: 0, y: -50 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.6, ease: 'easeOut' }}
            >
                Meet Your Expert Mentor
            </motion.h1>

            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                    <motion.div
                        className="relative flex items-center justify-center mb-8 md:mb-0"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={isVisible ? { opacity: 1, scale: 1 } : {}}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                        {/* Profile Image */}
                        <motion.img
                            src="https://cdn.buymeacoffee.com/uploads/profile_pictures/2023/11/PIXxBAJGpUj1wI7F.png@300w_0e.webp"
                            alt="Profile"
                            id='AboutMeLogo'
                            className="h-60 w-60 sm:h-80 sm:w-80 rounded-full object-cover shadow-2xl border-4 border-green-500"
                            initial={{ scale: 0.8 }}
                            animate={isVisible ? { scale: 1 } : {}}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                        />
                    </motion.div>

                    <motion.div
                        className="relative"
                        initial={{ opacity: 0, x: 50 }}
                        animate={isVisible ? { opacity: 1, x: 0 } : {}}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                        {/* Heading with Text Morphing Animation */}
                        <div className="text-3xl sm:text-4xl lg:text-5xl font-extrabold mb-6 relative overflow-hidden">
                            <span ref={textRef} className="block">
                                <span className="text-4xl sm:text-5xl lg:text-6xl text-gray-800 dark:text-gray-200">MuhilVanan</span>
                                <span className="block text-lg sm:text-xl lg:text-2xl font-medium text-gray-500 dark:text-gray-400 mt-2">
                                    DCSE, BCA, MCA
                                </span>
                            </span>
                        </div>

                        {/* Freelancer Quote */}
                        <motion.blockquote
                            className="text-lg sm:text-xl italic mb-8 border-l-4 border-green-500 pl-4"
                            initial={{ opacity: 0, x: -20 }}
                            animate={isVisible ? { opacity: 1, x: 0 } : {}}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                        >
                            "Empowering businesses with cutting-edge web solutions that drive success."
                        </motion.blockquote>

                        {/* Accomplishments */}
                        <motion.ul
                            className="space-y-6"
                            initial={{ opacity: 0, y: 20 }}
                            animate={isVisible ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                        >
                            {[
                                {
                                    icon: <FaBriefcase className="h-6 w-6 text-green-600 dark:text-green-400" />,
                                    text: (
                                        <span>
                                            <CountUp start={0} end={4} duration={2} suffix="+ years of experience" />
                                        </span>
                                    )
                                },
                                {
                                    icon: <FaUserGraduate className="h-6 w-6 text-green-600 dark:text-green-400" />,
                                    text: (
                                        <span>
                                            <CountUp start={0} end={5000} duration={2} />+ Students & Professionals
                                        </span>
                                    )
                                },
                                {
                                    icon: <FaAward className="h-6 w-6 text-green-600 dark:text-green-400" />,
                                    text: (
                                        <span>
                                            <CountUp start={0} end={250000} duration={2} suffix=" followers" />
                                        </span>
                                    )
                                },
                                {
                                    icon: <UsersIcon className="h-6 w-6 text-green-600 dark:text-green-400" />,
                                    text: (
                                        <span>
                                            <CountUp start={0} end={10000000} duration={2} suffix=" Global Fanbase" />
                                        </span>
                                    )
                                },
                            ].map((item, index) => (
                                <motion.li
                                    key={index}
                                    className="flex items-center text-lg"
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={isVisible ? { opacity: 1, x: 0 } : {}}
                                    transition={{ duration: 0.6, ease: 'easeOut', delay: index * 0.1 }}
                                >
                                    <div className="rounded-md bg-gray-100 dark:bg-gray-800 p-2 ring-1 mr-4 ring-green-500 dark:ring-green-400">
                                        {item.icon}
                                    </div>
                                    {item.text}
                                </motion.li>
                            ))}
                        </motion.ul>

                        {/* Skills Section */}
                        <motion.div
                            className="mt-8 space-y-6"
                            initial={{ opacity: 0, y: 20 }}
                            animate={isVisible ? { opacity: 1, y: 0 } : {}}
                            transition={{ duration: 0.6, ease: 'easeOut' }}
                        >
                            <h2 className="text-2xl sm:text-3xl font-bold mb-4">Skills</h2>
                            <div className="space-y-4">
                                {[
                                    { skill: 'HTML & CSS', percent: 99 },
                                    { skill: 'JavaScript', percent: 75 },
                                    { skill: 'React', percent: 70 },
                                    { skill: 'Node.js', percent: 35 },
                                ].map((skill, index) => (
                                    <div key={index} className="flex items-center">
                                        <div className="w-1/3 text-gray-800 dark:text-gray-400">{skill.skill}</div>
                                        <div className="w-2/3 bg-gray-200 dark:bg-gray-700 rounded-full overflow-hidden relative">
                                            <motion.div
                                                className="bg-green-500 text-xs font-medium text-white text-center p-2 leading-none rounded-full"
                                                initial={{ width: 0 }}
                                                animate={isVisible ? { width: `${skill.percent}%` } : {}}
                                                transition={{ duration: 3, delay: index * 0.5 }}
                                            >
                                                {/* Animate the percentage text */}
                                                <CountUp
                                                    start={0}
                                                    end={skill.percent}
                                                    duration={1}
                                                    suffix="%"
                                                    className="absolute inset-0 flex items-center text-black justify-center"
                                                />
                                            </motion.div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
}
