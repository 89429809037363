// CourseForm.jsx
import React, { useState } from 'react';
import { db, collection, addDoc } from "../../config/config";

const CourseForm = () => {
    const [language, setLanguage] = useState('');
    const [duration, setDuration] = useState('');
    const [topicsCovered, setTopicsCovered] = useState('');
    const [price, setPrice] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form inputs
        if (!language || !duration || !topicsCovered || !price) {
            setError('All fields are required.');
            return;
        }

        // Convert topicsCovered to an array
        const topicsArray = topicsCovered.split(',').map(topic => topic.trim());

        try {
            // Add data to Firestore
            await addDoc(collection(db, "courseOverview"), {
                language,
                duration,
                topicsCovered: topicsArray,
                price,
            });
            setSuccess('Course data added successfully!');
            setError('');
            // Clear form fields
            setLanguage('');
            setDuration('');
            setTopicsCovered('');
            setPrice('');
        } catch (error) {
            setError('Error adding document: ' + error.message);
            setSuccess('');
        }
    };

    return (
        <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Add New Course</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {success && <p className="text-green-500 mb-4">{success}</p>}
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Language:</label>
                    <input
                        type="text"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg px-3 py-2 mt-1"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Duration:</label>
                    <input
                        type="text"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg px-3 py-2 mt-1"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Topics Covered (comma separated):</label>
                    <input
                        type="text"
                        value={topicsCovered}
                        onChange={(e) => setTopicsCovered(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg px-3 py-2 mt-1"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Price:</label>
                    <input
                        type="text"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        className="w-full border border-gray-300 rounded-lg px-3 py-2 mt-1"
                    />
                </div>
                <button
                    type="submit"
                    className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-500"
                >
                    Add Course
                </button>
            </form>
        </div>
    );
};

export default CourseForm;
