import React from 'react';
import Slider from 'react-slick';
import { FaHtml5, FaCss3Alt, FaJs, FaReact } from 'react-icons/fa';
import { SiTailwindcss } from 'react-icons/si';
import './MainLogoCarousel.css';

// Slider settings
const settings = {
    dots: false, // Remove dots if you don't need them
    infinite: true, // Infinite loop
    speed: 1000, // Adjust speed for smoothness
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust speed of autoplay
    cssEase: 'linear', // Smooth animation
};

const MainLogoCarousel = () => {
    return (
        <div className="relative mt-10 overflow-hidden">
            <Slider {...settings}>
                <div className="flex justify-center items-center p-4">
                    <FaHtml5 className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <FaCss3Alt className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <FaJs className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <FaReact className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <SiTailwindcss className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                {/* Duplicate slides to enhance continuous effect */}
                <div className="flex justify-center items-center p-4">
                    <FaHtml5 className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <FaCss3Alt className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <FaJs className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <FaReact className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
                <div className="flex justify-center items-center p-4">
                    <SiTailwindcss className="text-5xl text-gray-700 dark:text-gray-300" />
                </div>
            </Slider>
        </div>
    );
};

export default MainLogoCarousel;
