import React, { useState, useEffect } from 'react';
import { ShoppingCartIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { useUser } from '../../contexts/UserContext'; // Import the UserContext hook
import { db } from '../../config/config'; // Import Firestore database instance
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { collection, addDoc, query, where, getDocs, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-toastify'; // Import the toast function

const ProjectCard = ({ project }) => {
    const [isInCart, setIsInCart] = useState(false);
    const [rating, setRating] = useState(0); // State for rating
    const [reviewCount, setReviewCount] = useState(0); // State for review count
    const { user } = useUser(); // Use the UserContext to get the current user
    const navigate = useNavigate(); // Hook for navigation

    // console.log(user.email);


    useEffect(() => {
        if (user) {
            checkIfInCart();
            fetchProjectData(); // Fetch project data on component mount
        }
    }, [user, project.id]);

    const checkIfInCart = async () => {
        try {
            const cartRef = collection(db, 'Cart');
            const q = query(cartRef, where('userEmail', '==', user.email), where('productId', '==', project.id));
            const querySnapshot = await getDocs(q);
            setIsInCart(!querySnapshot.empty);
        } catch (error) {
            console.error('Error checking cart status:', error);
        }
    };

    const addToCart = async (productId) => {
        try {
            const cartRef = collection(db, 'Cart');
            await addDoc(cartRef, {
                userEmail: user.email,
                productId: productId
            });
            console.log('Cart details saved to Firestore.');
            setIsInCart(true); // Update state to reflect cart status
        } catch (error) {
            console.error('Error saving Cart details to Firestore:', error);
        }
    };

    const removeFromCart = async (productId) => {
        try {
            const cartRef = collection(db, 'Cart');
            const q = query(cartRef, where('userEmail', '==', user.email), where('productId', '==', productId));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

            // toast.success('Cart item removed from Firestore.');
            setIsInCart(false); // Update state to reflect cart status
        } catch (error) {
            toast.error('Error removing Cart details from Firestore:', error);
        }
    };

    const fetchProjectData = async () => {
        try {
            const projectRef = doc(db, 'projects', project.projectId); // Adjust collection name and document ID
            const projectDoc = await getDoc(projectRef);
            if (projectDoc.exists()) {
                const data = projectDoc.data();
                setRating(data.rating || 0); // Update state with the fetched rating
                setReviewCount(data.reviewCount || 0); // Update state with the fetched review count
            }
        } catch (error) {
            toast.error('Error fetching project data:', error);
        }
    };

    const handleDownloadButtonClick = (event) => {
        event.stopPropagation();  // Prevents the click event from propagating to parent elements
        event.preventDefault();   // Prevents the default behavior of the event

        // Implement the download functionality here
        toast.info('Download initiated...', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            icon: "📥",  // Custom icon
        });
    };

    // const handleCartButtonClick = (event) => {
    //     event.stopPropagation();  // Prevents the click event from propagating to parent elements
    //     event.preventDefault();   // Prevents the default behavior of the event

    //     if (!user) {
    //         // Show toast notification if the user is not logged in
    //         toast.error('Please "login" to add items to your cart.', {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //         });
    //         // navigate('/Authentication');
    //         return;
    //     }

    //     if (isInCart) {
    //         removeFromCart(project.id);
    //         toast.info('Item removed from cart.', {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //             icon: "🗑️",  // Custom icon
    //         });
    //     } else {
    //         addToCart(project.id);
    //         toast.success('Item added to cart!', {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "colored",
    //             icon: "🛒",  // Custom icon
    //         });
    //     }
    // };

    const handleCartButtonClick = (event) => {
        event.stopPropagation();  // Prevents the click event from propagating to parent elements
        event.preventDefault();   // Prevents the default behavior of the event

        if (!user) {
            toast.error('Please "login" to add items to your cart.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
            return;
        }

        if (!isInCart) {
            addToCart(project.id);
            toast.success('Item added to cart!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                icon: "🛒",  // Custom icon
            });
        }
    };

    const renderStarRating = (rating) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 >= 0.5;
        const quarterStar = rating % 1 > 0.25 && rating % 1 < 0.5;

        return (
            <div className="flex items-center">
                {[...Array(5)].map((_, index) => {
                    let starType = 'text-gray-300'; // Default to empty star
                    if (index < fullStars) {
                        starType = 'text-yellow-500'; // Full star
                    } else if (index === fullStars && halfStar) {
                        starType = 'text-yellow-500'; // Half star
                    } else if (index === fullStars && quarterStar) {
                        starType = 'text-yellow-500'; // Quarter star
                    }
                    return (
                        <svg
                            key={index}
                            className={`w-5 h-5 ${starType}`}
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73-1.64 7.03L12 17.27z" />
                        </svg>
                    );
                })}
            </div>
        );
    };

    const priceDisplay = () => {
        if (project.price <= 0 || project.price.toLowerCase() === 'free' || !project.price) {
            return "Free";
        }
        return `₹${project.price}`;
    };

    return (
        <div class="p-4 sm:p-6 bg-white dark:bg-gray-800 h-full  shadow-lg border border-gray-200 dark:border-gray-700 transition-transform transform hover:scale-105 ease-in-out duration-300 w-full ">
            <img
                src={project.image}
                alt={project.title}
                className="w-full h-64 object-contain rounded-md shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300 bg-black"
                loading="lazy"

            />
            <div className="flex flex-col items-start mt-4">
                <h1 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-gray-100 mb-2">{project.title}</h1>
                <div className="mt-4 flex flex-col flex-grow">
                    <div className="flex justify-between items-center mb-2">
                        <span className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-gray-100">
                            Price - ₹{project.price}
                        </span>
                    </div>
                    <div className="text-gray-900 dark:text-gray-100 flex items-center">
                        {renderStarRating(project.rating)}
                        <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">({project.saleCount} Sales)</span>
                    </div>
                </div>
            </div>
            <div className="flex items-end justify-end mt-4">
                {/* <button
                    onClick={handleCartButtonClick}
                    className={`bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center space-x-2 shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300 ${isInCart ? 'bg-green-600 hover:bg-green-700' : ''}`}
                >
                    <ShoppingCartIcon className="w-5 h-5" />
                    <span>{isInCart ? 'Remove from Cart' : 'Add to Cart'}</span>
                </button> */}
                <button
                    onClick={handleCartButtonClick}
                    disabled={isInCart}
                    className={`bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center space-x-2 shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300 ${isInCart ? 'bg-green-600 cursor-not-hover' : ''
                        // isInCart ? 'bg-green-500 cursor-not-allowed' : ''
                        }`}
                >
                    <ShoppingCartIcon className="w-5 h-5" />
                    <span>{isInCart ? 'Already in Cart' : 'Add to Cart'}</span>
                </button>
            </div>
        </div>
    );
};

export default ProjectCard;
