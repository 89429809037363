import React, { useState, useEffect, useRef } from 'react';
import { auth } from '../config/config'; // Assuming auth is correctly configured
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import LoaderSpinner from '../Components/LoaderSpinner/LoaderSpinner';

const db = getFirestore();

const MyComponent = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [countdown, setCountdown] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const intervalRef = useRef(null);
    const [user, setUser] = useState(null);

    // Function to fetch products by product IDs
    const fetchProducts = async (productIds) => {
        try {
            const validProductIds = Array.from(new Set(productIds.filter(id => id)));
            if (validProductIds.length === 0) return [];
            
            const chunkSize = 10;
            const productChunks = [];
            for (let i = 0; i < validProductIds.length; i += chunkSize) {
                productChunks.push(validProductIds.slice(i, i + chunkSize));
            }

            let fetchedProducts = [];
            for (const chunk of productChunks) {
                const productQuery = query(collection(db, 'projects'), where('projectId', 'in', chunk));
                const querySnapshot = await getDocs(productQuery);
                const productsInChunk = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                fetchedProducts = [...fetchedProducts, ...productsInChunk];
            }
            
            const uniqueProducts = Array.from(new Map(fetchedProducts.map(product => [product.id, product])).values());
            return uniqueProducts;
        } catch (error) {
            console.error('Error fetching products:', error);
            return [];
        }
    };

    const fetchPurchasedProductIds = async (email) => {
        try {
            const purchasesQuery = query(collection(db, 'payments'), where('userEmail', '==', email));
            const querySnapshot = await getDocs(purchasesQuery);
            if (querySnapshot.empty) return [];
            
            const productIds = querySnapshot.docs.flatMap(doc => doc.data().productIds || []);
            return productIds;
        } catch (error) {
            console.error('Error fetching purchased product IDs:', error);
            return [];
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (loggedInUser) => {
            if (loggedInUser) {
                setUser(loggedInUser);
                const email = loggedInUser.email;
                try {
                    const productIds = await fetchPurchasedProductIds(email);
                    if (productIds.length > 0) {
                        const fetchedProducts = await fetchProducts(productIds);
                        setProducts(fetchedProducts);
                    }
                } catch (error) {
                    console.error('Error fetching products:', error);
                }
                setLoading(false);
            } else {
                setUser(null);
                setLoading(false);
            }
        });

        return () => unsubscribe(); // Clean up the subscription
    }, []);

    const downloadFile = async (product) => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        setCurrentProduct(product.id);
        let timeLeft = 10; // 10-second countdown
        setCountdown(timeLeft);

        const intervalId = setInterval(() => {
            timeLeft -= 1;
            setCountdown(timeLeft);
            if (timeLeft <= 0) {
                clearInterval(intervalId);
                try {
                    if (product.Link) {
                        fetch(product.Link)
                            .then((response) => {
                                if (!response.ok) {
                                    throw new Error(`Failed to fetch file from ${product.Link}`);
                                }
                                return response.blob();
                            })
                            .then((blob) => {
                                const url = window.URL.createObjectURL(blob);
                                const link = document.createElement('a');
                                link.href = url;
                                // Attempt to infer file extension from MIME type
                                const fileName = product.title || 'file';
                                link.download = fileName; // Customize the filename if needed
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                window.URL.revokeObjectURL(url);
                            })
                            .catch((error) => console.error('Error downloading file:', error));
                    }
                } catch (error) {
                    console.error('Error downloading file:', error);
                }
                setCurrentProduct(null);
                setCountdown(null);
            }
        }, 1000);

        intervalRef.current = intervalId;
    };

    if (loading) {
        return <LoaderSpinner />;
    }

    return (
        <div className="p-6 min-h-screen">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
                {products.length > 0 ? (
                    products.map((product) => (
                        <div
                            key={product.id}
                            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 ease-in-out duration-300"
                        >
                            <img
                                src={product.image} // Assuming 'imageLink' is the field for image URL
                                alt={product.title} // Assuming 'title' is the field for product title
                                className="w-full h-48 object-cover"
                                loading="lazy"
                            />
                            <div className="p-6">
                                <h2 className="text-md font-semibold text-gray-900 dark:text-gray-100 mb-4">
                                    {product.title}
                                </h2>
                                <button
                                    onClick={() => downloadFile(product)}
                                    className={`bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center space-x-2 shadow-md transition-transform transform ${currentProduct === product.id ? 'cursor-wait' : 'cursor-pointer'} transition-transform ease-in-out duration-300`}
                                    disabled={currentProduct === product.id}
                                >
                                    <CloudArrowDownIcon className="h-5 w-5" />
                                    <span>
                                        {currentProduct === product.id ? `Download in ${countdown}s` : 'Download'}
                                    </span>
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-gray-500">No products available.</p>
                )}
            </div>
        </div>
    );
};

export default MyComponent;
