import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { auth, googleProvider } from '../config/config';
import { FcGoogle } from 'react-icons/fc';
import { PiUsersThreeFill } from "react-icons/pi";
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    sendEmailVerification,
    sendPasswordResetEmail,
    onAuthStateChanged,
    updateProfile,
    signInWithPopup,
} from 'firebase/auth';
import './CSS/Authentication.css';
import { useNavigate, useLocation } from 'react-router-dom';

const FirebaseLogin = () => {
    const [user, setUser] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isRegistering, setIsRegistering] = useState(false);
    const [otpSent, setOtpSent] = useState(false);
    const [timer, setTimer] = useState(0);
    const [verificationSent, setVerificationSent] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user && !user.emailVerified) {
                sendVerification();
            }
        });

        return () => unsubscribe();
    }, []);

    const successNotification = (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorNotification = (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const errorMessages = {
        'auth/email-already-in-use': 'This email is already in use. Please use another one.',
        'auth/wrong-password': 'Incorrect password. Please try again.',
        'auth/user-not-found': 'No account found with this email. Please register.',
        'auth/invalid-email': 'Please enter a valid email address.',
        'auth/operation-not-allowed': 'This operation is not allowed.',
        'auth/weak-password': 'Your password is too weak. Please choose a stronger one.',
    };

    const getErrorMessage = (errorCode) => {
        return errorMessages[errorCode] || 'Incorrect Email or Password. Please try again.';
    };

    const sendVerification = async () => {
        if (user && !user.emailVerified && !verificationSent) {
            await sendEmailVerification(user);
            setVerificationSent(true);
            successNotification("Verification email sent!");
        }
    };

    const login = async () => {
        try {
            const response = await signInWithEmailAndPassword(auth, email, password);
            const redirectTo = location.state?.from || '/'; // Default to home if no previous page

            if (response.user.emailVerified) {
                successNotification(`Welcome, ${response.user.email}!`);
                navigate(redirectTo, { replace: true });
                // navigate('/');
            } else {
                errorNotification("Please verify your email first.");
                toast.onfo("Please verify your email first.");
            }
        } catch (err) {
            errorNotification(getErrorMessage(err.code));
        }
    };



    const logout = async () => {
        try {
            await signOut(auth);
            const redirectTo = location.state?.from || '/'; // Default to home if no previous page

            successNotification("Successfully logged out!");
            navigate(redirectTo, { replace: true });

        } catch (err) {
            errorNotification("An error occurred while logging out.");
        }
    };

    const registration = async () => {

        if (password !== confirmPassword) {
            errorNotification("Passwords do not match!");
            return;
        }

        try {
            const response = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(response.user);
            await updateProfile(response.user, { displayName: email });
            successNotification("Registration successful! Please verify your email.");
            setIsRegistering(false);
        } catch (err) {
            errorNotification(getErrorMessage(err.code));
        }
    };

    const sendOtp = async () => {
        if (!email) {
            errorNotification("Please enter your email.");
            return;
        }

        try {
            await sendPasswordResetEmail(auth, email);
            successNotification(`Password reset email sent to ${email}.`);
            setOtpSent(true);
            setTimer(30);

            const interval = setInterval(() => {
                setTimer((prev) => {
                    if (prev <= 1) {
                        clearInterval(interval);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(interval);
        } catch (error) {
            errorNotification(getErrorMessage(error.code));
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const redirectTo = location.state?.from || '/'; // Default to home if no previous page

            successNotification(`Welcome, ${result.user.email}!`);
            navigate(redirectTo, { replace: true });

            // navigate('/');
        } catch (error) {
            errorNotification(getErrorMessage(error.code));
        }
    };

    const renderLogin = () => (
        <div className="flex flex-col items-center justify-center min-h-screen px-6 py-12 bg-gray-50 dark:bg-gray-900">
            <div className="w-full max-w-md bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8">
                <div className="flex justify-center mb-6">
                    <PiUsersThreeFill className="w-14 h-14 sm:w-16 sm:h-16 md:w-18 md:h-18 lg:w-20 lg:h-20 xl:w-24 xl:h-24 text-white-700 dark:text-white" />
                </div>
                <h2 className="text-2xl font-bold text-center text-gray-900 dark:text-white">
                    Sign in to your account
                </h2>

                <form onSubmit={(e) => { e.preventDefault(); login(); }} className="mt-8 space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                            Email address
                        </label>
                        <div className="mt-1">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                            Password
                        </label>
                        <div className="mt-1">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                        <div className="flex items-center justify-between mt-2">
                            <a href="#" onClick={() => setOtpSent(true)} className="text-sm font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400">
                                Forgot password?
                            </a>
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        <button
                            type="submit"
                            className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                        >
                            Sign in
                        </button>
                        <button
                            type="button"
                            className="w-full py-2 px-4 border border-gray-300 text-gray-900 font-semibold rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                            onClick={handleGoogleLogin}
                        >
                            <FcGoogle className="inline-block mr-2" />
                            Sign in with Google
                        </button>
                    </div>
                </form>

                <p className="mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
                    Don't have an account?{' '}
                    <a href="#" onClick={() => setIsRegistering(true)} className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400">
                        Register here
                    </a>
                </p>
            </div>
            <ToastContainer />
        </div>
    );

    const renderRegistration = () => (
        <div className="flex flex-col items-center justify-center min-h-screen px-6 py-12 bg-gray-50 dark:bg-gray-900">
            <div className="w-full max-w-md bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8">
                <div className="flex justify-center mb-6">
                    <PiUsersThreeFill className="w-14 h-14 sm:w-16 sm:h-16 md:w-18 md:h-18 lg:w-20 lg:h-20 xl:w-24 xl:h-24 text-white-700 dark:text-white" />
                </div>
                <h2 className="text-2xl font-bold text-center text-gray-900 dark:text-white">
                    Register an account
                </h2>

                <form onSubmit={(e) => { e.preventDefault(); registration(); }} className="mt-8 space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                            Email address
                        </label>
                        <div className="mt-1">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                            Password
                        </label>
                        <div className="mt-1">
                            <input
                                id="password"
                                name="password"
                                type="password"
                                required
                                autoComplete="new-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                    </div>

                    <div>
                        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                            Confirm Password
                        </label>
                        <div className="mt-1">
                            <input
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                required
                                autoComplete="new-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                    >
                        Register
                    </button>

                    <button
                        type="button"
                        className="w-full py-2 px-4 border border-gray-300 text-gray-900 font-semibold rounded-md shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600"
                        onClick={handleGoogleLogin}
                    >
                        <FcGoogle className="inline-block mr-2" />
                        Register with Google
                    </button>
                </form>

                <p className="mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
                    Already have an account?{' '}
                    <a href="#" onClick={() => setIsRegistering(false)} className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400">
                        Sign in here
                    </a>
                </p>
            </div>
            <ToastContainer />
        </div>
    );

    const renderOtp = () => (
        <div className="flex flex-col items-center justify-center min-h-screen px-6 py-12 bg-gray-50 dark:bg-gray-900">
            <div className="w-full max-w-md bg-white dark:bg-gray-800 shadow-lg rounded-lg p-8">
                <h2 className="text-2xl font-bold text-center text-gray-900 dark:text-white">
                    Password Reset
                </h2>
                <form onSubmit={(e) => { e.preventDefault(); sendOtp(); }} className="mt-8 space-y-6">
                    <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                            Email address
                        </label>
                        <div className="mt-1">
                            <input
                                id="email"
                                name="email"
                                type="email"
                                required
                                autoComplete="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
                    >
                        Send OTP
                    </button>
                </form>
                {otpSent && (
                    <div className="mt-4 text-center">
                        {/* <p className="text-sm text-gray-500 dark:text-gray-400">
                            OTP has been sent to your email. Please check your inbox.
                        </p> */}
                        <p className="mt-2 text-xs text-gray-400 dark:text-gray-500">
                            {timer > 0 ? `OTP expires in ${timer} seconds` : "OTP has expired. Please request a new one."}
                        </p>
                    </div>
                )}
                <p className="mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
                    Remember your password?{' '}
                    <a href="#" onClick={() => setOtpSent(false)} className="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400">
                        Sign in here
                    </a>
                </p>
            </div>
            <ToastContainer />
        </div>
    );

    return (
        <>
            {otpSent ? renderOtp() : isRegistering ? renderRegistration() : renderLogin()}
        </>
    );
};

export default FirebaseLogin;
