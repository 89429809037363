import React, { useState, useEffect } from 'react';
import {
    QuestionMarkCircleIcon,


} from '@heroicons/react/24/outline';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';


const FrequentlyAskedQuestions = () => {

    const [openIndex, setOpenIndex] = useState(null);

    const toggleOpen = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const [, setTimeLeft] = useState(3600); // Example initial timer value in seconds

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);
        return () => clearInterval(timer);
    }, []);





    return (
        <div className="mt-12 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">Frequently Asked Questions</h3>
            <div className="mt-4 space-y-4">
                <div className="border-b border-gray-200 dark:border-gray-700">
                    <div
                        className="flex items-center justify-between py-4 cursor-pointer"
                        onClick={() => toggleOpen(0)}
                    >
                        <div className="flex items-center">
                            <QuestionMarkCircleIcon className="h-6 w-6 text-indigo-500" />
                            <p className="ml-3 text-lg font-semibold text-gray-900 dark:text-gray-100">
                                What is the duration of the course?
                            </p>
                        </div>
                        <div className="text-gray-600 dark:text-gray-400">
                            {openIndex === 0 ? (
                                <MinusIcon className="h-6 w-6 text-indigo-500" />
                            ) : (
                                <PlusIcon className="h-6 w-6 text-indigo-500" />
                            )}
                        </div>
                    </div>
                    {openIndex === 0 && (
                        <div className="ml-9 pb-4 text-gray-600 dark:text-gray-400">
                            The course is self-paced and takes approximately 3 Months to complete.
                        </div>
                    )}
                </div>

                <div className="border-b border-gray-200 dark:border-gray-700">
                    <div
                        className="flex items-center justify-between py-4 cursor-pointer"
                        onClick={() => toggleOpen(1)}
                    >
                        <div className="flex items-center">
                            <QuestionMarkCircleIcon className="h-6 w-6 text-indigo-500" />
                            <p className="ml-3 text-lg font-semibold text-gray-900 dark:text-gray-100">
                                Are there any prerequisites?
                            </p>
                        </div>
                        <div className="text-gray-600 dark:text-gray-400">
                            {openIndex === 1 ? (
                                <MinusIcon className="h-6 w-6 text-indigo-500" />
                            ) : (
                                <PlusIcon className="h-6 w-6 text-indigo-500" />
                            )}
                        </div>
                    </div>
                    {openIndex === 1 && (
                        <div className="ml-9 pb-4 text-gray-600 dark:text-gray-400">
                            No prerequisites are required. This course is designed for both beginners and advanced learners.
                        </div>
                    )}
                </div>

                <div className="border-b border-gray-200 dark:border-gray-700">
                    <div
                        className="flex items-center justify-between py-4 cursor-pointer"
                        onClick={() => toggleOpen(2)}
                    >
                        <div className="flex items-center">
                            <QuestionMarkCircleIcon className="h-6 w-6 text-indigo-500" />
                            <p className="ml-3 text-lg font-semibold text-gray-900 dark:text-gray-100">
                                How can I contact support?
                            </p>
                        </div>
                        <div className="text-gray-600 dark:text-gray-400">
                            {openIndex === 2 ? (
                                <MinusIcon className="h-6 w-6 text-indigo-500" />
                            ) : (
                                <PlusIcon className="h-6 w-6 text-indigo-500" />
                            )}
                        </div>
                    </div>
                    {openIndex === 2 && (
                        <div className="ml-9 pb-4 text-gray-600 dark:text-gray-400">
                            You can contact support via email at muhilan6601@outlook.com, Whatsapp - +91 8838299264 or through our community forums.
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FrequentlyAskedQuestions