import React from 'react'
import ParticlesBackground from '../Components/HomeMain/ParticlesBackground'
import AdminPanel from './AdminPanel'
import ProjectPage from '../Components/ProjectPage/ProjectPage'
import ProjectForm from '../Components/ProjectForm/ProjectForm';
// import Chatbot from '../Components/Chatbot/Chatbot'

const Project = () => {
  return (
    <div className='relative bg-gray-200 dark:bg-gray-900 min-h-screen pt-10'> 
      
      {/* <main style={{ minHeight: '700px' }} className="grid min-h-full place-items-center bg-white dark:bg-black-900 px-6 py-24 sm:py-32 lg:px-8">
        <ParticlesBackground />

        <div className="text-center">
          <div className="bg-gray-200 dark:bg-gray-800 p-8 rounded-lg shadow-lg inline-block">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">🚧 Page Under Development</h1>
            <p className="text-lg text-gray-600 dark:text-gray-400 mb-6">
              We're working hard to get this page ready. It will be available soon.
            </p>
            <p className="text-base text-gray-600 dark:text-gray-400 mb-6">
              In the meantime, you can return to the <a href="/" className="text-indigo-600 dark:text-indigo-400 hover:underline">homepage</a> or contact us for more information.
            </p>
            <div>
              <button className="bg-indigo-600 text-white px-4 py-2 rounded-lg shadow hover:bg-indigo-700 transition duration-300 ease-in-out">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </main> */}

      {/* <AdminPanel /> */}

      {/* <Chatbot /> */}


<ProjectPage />

{/* <ProjectForm /> */}
    </div>
  )
}

export default Project