import React, { useState } from 'react';
import { FiHome, FiSettings, FiMenu, FiX } from 'react-icons/fi';
import ProductSection from '../Components/AdminComponent/ComponentProducts/ProductSection';
import CourseForm from '../Components/AdminComponent/CourseForm';
import ProjectForm from '../Components/ProjectForm/ProjectForm';
import EnrollmentStatisticsForm from '../Components/AdminComponent/EnrollmentStatisticsForm/EnrollmentStatisticsForm';
import ProjectPanel from '../Components/ProjectForm/ProjectPanel';
import FileUpload from '../Components/AdminComponent/UploadFile/FileUpload';

const AdminPage = () => {
    const [activeSection, setActiveSection] = useState('dashboard');
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const renderContent = () => {
        switch (activeSection) {
            case 'dashboard':
                return <ProductSection />;
            case 'courseform':
                return <CourseForm />;
            case 'project':
                // return <ProjectPanel />;
                return <FileUpload />
            case 'enrollmentstatistics':
                return <EnrollmentStatisticsForm />;
            default:
                return <ProductSection />;
        }
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {/* Toggle Button */}
            <button
                className={`0 fixed top-20 transform -translate-y-1/2 text-white text-2xl bg-gray-800 p-2 rounded-r-lg shadow-lg transition-transform ${isSidebarOpen ? 'rotate-180' : ''}`}
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                style={{ zIndex: 999 }}
            >
                {isSidebarOpen ? <FiX /> : <FiMenu />}
            </button>

            {/* Sidebar */}
            <div
                className={`fixed top-20 left-0 h-full bg-gray-800 text-white shadow-lg transition-transform transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
                style={{ width: '250px', maxHeight: 'calc(100vh - 2.5rem)', overflowY: 'auto' }}
            >
                <div className="p-6 mb-10 flex justify-between items-center">
                    {/* <h1 className="text-2xl font-bold">Admin Panel</h1> */}
                </div>
                <nav>
                    <ul>
                        {/* <li
                            className={`cursor-pointer p-4 hover:bg-gray-700 transition ${activeSection === 'dashboard' ? 'bg-gray-700' : ''}`}
                            onClick={() => setActiveSection('dashboard')}
                        >
                            <FiHome className="inline-block mr-2" /> Dashboard
                        </li> */}
                        <li
                            className={`cursor-pointer p-4 hover:bg-gray-700 transition ${activeSection === 'courseform' ? 'bg-gray-700' : ''}`}
                            onClick={() => setActiveSection('courseform')}
                        >
                            <FiSettings className="inline-block mr-2" /> CourseForm
                        </li>
                        <li
                            className={`cursor-pointer p-4 hover:bg-gray-700 transition ${activeSection === 'project' ? 'bg-gray-700' : ''}`}
                            onClick={() => setActiveSection('project')}
                        >
                            <FiHome className="inline-block mr-2" /> Project
                        </li>
                        <li
                            className={`cursor-pointer p-4 hover:bg-gray-700 transition ${activeSection === 'enrollmentstatistics' ? 'bg-gray-700' : ''}`}
                            onClick={() => setActiveSection('enrollmentstatistics')}
                        >
                            <FiHome className="inline-block mr-2" /> Enrollment Statistics
                        </li>
                    </ul>
                </nav>
            </div>

            {/* Main content */}
            <div className={`relative top-20 mb-20 flex-1 p-6 overflow-y-auto max-h-screen transition-all ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
                {renderContent()}
            </div>
        </div>
    );
};

export default AdminPage;
