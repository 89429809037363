import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent } from '@mui/material';
import ProjectCard from '../ProjectCard/ProjectCard';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/config';
import { useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';

const RelatedProjects = ({ currentProjectId }) => {
    const [relatedProjects, setRelatedProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchRelatedProjects = async () => {
            setLoading(true);
            setError(null);

            try {
                const querySnapshot = await getDocs(collection(db, 'projects'));
                let fetchedProjects = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Filter out the current project from related projects
                fetchedProjects = fetchedProjects.filter(project => project.id !== currentProjectId);

                // Shuffle and select 3 random projects
                fetchedProjects = fetchedProjects.sort(() => 0.5 - Math.random()).slice(0, 3);
                setRelatedProjects(fetchedProjects);
            } catch (err) {
                setError('Failed to fetch related projects. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchRelatedProjects();
    }, [currentProjectId]);

    const handleProjectClick = (projectId) => {
        // Ensure the page scrolls to the top
        window.scrollTo({ top: 0, behavior: 'instant' }); // 'instant' or 'auto' for no smooth scrolling
        navigate(`/Shop/${projectId}`);
    };

    if (loading) {
        return (
            <Container className="py-8">
                <Typography variant="h6" gutterBottom className="mb-4 font-semibold text-lg">
                    Related Projects
                </Typography>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width="100%" height={180} />
                    ))}
                </div>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="py-8">
                <Typography variant="h6" gutterBottom className="mb-4 font-semibold text-lg">
                    Related Projects
                </Typography>
                <Typography color="error" align="center">
                    {error}
                </Typography>
            </Container>
        );
    }

    return (
        <Container className="py-8">
            <Typography variant="h6" gutterBottom className="mb-4 font-semibold text-lg">
                Related Projects
            </Typography>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {relatedProjects.length > 0 ? (
                    relatedProjects.map((project) => (
                        <div key={project.id} className="transform transition-transform duration-300 hover:scale-105">
                            <div onClick={() => handleProjectClick(project.id)} style={{ cursor: 'pointer' }}>
                                <ProjectCard project={project} className="flex flex-col justify-between h-full" />
                            </div>
                        </div>
                    ))
                ) : (
                    <Card className="col-span-1 sm:col-span-2 lg:col-span-3">
                        <CardContent>
                            <Typography variant="body1" align="center">
                                No related projects found
                            </Typography>
                        </CardContent>
                    </Card>
                )}
            </div>
        </Container>
    );
};

export default RelatedProjects;
