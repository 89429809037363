import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../config/config';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../contexts/UserContext';
import LoaderSpinner from '../Components/LoaderSpinner/LoaderSpinner';

const ProjectCheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  // const [user, setUser] = useState({ email: '' });
  const { user } = useUser(); // Use the UserContext to get the current user



  const projectId = new URLSearchParams(location.search).get('projectId');

  useEffect(() => {
    // Scroll to top when the component is mounted
    window.scrollTo(0, 0);


    const fetchProduct = async () => {
      if (projectId) {
        try {
          const querySnapshot = await getDocs(collection(db, 'projects'));
          const productDoc = querySnapshot.docs.find(doc => doc.data().projectId === projectId);

          if (productDoc) {
            const data = productDoc.data();
            setProduct(data);
            console.log(data);

          } else {
            toast.error('Product not found.');
          }
        } catch (error) {
          console.error('Error fetching product:', error);
          toast.error('Error fetching product.');
        }
      }
    };

    fetchProduct();
  }, [projectId]);




  // console.log(projectId);

  const handleCheckout = async () => {
    if (!user) {
      navigate('/Authentication', { state: { from: location.pathname + location.search } });
      return;
    }

    setLoading(true);

    const productPrice = product ? parseFloat(product.price) : 0;
    const parsedPrice = parseFloat(productPrice);

    if (isNaN(parsedPrice) || parsedPrice <= 0) {
      try {
        await setDoc(doc(collection(db, 'payments')), {
          userEmail: user.email,
          productIds: projectId || 'Unknown Product ID',
          productTitle: product.title || 'Unknown Product',
          productPrice: 'Free',
          amount: 'Free',
          paymentId: 'Free_Product',
          status: 'Completed',
          createdAt: new Date(),
        });

        toast.success('You have successfully claimed the free product!');
        navigate('/confirmation', {
          state: { paymentStatus: 'success', product, paymentId: 'Free_Product', amount: 'Free' },
        });
      } catch (error) {
        toast.error('Failed to claim free product. Please try again.');
      } finally {
        setLoading(false);
      }
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: parsedPrice * 100,
      currency: 'INR',
      name: 'Your Company Name',
      description: 'Digital Product Purchase',
      image: 'path/to/your/logo.png',
      handler: async function (response) {
        try {
          await setDoc(doc(collection(db, 'payments'), response.razorpay_payment_id), {
            userEmail: user.email,
            productIds: projectId,
            productTitle: product.title,
            productPrice: product.price,
            amount: parsedPrice,
            paymentId: response.razorpay_payment_id,
            status: 'Completed',
            createdAt: new Date(),
          });

          toast.success('Payment completed successfully!');
          navigate('/confirmation', {
            state: { paymentStatus: 'success', product, paymentId: response.razorpay_payment_id, amount: parsedPrice },
          });
        } catch (error) {
          toast.error('Payment failed. Please try again.');
          navigate('/confirmation', { state: { paymentStatus: 'fail', product, amount: parsedPrice } });
        }
      },
      prefill: {
        email: user.email,
      },
      theme: {
        color: '#3399cc',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setLoading(false);
  };



  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // Calculate total and tax
  // Calculate total and tax
  const productPrice = product ? parseFloat(product.price) : 0;
  const tax = productPrice > 0 ? (productPrice * 0.08).toFixed(2) : '0.00';
  const total = productPrice > 0 ? (productPrice + parseFloat(tax)).toFixed(2) : 'Free';



  if (product === null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoaderSpinner /> {/* Show loading spinner */}
      </div>
    );
  }

  return (
    <div className="relative top-20 mb-20 p-2 sm:p-6 lg:p-8 bg-gray-200 dark:bg-gray-900 min-h-screen flex flex-col items-center">
      <div className="w-full max-w-5xl bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-8">Checkout</h1>

        {product === null ? (
          <p className="text-center text-lg font-semibold">Loading product details...</p>
        ) : product ? (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md">
              <thead className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100">
                <tr>
                  <th className="p-4 text-left text-xs md:text-sm">Product</th>
                  <th className="p-4 text-left text-xs md:text-sm">Price</th>
                  <th className="p-4 text-left text-xs md:text-sm">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-t border-gray-300 dark:border-gray-700">
                  <td className="p-4 flex flex-col sm:flex-row items-center space-x-4 sm:space-x-6">
                    <img
                      src={product.image || 'path/to/placeholder-image.jpg'}
                      alt={product.title || 'Product Image'}
                      className="w-24 h-24 object-cover rounded-lg shadow-md"
                    />
                    <span className="hidden sm:block font-medium mt-2 sm:mt-0">{product.title}</span>
                  </td>
                  <td className="p-4 text-xs md:text-sm">
                    ₹ {isNaN(productPrice) ? '0.00' : productPrice.toFixed(2)}
                  </td>
                  <td className="p-4 text-xs md:text-sm">
                    ₹ {isNaN(productPrice) ? '0.00' : productPrice.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-center text-lg font-semibold">Product not found.</p>
        )}

        <div className="mt-8 bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Billing Information</h2>
          <input
            type="email"
            placeholder="Email Address"
            className="w-full p-4 mb-4 border border-gray-300 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            // value={user.email || ''} 
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            type="text"
            placeholder="Coupon Code (Optional)"
            className="w-full p-4 mb-6 border border-gray-300 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <div className="flex justify-between items-center mt-4 text-lg font-medium">
            <span>Subtotal:</span>
            <span>₹ {productPrice.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center mt-2 text-lg font-medium">
            <span>Tax (8%):</span>
            <span>₹ {tax}</span>
          </div>
          <div className="flex justify-between items-center mt-4 border-t border-gray-300 dark:border-gray-700 pt-4 font-bold text-lg">
            <span>Total:</span>
            <span>{total === 'Free' ? 'Free' : `₹ ${total}`}</span>
          </div>

          <button
            onClick={handleCheckout}
            disabled={loading}
            className="w-full py-3 mt-6 text-white bg-blue-600 dark:bg-blue-500 rounded-md shadow-lg flex items-center justify-center transition-transform transform hover:scale-105 ease-in-out duration-300"
          >
            {loading ? (
              <>
                Processing...
                <svg className="w-6 h-6 ml-3 animate-spin text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeOpacity="0.5" />
                  <path d="M4 12a8 8 0 0114.696-4.696" stroke="currentColor" strokeWidth="4" />
                </svg>
              </>
            ) : (
              'Checkout'
            )}
          </button>
        </div>
      </div>

      {openSnackbar && (
        <div className="fixed bottom-5 left-1/2 transform -translate-x-1/2 bg-green-600 text-white p-4 rounded-lg shadow-lg">
          <p>Payment successful! You can now download your product.</p>
          <button onClick={handleSnackbarClose} className="absolute top-1 right-1 text-xl font-semibold">
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default ProjectCheckoutPage;
