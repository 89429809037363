import React from 'react';
import { StarIcon } from '@heroicons/react/24/outline';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import sajeed from '../../Components/Assets/sajeed.jpg';
import julian from '../../Components/Assets/julian.jpg';

const EbookCarouselTestimonials = () => {
    // Carousel settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false, // Hide arrows for a cleaner look
    };

    // Function to render star rating
    const renderStars = (rating) => (
        <div className="flex items-center space-x-1">
            {[...Array(5)].map((_, index) => (
                <StarIcon
                    key={index}
                    className={`h-6 w-6 ${index < rating ? 'text-yellow-400' : 'text-gray-300'}`}
                />
            ))}
        </div>
    );

    return (
        <div className="mt-12 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl font-bold text-center text-gray-800 dark:text-gray-100 mb-8">
                What other Experts Says
            </h3>
            <Slider {...settings} className="relative">
                {/* Testimonial 1 */}
                <div className="flex flex-col items-center p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 transform transition-transform hover:scale-105">
                    <div className="w-24 h-24 bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full overflow-hidden flex items-center justify-center mb-4 border-4 border-white dark:border-gray-900">
                        <img src={sajeed} alt="Jane Doe" className="w-full h-full object-cover" />
                    </div>
                    <div className="relative flex items-center justify-center w-full mb-4">
                        <div className="absolute flex items-center justify-center">
                            {renderStars(5)}
                        </div>
                    </div>
                    <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
                        Sajad Saeedi Azad
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 text-center mb-2">
                        "An exceptional course that covers all necessary aspects of web development. The hands-on projects were incredibly useful!"
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Software Engineer
                         {/* at TechCorp */}
                    </p>
                </div>

                {/* Testimonial 2 */}
                <div className="flex flex-col items-center p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 transform transition-transform hover:scale-105">
                    <div className="w-24 h-24 bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full overflow-hidden flex items-center justify-center mb-4 border-4 border-white dark:border-gray-900">
                        <img src={julian} alt="John Smith" className="w-full h-full object-cover" />
                    </div>
                    <div className="relative flex items-center justify-center w-full mb-4">
                        <div className="absolute flex items-center justify-center">
                            {renderStars(4)}
                        </div>
                    </div>
                    <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
                        Julian Velez
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 text-center mb-2">
                        "The course structure and content were excellent. I gained practical knowledge that I can immediately apply to real-world projects."
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Front-End Developer at WebWorks
                    </p>
                </div>

                {/* Testimonial 3 */}
                <div className="flex flex-col items-center p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 transform transition-transform hover:scale-105">
                    <div className="w-24 h-24 bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full overflow-hidden flex items-center justify-center mb-4 border-4 border-white dark:border-gray-900">
                        <img src="https://via.placeholder.com/100" alt="Emily Johnson" className="w-full h-full object-cover" />
                    </div>
                    <div className="relative flex items-center justify-center w-full mb-4">
                        <div className="absolute flex items-center justify-center">
                            {renderStars(5)}
                        </div>
                    </div>
                    <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
                        Emily Johnson
                    </p>
                    <p className="text-gray-700 dark:text-gray-300 text-center mb-2">
                        "Highly recommended for anyone who wants to enhance their web development skills. The support and resources provided were outstanding."
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                        Freelance Developer
                    </p>
                </div>
            </Slider>
        </div>
    );
};

export default EbookCarouselTestimonials;
