import React, { useState } from 'react';
import axios from 'axios';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');
  const [fileUrl, setFileUrl] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      // Make a POST request to your PHP backend
      const response = await axios.post('https://codewithmuhilan.com/myuploads/upload.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.status === 'success') {
        setUploadStatus('File uploaded successfully!');
        setFileUrl(`https://your-domain.com${response.data.url}`);
      } else {
        setUploadStatus(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error uploading the file:', error);
      setUploadStatus('Error uploading the file.');
    }
  };

  return (
    <div>
      <h2>Upload File to Hostinger Storage</h2>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Upload</button>

      {uploadStatus && <p>{uploadStatus}</p>}

      {fileUrl && (
        <div>
          <p>File URL: <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileUrl}</a></p>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
