import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../config/config'; // Ensure you import your Firebase config
import { useUser } from '../contexts/UserContext';

const CartCheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const { user } = useUser(); // Use the UserContext to get the current user

  const products = location.state?.products || [];
  const totalPrice = location.state?.totalPrice || 0;

  const handleCheckout = () => {
    setLoading(true);

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Your Razorpay key id
      amount: totalPrice * 100, // Amount in paise
      currency: 'INR',
      name: 'Your Company Name',
      description: 'Purchase Description',
      image: 'path/to/your/logo.png', // Replace with your company logo
      handler: async function (response) {
        try {
          // Save transaction details
          await saveTransaction(response.razorpay_payment_id, totalPrice);

          // Delete products from the database
          // for (const product of products) {
          //   await deleteProduct(product.id);
          // }

          toast.success('Payment completed successfully!');
          setOpenSnackbar(true);
          navigateToConfirmation('success', response.razorpay_payment_id, totalPrice);
        } catch (error) {
          toast.error('Error processing payment.');
        }
      },
      prefill: {
        name: '',
        email: email,
        contact: ''
      },
      theme: {
        color: '#3399cc'
      }
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();

    setLoading(false);
  };

  const saveTransaction = async (paymentId, amount) => {
    try {
      await setDoc(doc(collection(db, 'payments'), paymentId), {
        userEmail: user.email,
        productIds: products.map(product => product.id),
        productTitles: products.map(product => product.title),
        productPrices: products.map(product => product.price),
        totalAmount: amount,
        paymentId,
        status: 'Completed',
        createdAt: new Date(),
      });
    } catch (error) {
      toast.error('Error saving payment details.');
    }
  };

  // const deleteProduct = async (productId) => {
  //   try {
  //     await deleteDoc(doc(db, 'products', productId));
  //   } catch (error) {
  //     toast.error('Error deleting product data.');
  //   }
  // };

  const navigateToConfirmation = (status, paymentId, amount) => {
    navigate('/cartconfirmation', {
      state: { paymentStatus: status, products, paymentId, amount },
    });
  };

  const tax = (totalPrice * 0.08).toFixed(2); // 8% tax
  const finalAmount = (totalPrice + parseFloat(tax)).toFixed(2);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="relative top-20 mb-20 p-2 sm:p-6 lg:p-8 bg-gray-200 dark:bg-gray-900 min-h-screen flex flex-col items-center">
      <div className="w-full max-w-5xl bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center mb-8">Checkout</h1>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md">
            <thead className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100">
              <tr>
                <th className="p-4 text-left text-xs md:text-sm">Product</th>
                <th className="p-4 text-left text-xs md:text-sm">Price</th>
                <th className="p-4 text-left text-xs md:text-sm">Total</th>
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product.id} className="border-t border-gray-300 dark:border-gray-700">
                  <td className="p-4 flex items-center space-x-4">
                    <img
                      src={product.image || 'path/to/placeholder-image.jpg'}
                      alt={product.title}
                      className="w-24 h-24 object-cover rounded-lg shadow-md"
                    />
                    <span className="hidden sm:block font-medium mt-2 sm:mt-0">{product.title}</span>
                  </td>
                  <td className="p-4">{product.price === "Free" ? "Free" : `₹${product.price}`}</td>
                  <td className="p-4">{product.price === "Free" ? "Free" : `₹${(product.price * product.quantity).toFixed(2)}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-8 bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Billing Information</h2>
          <input
            type="email"
            placeholder="Email Address"
            className="w-full p-4 mb-4 border border-gray-300 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="Coupon Code (Optional)"
            className="w-full p-4 mb-6 border border-gray-300 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
          />
          <div className="flex justify-between items-center mt-4 text-lg font-medium">
            <span>Subtotal:</span>
            <span>₹ {totalPrice.toFixed(2)}</span>
          </div>
          <div className="flex justify-between items-center mt-2 text-lg font-medium">
            <span>Tax (8%):</span>
            <span>₹ {tax}</span>
          </div>
          <div className="flex justify-between items-center mt-4 border-t border-gray-300 dark:border-gray-700 pt-4 font-bold text-lg">
            <span>Total:</span>
            <span>₹ {finalAmount}</span>
          </div>
          <button
            onClick={handleCheckout}
            disabled={loading}
            className="w-full py-3 mt-6 text-white bg-blue-600 dark:bg-blue-500 rounded-md shadow-lg flex items-center justify-center transition-transform transform hover:scale-105 ease-in-out duration-300"
          >
            {loading ? (
              <>
                Processing...
                <svg className="w-6 h-6 ml-3 animate-spin text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" strokeOpacity="0.5" />
                  <path d="M4 12a8 8 0 0114.696-4.696" stroke="currentColor" strokeWidth="4" strokeLinecap="round" />
                </svg>
              </>
            ) : (
              'Complete Purchase'
            )}
          </button>
        </div>
      </div>

      {/* Snackbar */}
      {openSnackbar && (
        <div className="fixed bottom-5 right-5 bg-green-600 text-white p-4 rounded-lg shadow-lg flex items-center">
          <span>Payment completed successfully!</span>
          <button onClick={handleSnackbarClose} className="ml-4 text-xl font-bold">&times;</button>
        </div>
      )}
    </div>
  );
};

export default CartCheckoutPage;
