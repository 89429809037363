import { BookOpenIcon, CodeBracketIcon, ShoppingBagIcon } from '@heroicons/react/24/outline';
import './Footer.css'; // Import your updated CSS

export default function Footer({ theme }) {
  const footerThemeClass = theme === 'dark' ? 'footer-dark-theme' : 'footer-light-theme';

  return (
    <div id='Main-Footer' className={`relative isolate overflow-hidden  bg-white dark:bg-black py-16 sm:py-24 lg:py-32 footer ${footerThemeClass}`}>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Stay Updated with Our Latest Resources</h2>
            <p className="mt-4 text-lg leading-8 text-black-300 dark:text-gray" >
              Get the latest updates on our ebooks, courses, and eProducts designed to elevate your web development skills.
              Join our mailing list to never miss an update.
            </p>
            <div className="mt-6 flex max-w-md gap-x-4">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                placeholder="Enter your email"
                autoComplete="email" 
                style={{border:'1px solid black'}}
                className="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
              />
              <button
                type="submit"
                className="flex-none rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Subscribe
              </button>
            </div>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start" >
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-black/10 dark:ring-white">
                <BookOpenIcon aria-hidden="true" className="h-6 w-6 text-black dark:text-white" />
              </div>
              <dt className="mt-4 font-semibold">Expert Ebooks</dt>
              <dd className="mt-2 leading-7 text-black dark:text-white">
                Access comprehensive guides and ebooks that cover essential web development techniques and best practices.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-black/10 dark:ring-white">
                <CodeBracketIcon aria-hidden="true" className="h-6 w-6 text-black dark:text-white" />
              </div>
              <dt className="mt-4 font-semibold">Interactive Courses</dt>
              <dd className="mt-2 leading-7 text-black-400 dark:text-gray">
                Enroll in hands-on courses designed to help you master the latest web development technologies and tools.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-black/10 dark:ring-white">
                <ShoppingBagIcon aria-hidden="true" className="h-6 w-6 text-black dark:text-white" />
              </div>
              <dt className="mt-4 font-semibold">Premium eProducts</dt>
              <dd className="mt-2 leading-7 text-black-400 dark:text-gray">
                Explore our curated collection of eProducts that enhance your web development workflow and productivity.
              </dd>
            </div>
          </dl>


          <div className="mt-12 border-t pt-6">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 lg:grid-cols-4">
              {/* <div className="space-y-2">
                <h4 className="text-lg font-semibold">About Us</h4>
                <ul className="space-y-1">
                  <li><a href="#" className="text-gray-400 hover:text-white">Our Story</a></li>
                  <li><a href="#" className="text-gray-400 hover:text-white">Careers</a></li>
                  <li><a href="#" className="text-gray-400 hover:text-white">Press</a></li>
                </ul>
              </div> */}
              <div className="space-y-2">
                <h4 className="text-lg font-semibold">Support</h4>
                <ul className="space-y-1">
                  <li><a target='_blank' href="https://codewithmuhilan.com/Return.html" className="text-gray-400 hover:text-white">Return & Refund</a></li>
                  {/* <li><a target='_blank' href="#" className="text-gray-400 hover:text-white">FAQ</a></li> */}
                  <li><a target='_blank' href="https://codewithmuhilan.com/Contact.html" className="text-gray-400 hover:text-white">Contact Us</a></li>
                </ul>
              </div>
              {/* <div className="space-y-2">
                <h4 className="text-lg font-semibold">Resources</h4>
                <ul className="space-y-1">
                  <li><a target='_blank' href="#" className="text-gray-400 hover:text-white">Blog</a></li>
                  <li><a target='_blank' href="#" className="text-gray-400 hover:text-white">Webinars</a></li>
                  <li><a target='_blank' href="#" className="text-gray-400 hover:text-white">Guides</a></li>
                </ul>
              </div> */}
              <div className="space-y-2">
                <h4 className="text-lg font-semibold">Legal</h4>
                <ul className="space-y-1">
                  <li><a target='_blank' href="https://codewithmuhilan.com/Privacy.html" className="text-gray-400 hover:text-white">Privacy Policy</a></li>
                  <li><a target='_blank' href="https://codewithmuhilan.com/Terms.html" className="text-gray-400 hover:text-white">Terms of Service</a></li>
                  {/* <li><a target='_blank' href="#" className="text-gray-400 hover:text-white">Cookie Policy</a></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        
        <div className="mt-12 border-t pt-6 text-center text-black-400 dark:text-gray">
          <p>&copy; {new Date().getFullYear()} CodeWithMuhilan. All rights reserved.</p>
          <p className="text-sm">Built with passion and commitment to excellence.</p>
        </div>
      </div>
      <div aria-hidden="true" className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6">
        <div
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#1e3c72] to-[#2a5298] opacity-60"
        />
      </div>
    </div>
  );
}
