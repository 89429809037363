import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './config/config';
import './App.css';
import './index.css';
import Home from './Screens/Home';
import Component from './Screens/Component';
import Ebook from './Screens/Ebook';
import Project from './Screens/Project';
import Cart from './Screens/Cart';
import Footer from './Components/Footer/Footer';
import FirebaseLogin from './Screens/Authentication';
import EmailVerification from './Screens/EmailVerification';
import SlideableMenu from './Components/SlideableMenu/SlideableMenu';
import MyProducts from './Screens/MyProducts';
import 'react-toastify/dist/ReactToastify.css';
import ProjectDetailPage from './Components/ProjectDetailPage/ProjectDetailPage';
import Editor from './Screens/Editor';
// import HtmlPage from './Components/TutorialSection/HtmlPage/HtmlPage';
import EbookHome from './Components/EbookHome/EbookHome';
import NotFound from './Screens/NotFound';
import AdminPanel from './Screens/AdminPanel';
import ConstructionPage from './Screens/ConstructionPage';
import Header from './Components/Header/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HtmlCourseViewPage from './Components/CourseViewPage/HtmlCourseViewPage';
import About from './Screens/About';
import ProjectCheckoutPage from './CheckoutPage/ProjectCheckoutPage';
import CartCheckoutPage from './CheckoutPage/CartCheckoutPage';
import PaymentConfirmationPage from './CheckoutPage/PaymentConfirmationPage/PaymentConfirmationPage';
import CartPaymentConfirmationPage from './CheckoutPage/PaymentConfirmationPage/CartPaymentConfirmationPage';

function App() {
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current location



  return (
    <div >

      <Header />

      {/* Slideable Menu */}
      <SlideableMenu />


      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <main className='bg-gray-200 dark:bg-gray-900'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Component" element={<Component />} />
          <Route path="/Ebook" element={<Ebook />} />
          <Route path="/Shop" element={<Project />} />
          <Route path="/Learn" element={<HtmlCourseViewPage />} />
          <Route path="/About" element={<About />} />
          <Route path="/Cart" element={<Cart />} />
          <Route path="/Editor" element={<Editor />} />
          <Route path="/Authentication" element={<FirebaseLogin />} />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/MyProducts" element={<MyProducts />} />
          {/* <Route path="/htmlpage" element={<EbookHome />} /> */}
          <Route path="/Admin" element={<AdminPanel />} />
          <Route path="/NotFound" element={<ConstructionPage />} />
          {/* <Route path="/topic/:topicId" element={<HtmlPage />} />
          <Route path="/topic/:topicId/subtopic/:subtopicId" element={<HtmlPage />} /> */}
          <Route path="/Shop/:productId" element={<ProjectDetailPage />} />
          <Route component={NotFound} />
          <Route path="/projectcheckout" element={<ProjectCheckoutPage />} />
          <Route path="/cartcheckout" element={<CartCheckoutPage />} />
          <Route path="/confirmation" element={<PaymentConfirmationPage />} />
          <Route path="/cartconfirmation" element={<CartPaymentConfirmationPage />} />


          <Route path="/htmlpage" element={<HtmlCourseViewPage />} />
          <Route path="/css" element={<ConstructionPage />} />
          <Route path="/js" element={<ConstructionPage />} />
          <Route path="/react" element={<ConstructionPage />} />
          <Route path="/node" element={<ConstructionPage />} />
          <Route path="/java" element={<ConstructionPage />} />
          <Route path="/python" element={<ConstructionPage />} />
          <Route path="/php" element={<ConstructionPage />} />
          <Route path="/" element={<HtmlCourseViewPage />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
}

export default App;