import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, doc, getDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../config/config';
import { useUser } from '../contexts/UserContext';
import LoaderSpinner from '../Components/LoaderSpinner/LoaderSpinner';
import { FaTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const [loading, setLoading] = useState(true);
    const [cartProducts, setCartProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [error, setError] = useState(null);
    const { user } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCartItems = async () => {
            if (!user || !user.email) {
                setLoading(false);
                return;
            }

            setLoading(true);

            try {
                const cartCollectionRef = collection(db, 'Cart');
                const q = query(cartCollectionRef, where('userEmail', '==', user.email));

                const unsubscribe = onSnapshot(q, async (querySnapshot) => {
                    const cartItems = querySnapshot.docs.map(doc => doc.data());
                    const productIds = [...new Set(cartItems.map(item => item.productId).filter(id => id))];

                    if (productIds.length > 0) {
                        const productsCollectionRef = collection(db, 'projects');
                        const productsQuery = query(productsCollectionRef, where('id', 'in', productIds));
                        const productsSnapshot = await getDocs(productsQuery);

                        const productDocs = [];
                        for (const id of productIds) {
                            const docRef = doc(db, 'projects', id);
                            const docSnap = await getDoc(docRef);
                            if (docSnap.exists()) {
                                productDocs.push({ id: docSnap.id, ...docSnap.data() });
                            }
                        }

                        setCartProducts(productDocs);

                        const total = productDocs.reduce((sum, product) => sum + (product.price === "Free" ? 0 : parseFloat(product.price) || 0), 0);
                        setTotalPrice(total);
                    } else {
                        setCartProducts([]);
                    }
                });

                // Cleanup subscription on unmount
                return () => unsubscribe();
            } catch (error) {
                console.error("Error fetching cart items or products:", error.message);
                setError(`Failed to load cart items. Error: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        if (user) {
            fetchCartItems();
        } else {
            setLoading(false);
        }
    }, [user]);

    const handleDelete = async (productId) => {
        if (window.confirm("Are you sure you want to remove this item from the cart?")) {
            try {
                const cartCollectionRef = collection(db, 'Cart');
                const q = query(cartCollectionRef, where('userEmail', '==', user.email), where('productId', '==', productId));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const docRef = doc(db, 'Cart', querySnapshot.docs[0].id);
                    await deleteDoc(docRef);

                    setCartProducts(prevProducts => prevProducts.filter(product => product.id !== productId));

                    const updatedTotal = cartProducts
                        .filter(product => product.id !== productId)
                        .reduce((sum, product) => sum + (product.price === "Free" ? 0 : parseFloat(product.price) || 0), 0);
                    setTotalPrice(updatedTotal);
                }
            } catch (error) {
                console.error("Error deleting cart item:", error.message);
                setError(`Failed to delete item. Error: ${error.message}`);
            }
        }
    };

    const handleCheckout = () => {
        if (!user || !user.email) {
            toast.error("User is not authenticated.");
            return;
        }

        navigate('/cartcheckout', { state: { products: cartProducts, totalPrice } });
    };

    return (
        <div className="p-4 bg-gray-200 dark:bg-gray-900 min-h-screen">
            {loading ? (
                <LoaderSpinner />
            ) : error ? (
                <div className="text-red-500">{error}</div>
            ) : (
                <div className='relative top-20'>
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-2">Your Cart</h1>
                    {cartProducts.length > 0 ? (
                        <div className="relative  mb-20 p-2 sm:p-6 lg:p-8 bg-gray-200 dark:bg-gray-900 min-h-screen flex flex-col items-center">
                            <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg shadow-md border border-gray-200 dark:border-gray-700">
                                <thead className="bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300">
                                    <tr>
                                        <th className="py-3 px-4 text-left">Product</th>
                                        <th className="py-3 px-4 text-left">Price</th>
                                        <th className="py-3 px-4 text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-700 dark:text-gray-300">
                                    {cartProducts.map(product => (
                                        <tr key={product.id} className="border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 transition">
                                            <td className="py-3 px-4 flex items-center">
                                                <img src={product.image || 'https://via.placeholder.com/100'} alt={product.title} className="w-16 h-16 object-cover rounded-md mr-4" />
                                                {product.title}
                                            </td>
                                            <td className="py-3 px-4">
                                                {product.price === "Free" ? "Free" : `₹ ${product.price}`}
                                            </td>
                                            <td className="py-3 px-4 text-right">
                                                <button
                                                    className="text-red-500 hover:text-red-600 focus:outline-none"
                                                    onClick={() => handleDelete(product.id)}
                                                >
                                                    <FaTrashAlt />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan="2" className="py-3 px-4 text-right font-semibold">Subtotal:</td>
                                        <td className="py-3 px-4 text-right">
                                            ₹ {totalPrice.toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="py-3 px-4 text-right font-semibold">Tax (10%):</td>
                                        <td className="py-3 px-4 text-right">
                                            ₹ {(totalPrice * 0.10).toFixed(2)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="py-3 px-4 text-right font-semibold">Total:</td>
                                        <td className="py-3 px-4 text-right font-bold">
                                            ₹ {(totalPrice * 1.10).toFixed(2)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="mt-6 flex justify-end">
                                <button
                                    className="py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition"
                                    onClick={handleCheckout}
                                >
                                    Proceed to Checkout
                                </button>
                            </div>
                        </div>
                    ) : (
                        <p className="text-gray-600 dark:text-gray-400 text-center">Your cart is empty</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default Cart;
