import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import ProjectCard from '../ProjectCard/ProjectCard';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/config';
import { Link } from 'react-router-dom';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';

const HomeFeatureProjects = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    // Check screen size to determine mobile view
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 500);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch 6 random projects every time the page is loaded or refreshed
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'projects'));
                const fetchedProjects = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                // Shuffle and pick 6 random projects
                const shuffledProjects = fetchedProjects.sort(() => 0.5 - Math.random());
                setProjects(shuffledProjects.slice(0, 6));
            } catch (err) {
                setError('Failed to fetch projects');
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    if (loading) {
        return (
            <Container>
                <div className="flex justify-center items-center h-screen">
                    <LoaderSpinner /> {/* Show loading spinner */}
                </div>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <div className="flex justify-center items-center h-screen text-red-500">
                    <Typography color="error">Error loading projects: {error}</Typography> {/* Display error message */}
                </div>
            </Container>
        );
    }

    return (
        <div className="pb-40 pt-16 sm:pb-32 sm:pt-24 lg:pb-40 lg:pt-32 bg-gray-200 dark:bg-gray-900">
            <div className="relative top-10 flex flex-col container mx-auto max-w-6xl w-full sm:w-[90%]">
                <h1
                    style={{ position: 'relative', top: '-30px' }}
                    className="text-3xl sm:text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white text-center"
                >
                    Featured Projects
                </h1>

                {/* Projects Grid */}
                <div style={{ paddingBottom: '75px' }} className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                    {/* If mobile, show only 3 projects */}
                    {projects.slice(0, isMobile ? 3 : projects.length).map((project) => (
                        <Grid item key={project.id} xs={12} sm={6} md={4} lg={3} xl={3}>
                            <Link to={`/Shop/${project.id}`} style={{ textDecoration: 'none' }}>
                                <div className="flex flex-col h-full">
                                    <ProjectCard project={project} className="flex flex-col justify-between h-full" />
                                </div>
                            </Link>
                        </Grid>
                    ))}
                </div>

                {/* Footer Space */}
                <div className="h-24 bg-gray-200 dark:bg-gray-900 mt-auto" />
            </div>
        </div>
    );
};

export default HomeFeatureProjects;
