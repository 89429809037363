import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { FaDollarSign, FaMinus, FaPlus, FaCheckCircle, FaTag, FaTools, FaShoppingCart, FaStar, FaUser, FaQuoteLeft, FaHome, FaQuestionCircle, FaCopy, FaShareAlt, FaWhatsapp, FaInstagram, FaEnvelope, FaTwitter } from 'react-icons/fa';
import { db } from '../../config/config'; // Import Firestore instance
import { IconButton, Tooltip, Popover, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material'; // For advanced UI features
import { toast } from 'react-toastify';
import { useUser } from '../../contexts/UserContext'; // Import the UserContext hook
import ReviewsSection from './ReviewsSection';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';
import { Container, Typography } from '@mui/material';
import RelatedProjects from './RelatedProjects';


const ProductDetailPage = () => {
    const { productId } = useParams();
    const [project, setProject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isInCart, setIsInCart] = useState(false);
    const { user } = useUser(); // Use the UserContext to get the current user
    const navigate = useNavigate(); // Hook for navigation

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const docRef = doc(db, 'projects', productId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setProject(docSnap.data());
                } else {
                    setError('Project not found');
                }
            } catch (err) {
                setError('Failed to fetch project');
            } finally {
                setLoading(false);
            }
        };

        fetchProject();
    }, [productId]);

    useEffect(() => {
        if (user && project) {
            checkIfInCart();
        }
    }, [user, project]);

    const checkIfInCart = async () => {
        try {
            const cartRef = collection(db, 'Cart');
            const q = query(cartRef, where('userEmail', '==', user.email), where('productId', '==', productId));
            const querySnapshot = await getDocs(q);
            setIsInCart(!querySnapshot.empty);
        } catch (error) {
            console.error('Error checking cart status:', error);
        }
    };

    const addToCart = async (productId) => {
        try {
            const cartRef = collection(db, 'Cart');
            await addDoc(cartRef, {
                userEmail: user.email,
                productId: productId
            });
            toast.success('Added to cart.');
            setIsInCart(true); // Update state to reflect cart status
        } catch (error) {
            console.error('Error saving Cart details to Firestore:', error);
        }
    };

    const removeFromCart = async (productId) => {
        try {
            const cartRef = collection(db, 'Cart');
            const q = query(cartRef, where('userEmail', '==', user.email), where('productId', '==', productId));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

            toast.success('Removed from cart.');
            setIsInCart(false); // Update state to reflect cart status
        } catch (error) {
            console.error('Error removing Cart details from Firestore:', error);
        }
    };

    const handleCartButtonClick = (event) => {
        event.stopPropagation();  // Prevents the click event from propagating to parent elements
        event.preventDefault();   // Prevents the default behavior of the event

        if (!user) {
            toast.error('Please log in to add items to your cart.');
            navigate('/Authentication');
            return;
        }

        if (isInCart) {
            removeFromCart(productId);
        } else {
            addToCart(productId);
        }
    };


    const toggleAnswer = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success('Link copied to clipboard!');
    };

    const handleShareClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'share-popover' : undefined;

    const handleShareOptionClick = (url) => {
        window.open(url, '_blank');
        handleClose();
    };

    const getEmbedUrl = (videoUrl) => {
        // Check if it's a YouTube Shorts URL and convert it
        if (videoUrl.includes('shorts')) {
            return videoUrl.replace('/shorts/', '/embed/');
        }
        // Return the regular YouTube embed URL with options for disabling autoplay and infinite play
        return videoUrl;
    };



    if (loading) {
        return (
            <Container>
                <div className="flex justify-center items-center h-screen">
                    <LoaderSpinner /> {/* Show loading spinner */}
                </div>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <div className="flex justify-center items-center h-screen text-red-500">
                    <Typography color="error">Error loading projects: {error}</Typography> {/* Display error message */}
                </div>
            </Container>
        );
    }

    const {
        title = '',
        description,
        projectId,
        price,
        saleCount,
        published,
        highResolution,
        compatibleBrowsers,
        image = '',
        overview = '',
        rating = 0,
        client = '',
        clientQuote = '',
        techniques = [],
        features = [],
        gallery = [],
        faq = [],
        videoUrl = '',
        postUrl = ''
    } = project;

    const handleBuyNow = () => {
        navigate(`/projectcheckout?projectId=${projectId}`); // Navigate to checkout page with projectId
    };

    console.log(projectId);




    return (
        <div className=' bg-gray-200 dark:bg-gray-900'>

            <div className="relative pt-14 md:top-0  bg-gray-200 dark:bg-gray-900 min-h-screen p-6 xl:p-20 lg:p-20 md:p-20">
                {/* Top Section: Breadcrumb Navigation and Share Options */}
                <div className="flex flex-col  md:flex-row md:items-center justify-between mb-6">
                    {/* Breadcrumb Navigation */}
                    <nav className="flex items-center text-gray-700 dark:text-gray-300">
                        <Link to="/" className="text-blue-600 dark:text-blue-400 hover:underline flex items-center">
                            <FaHome className="mr-2" /> Home
                        </Link>
                        <span className="mx-2">/</span>
                        <Link to="/Shop" className="text-blue-600 dark:text-blue-400 hover:underline flex items-center">
                            Shop
                        </Link>
                        <span className="mx-2">/</span>
                        <span className="text-gray-900 dark:text-gray-100 truncate">{title}</span>
                    </nav>
                    {/* Share Options */}
                    <div className="flex space-x-4 mt-4 md:mt-0">
                        <Tooltip title="Copy Link" arrow placement="top">
                            <IconButton
                                onClick={handleCopyLink}
                                style={{
                                    background: 'linear-gradient(135deg, #6D5BBA 0%, #8A7AC7 100%)',
                                    color: '#fff',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                                    transition: 'transform 0.2s',
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            >
                                <FaCopy size={24} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share on Social Media" arrow placement="top">
                            <IconButton
                                onClick={handleShareClick}
                                style={{
                                    background: 'linear-gradient(135deg, #6D5BBA 0%, #8A7AC7 100%)',
                                    color: '#fff',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                                    transition: 'transform 0.2s',
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                            >
                                <FaShareAlt size={24} />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            PaperProps={{
                                style: {
                                    borderRadius: '12px',
                                    padding: '12px 0',
                                    boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
                                    background: 'linear-gradient(135deg, #f3f4f6 0%, #e2e3e9 100%)',
                                },
                            }}
                        >
                            <List style={{ width: '280px', padding: '8px' }}>
                                <ListItem button onClick={() => handleShareOptionClick(`https://wa.me/?text=${encodeURIComponent(window.location.href)}`)}>
                                    <ListItemIcon style={{ color: '#25D366' }}>
                                        <FaWhatsapp />
                                    </ListItemIcon>
                                    <ListItemText primary="Share on WhatsApp" />
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem button onClick={() => handleShareOptionClick(`https://www.instagram.com/sharer.php?u=${encodeURIComponent(window.location.href)}`)}>
                                    <ListItemIcon style={{ color: '#E1306C' }}>
                                        <FaInstagram />
                                    </ListItemIcon>
                                    <ListItemText primary="Share on Instagram" />
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem button onClick={() => handleShareOptionClick(`mailto:?subject=Check this out&body=${encodeURIComponent(window.location.href)}`)}>
                                    <ListItemIcon style={{ color: '#007BFF' }}>
                                        <FaEnvelope />
                                    </ListItemIcon>
                                    <ListItemText primary="Share via Email" />
                                </ListItem>
                                <Divider variant="middle" />
                                <ListItem button onClick={() => handleShareOptionClick(`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`)}>
                                    <ListItemIcon style={{ color: '#1DA1F2' }}>
                                        <FaTwitter />
                                    </ListItemIcon>
                                    <ListItemText primary="Share on X" />
                                </ListItem>
                            </List>
                        </Popover>
                    </div>

                </div>

                {/* Main Content Area */}
                <div className="max-w-7xl mx-auto bg-white dark:bg-gray-800  shadow-lg p-2">
                    {/* Product Details Layout */}
                    <div className="flex flex-col lg:flex-row lg:items-start">
                        {/* Product Image */}
                        <div className="lg:w-1/2 lg:mr-8 mb-6 lg:mb-0">
                            <img src={image} alt={title} className="w-full h-106 object-cover rounded-lg shadow-md" />
                        </div>

                        {/* Product Information */}
                        <div className="lg:w-1/2">
                            <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-100 mb-4">{title}</h1>
                            <p className="text-gray-600 dark:text-gray-400 text-lg leading-relaxed mb-6">{description}</p>
                            <div className="text-2xl font-semibold text-gray-900 dark:text-gray-100 mb-6">
                                <div className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
                                    <span className="text-3xl font-bold">₹{price}</span>
                                </div>
                                <div className="flex items-center space-x-4">
                                    <div className="flex items-center space-x-2 bg-gray-100 dark:bg-gray-800 p-3 rounded-lg shadow-md">
                                        <FaTag className="text-green-600 dark:text-green-400" />
                                        <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                                            {saleCount} Sold
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-around  xl:justify-start items-center mb-6">
                                <button
                                    className="px-4 py-2 sm:px-6 sm:py-3 lg:px-8 lg:py-4 bg-blue-600 text-white text-sm sm:text-md lg:text-lg font-semibold rounded-lg shadow-md hover:bg-blue-700 transition-colors flex items-center"
                                    onClick={handleCartButtonClick}
                                >
                                    <FaShoppingCart className="mr-2 text-sm sm:text-md lg:text-lg" />
                                    {isInCart ? 'Remove from Cart' : 'Add to Cart'}
                                </button>
                                <button
                                    onClick={handleBuyNow}
                                    className="px-4 py-2 sm:px-6 sm:py-3 lg:px-8 lg:py-4  lg:ml-8 xl:ml-10 bg-green-600 text-white text-sm sm:text-md lg:text-lg font-semibold rounded-lg shadow-md hover:bg-green-700 transition-colors flex items-center"
                                >
                                    <FaDollarSign className="mr-2 text-sm sm:text-md lg:text-lg" /> Buy Now
                                </button>
                            </div>



                            {/* Product Features */}
                            <div className="mt-6 p-4 rounded-lg shadow-md">
                                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Product Features</h2>
                                <ul className="space-y-2">
                                    <li className='text-gray-900 dark:text-gray-100' ><strong >Published:</strong> {published}</li>
                                    <li className='text-gray-900 dark:text-gray-100'><strong>High Resolution:</strong> {highResolution}</li>
                                    <li className='text-gray-900 dark:text-gray-100'><strong>Compatible Browsers:</strong> {compatibleBrowsers}</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Project Overview and Details */}
                    <div className="border-t border-gray-300 dark:border-gray-700 pt-6 mt-12">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {/* Project Overview */}
                            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Project Overview</h2>
                                <p className="text-gray-600 dark:text-gray-400 mb-4">{overview || 'No overview available'}</p>
                                <div className="flex items-center mb-4">
                                    <FaStar className="text-yellow-500 mr-2" />
                                    <span className="text-xl font-semibold">{rating} / 5</span>
                                </div>
                                <div className="flex items-center mb-4">
                                    <FaUser className="text-gray-700 dark:text-gray-300 mr-2" />
                                    <span className="text-lg font-semibold">Developed by: {client || 'Not specified'}</span>
                                </div>
                                {/* <div className="flex items-center">
                                    <FaQuoteLeft className="text-gray-700 dark:text-gray-300 mr-2" />
                                    <span className="text-lg italic">{clientQuote || 'No client quote available'}</span>
                                </div> */}
                            </div>

                            {/* Techniques and Features */}
                            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg">
                                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Techniques Used</h2>
                                {techniques.length > 0 ? (
                                    <ul className="list-disc list-inside mb-6 text-gray-600 dark:text-gray-400">
                                        {techniques.map((tech, index) => (
                                            <li key={index} className="flex items-center text-lg">
                                                <FaTools className="text-indigo-500 mr-3" /> {tech}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-600 dark:text-gray-400">No techniques listed</p>
                                )}

                                <h2 className="text-2xl font-bold text-gray-900 dark:text-gray-100 mb-4">Features</h2>
                                {features.length > 0 ? (
                                    <ul className="list-disc list-inside text-gray-600 dark:text-gray-400">
                                        {features.map((feature, index) => (
                                            <li key={index} className="flex items-center text-lg">
                                                <FaCheckCircle className="text-green-500 mr-3" /> {feature}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className="text-gray-600 dark:text-gray-400">No features listed</p>
                                )}
                            </div>
                        </div>
                    </div>


                    {/* Video Section */}
                    {videoUrl && (
                        <div className="youtube-reel-container border-t pt-6 mt-12 relative overflow-hidden w-full h-0 pb-[56.25%] bg-gray-100 dark:bg-gray-800">
                            <iframe
                                className="absolute top-0 left-0 w-full h-full"
                                src={getEmbedUrl(videoUrl)} // Apply the conversion function here
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="YouTube Reel"
                            ></iframe>
                        </div>
                    )}








                    {/* Photo Gallery */}
                    {gallery.length > 0 && (
                        <div className="border-t border-gray-300 dark:border-gray-700 pt-6 mt-12">
                            <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">Photo Gallery</h2>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                {gallery.map((img, index) => (
                                    <div key={index} className="relative group">
                                        <img src={img} alt={`Gallery ${index}`} className="w-full h-64 object-cover rounded-lg shadow-md" />
                                        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black bg-opacity-50 rounded-lg">
                                            <FaShareAlt className="text-white text-3xl" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* FAQ Section */}
                    {faq.length > 0 && (
                        <div className="border-t border-gray-300 dark:border-gray-700 pt-6 mt-12">
                            <h2 className="text-3xl font-bold text-gray-900 dark:text-gray-100 mb-4">FAQ</h2>
                            {faq.map((item, index) => (
                                <div key={index} className="mb-6">
                                    <button
                                        onClick={() => toggleAnswer(index)}
                                        className="flex items-center w-full text-left text-gray-900 dark:text-gray-100 font-semibold text-xl focus:outline-none"
                                    >
                                        <span className="flex-1">{item.question}</span>
                                        {expandedIndex === index ? <FaMinus className="ml-2" /> : <FaPlus className="ml-2" />}
                                    </button>
                                    {expandedIndex === index && (
                                        <p className="text-gray-600 dark:text-gray-400 mt-2">{item.answer}</p>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}


                    {/* Reviews Section */}
                    <ReviewsSection productId={productId} />




                </div>
            </div>

            {/* Related Projects Section */}
            <div className="my-8">
                <RelatedProjects currentProjectId={id} />
            </div>

        </div>
    );
};

export default ProductDetailPage;

