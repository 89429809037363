import React, { useState, useEffect } from 'react';
import {
    StarIcon,
    BookOpenIcon,
    CodeBracketIcon,
    ShoppingBagIcon,
    CalendarIcon,
    UserIcon,
    GlobeAltIcon,
    LightBulbIcon,
    ChatBubbleBottomCenterIcon,
    QuestionMarkCircleIcon,
    TrophyIcon,
    EnvelopeIcon, // Use this instead of MailIcon
} from '@heroicons/react/24/outline';
import { PlusIcon, MinusIcon } from '@heroicons/react/24/outline';

import AdditionalResourses from './AdditionalResourses';
import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import InstructorInfo from './InstructorInfo';
import RelatedCourses from './RelatedCourses';
import Awards from './Awards';
import EbookContactForm from './EbookContactForm';
import EbookCarouselTestimonials from './EbookCarouselTestimonials';
import EbookOverview from './EbookOverview';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/config';
import CourseBanner from './CourseBanner';


const EbookMain = ({ theme }) => {
    const [courseData, setCourseData] = useState(null);
    const FOUR_DAYS_IN_SECONDS = 4 * 24 * 60 * 60; // 4 days in seconds

    // Fetch data from Firestore
    const fetchCourseData = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'courseData'));
            const data = querySnapshot.docs.map(doc => doc.data());
            // Assuming there's only one document in the collection
            setCourseData(data[0]);
        } catch (error) {
            console.error("Error fetching course data: ", error);
        }
    };

    useEffect(() => {
        fetchCourseData();
    }, []);

    const getSavedEndTime = () => {
        const endTime = localStorage.getItem('timerEndTime');
        return endTime ? parseInt(endTime, 10) : null;
    };

    const setEndTime = (endTime) => {
        localStorage.setItem('timerEndTime', endTime.toString());
    };

    const initializeTimeLeft = () => {
        const savedEndTime = getSavedEndTime();
        if (savedEndTime) {
            const currentTime = Math.floor(Date.now() / 1000);
            const timeLeft = savedEndTime - currentTime;
            if (timeLeft > 0) return timeLeft;
        }
        const newEndTime = Math.floor(Date.now() / 1000) + FOUR_DAYS_IN_SECONDS;
        setEndTime(newEndTime);
        return FOUR_DAYS_IN_SECONDS;
    };

    const [timeLeft, setTimeLeft] = useState(initializeTimeLeft);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                const currentTime = Math.floor(Date.now() / 1000);
                if (prev > 0) {
                    const updatedTimeLeft = prev - 1;
                    if (updatedTimeLeft === 0) {
                        const newEndTime = currentTime + FOUR_DAYS_IN_SECONDS;
                        setEndTime(newEndTime);
                        return FOUR_DAYS_IN_SECONDS;
                    }
                    return updatedTimeLeft;
                } else {
                    const newEndTime = currentTime + FOUR_DAYS_IN_SECONDS;
                    setEndTime(newEndTime);
                    return FOUR_DAYS_IN_SECONDS;
                }
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const days = Math.floor(seconds / (24 * 60 * 60));
        const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((seconds % (60 * 60)) / 60);
        const secs = seconds % 60;
        return `${days}d ${hours}h ${minutes}m ${secs < 10 ? '0' : ''}${secs}s`;
    };

    const courseThemeClass = theme === 'dark' ? 'course-dark-theme' : 'course-light-theme';

    return (
        <div id="Course-Section" className={`relative isolate overflow-hidden bg-gray-200 dark:bg-gray-900 py-16 sm:py-24 lg:py-32 ${courseThemeClass}`}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="relative  text-center mx-4 my-6 sm:my-12">
                    <h2 className="text-2xl font-bold tracking-tight dark:text-white lg:text-5xl sm:text-3x">
                        {courseData?.header}
                    </h2>
                    <p
                    //  style={{lineHeight:'-10px'}}
                    className=" text-2xl mt-4 text-xs lg:text-md sm:text-sm leading-8 text-gray-700 dark:text-gray-300">
                        {courseData?.description}
                    </p>
                </div>




                <CourseBanner />
                <EbookOverview />
                <AdditionalResourses />
                <FrequentlyAskedQuestions />
                <div className="mt-12 grid grid-cols-1 gap-8 lg:grid-cols-2">
                    <InstructorInfo />
                    <Awards />
                </div>
                <EbookCarouselTestimonials />
                <RelatedCourses />
                <EbookContactForm />
                {/* <div className="absolute  top-16  inset-x-0 bg-indigo-600 text-white text-center py-2">
                    <p className="text-lg font-semibold">🎉 Limited Time Offer: {formatTime(timeLeft)} remaining!</p>
                </div> */}
                <div className="absolute bottom-0 inset-x-0 bg-indigo-600 text-white text-center py-2">
                    <p className="text-md font-semibold">🎉 Limited Time Offer: {formatTime(timeLeft)} remaining!</p>
                </div>
            </div>
        </div>
    );
};

export default EbookMain;
