import React, { useState, useEffect } from 'react';
import ProductCard from '../Components/ProductCard/ProductCard';
import { MagnifyingGlassIcon, FunnelIcon, XMarkIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../config/config';
import LoaderSpinner from '../Components/LoaderSpinner/LoaderSpinner'; // Import the LoaderSpinner component

const categories = ['All', 'Category1', 'Category2'];

const Component = () => {

    const [products, setProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true); // Start loading
                const querySnapshot = await getDocs(collection(db, 'Products'));
                const productsArray = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        title: data.name || 'Untitled',
                        category: data.category || 'Unknown',
                        description: data.description || '',
                        image: data.image || '',
                        link: data.link || '',
                        price: typeof data.price === 'number' ? data.price : parseFloat(data.price) || 0, // Ensure price is a number
                        ...data
                    };
                });

                setProducts(productsArray);
            } catch (error) {
                console.error('Error fetching products: ', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchProducts();
    }, []);



    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
        setIsSidebarOpen(false);
    };

    const getFilteredProducts = () => {
        const matchedProducts = products.filter(product => {
            const title = product.title || ''; // Ensure title is always a string
            const category = product.category || 'Unknown'; // Ensure category is always defined
            const matchesSearch = title.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = selectedCategory === 'All' || category === selectedCategory;
            return matchesSearch && matchesCategory;
        });

        const nonMatchedProducts = products.filter(product => {
            const title = product.title || ''; // Ensure title is always a string
            const category = product.category || 'Unknown'; // Ensure category is always defined
            const matchesSearch = title.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = selectedCategory === 'All' || category === selectedCategory;
            return !matchesSearch || !matchesCategory;
        });

        return [...matchedProducts, ...nonMatchedProducts];
    };

    const filteredProducts = getFilteredProducts();

    return (
        <div className="bg-gray-100 dark:bg-gray-900 min-h-screen pt-10">
            {loading ? (
                <LoaderSpinner /> // Show loader while loading
            ) : (
                <div className="relative top-10 flex flex-col container mx-auto max-w-6xl w-full sm:w-[90%]">

                    {/* Search and Filter Section */}
                    <div className="relative">
                        {/* Search Bar */}
                        <div className='p-10'
                        >
                            <div className="relative w-full max-w-xs">
                                <input
                                    type="text"
                                    placeholder="Search courses..."
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    className="w-full pl-10 pr-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-100 rounded-lg shadow-sm focus:outline-none focus:ring focus:ring-blue-500"
                                />
                                <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400 dark:text-gray-300" />
                            </div>

                        </div>

                    </div>

                    {/* Products Grid */}
                    <div style={{ paddingBottom: '75px' }} className=" grid gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                        {filteredProducts.length > 0 ? (
                            filteredProducts.map(product => (
                                <ProductCard key={product.id} product={product} />
                            ))
                        ) : (
                            <div className="col-span-full text-center text-gray-700 dark:text-gray-300 text-lg font-semibold">
                                No products available
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Component;
