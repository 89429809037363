import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, where, orderBy, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';

const ReviewsSection = ({ productId }) => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [reviewText, setReviewText] = useState('');
    const [rating, setRating] = useState(5);
    const [userReviewed, setUserReviewed] = useState(false);
    const [reviewIdToEdit, setReviewIdToEdit] = useState(null);
    const [newReviewText, setNewReviewText] = useState('');
    const [newReviewRating, setNewReviewRating] = useState(5);
    const [showMenu, setShowMenu] = useState(null);
    const { currentUser } = useAuth();

    const fetchReviews = async () => {
        try {
            const reviewsQuery = query(
                collection(db, 'reviews'),
                where('productId', '==', productId),
                orderBy('date', 'desc')
            );
            const reviewsSnapshot = await getDocs(reviewsQuery);
            const reviewsList = reviewsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setReviews(reviewsList);

            if (currentUser) {
                const userReviewQuery = query(
                    collection(db, 'reviews'),
                    where('productId', '==', productId),
                    where('userId', '==', currentUser.uid)
                );
                const userReviewSnapshot = await getDocs(userReviewQuery);
                setUserReviewed(!userReviewSnapshot.empty);
            }
        } catch (error) {
            console.error('Error fetching reviews: ', error);
            setError('Error loading reviews. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, [productId, currentUser]);

    const handleReviewSubmit = async (e) => {
        e.preventDefault();
        if (!reviewText.trim()) return;

        try {
            await addDoc(collection(db, 'reviews'), {
                userId: currentUser.uid,
                userName: currentUser.displayName || 'Anonymous',
                userProfilePic: currentUser.photoURL || 'default-profile-pic-url',
                date: new Date(),
                productId,
                text: reviewText,
                rating
            });
            setReviewText('');
            setRating(5);
            fetchReviews();
        } catch (error) {
            console.error('Error posting review: ', error);
            setError('Error posting review. Please try again later.');
        }
    };

    const handleReviewEdit = async (reviewId) => {
        try {
            const reviewRef = doc(db, 'reviews', reviewId);
            await updateDoc(reviewRef, {
                text: newReviewText,
                rating: newReviewRating
            });
            setReviewIdToEdit(null);
            setNewReviewText('');
            setNewReviewRating(5);
            fetchReviews();
        } catch (error) {
            console.error('Error updating review: ', error);
            setError('Error updating review. Please try again later.');
        }
    };

    const handleReviewDelete = async (reviewId) => {
        try {
            await deleteDoc(doc(db, 'reviews', reviewId));
            fetchReviews();
        } catch (error) {
            console.error('Error deleting review: ', error);
            setError('Error deleting review. Please try again later.');
        }
    };

    const handleClickOutside = (event) => {
        if (event.target.closest('.review-menu')) return;
        setShowMenu(null);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <div className="relative reviews-section mt-10 p-4 sm:p-6 bg-gray-100 dark:bg-gray-800">
            <h2 className="text-xl sm:text-3xl font-semibold mb-4 text-center text-gray-900 dark:text-gray-100">Customer Reviews</h2>
            <div className="write-review bg-white dark:bg-gray-900 p-4 sm:p-6   mb-6">
                <h3 className="text-lg sm:text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Write a Review</h3>
                {currentUser ? (
                    userReviewed ? (
                        <p className="text-xs sm:text-sm text-gray-700 dark:text-gray-300 italic">You've already submitted a review for this product.</p>
                    ) : (
                        <form onSubmit={handleReviewSubmit} className="space-y-4">
                            <div className="flex items-center space-x-2">
                                <label className="text-sm sm:text-lg font-medium text-gray-900 dark:text-gray-100">Rating:</label>
                                <div className="flex items-center">
                                    {[...Array(5)].map((_, index) => (
                                        <svg
                                            key={index}
                                            onClick={() => setRating(index + 1)}
                                            className={`w-5 h-5 sm:w-6 sm:h-6 cursor-pointer ${rating > index ? 'text-yellow-400' : 'text-gray-300'}`}
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73-1.64 7.03L12 17.27z" />
                                        </svg>
                                    ))}
                                </div>
                            </div>
                            <textarea
                                value={reviewText}
                                onChange={(e) => setReviewText(e.target.value)}
                                placeholder="Write your review..."
                                rows="4"
                                className="w-full border border-gray-300 rounded-md p-2 sm:p-3 focus:outline-none focus:border-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100 text-sm sm:text-base"
                            />
                            <button
                                type="submit"
                                className="bg-blue-600 text-white font-semibold py-2 px-4 sm:py-2 sm:px-6 rounded-md hover:bg-blue-700 shadow-lg transition duration-200"
                            >
                                Submit Review
                            </button>
                        </form>
                    )
                ) : (
                    <p className="text-xs sm:text-sm text-gray-700 dark:text-gray-300">Please <a href="/Authentication" className="text-blue-600 hover:underline">log in</a> to write a review.</p>
                )}
            </div>

            {loading ? (
                <p className="text-center text-gray-700 dark:text-gray-300">Loading reviews...</p>
            ) : error ? (
                <p className="text-center text-red-600">{error}</p>
            ) : reviews.length === 0 ? (
                <p className="text-center text-gray-600 dark:text-gray-400 italic">No reviews yet.</p>
            ) : (
                <div className="reviews-list space-y-4 sm:space-y-6">
                    {reviews.map(review => (
                        <div key={review.id} className="review flex items-start relative">
                            <img
                                src={review.userProfilePic}
                                alt={`${review.userName}'s profile`}
                                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 border-gray-300 dark:border-gray-700 mr-3 sm:mr-4"
                            />
                            <div className="flex-1">
                                <div className="flex justify-between items-center mb-2">
                                    <h4 className="text-sm sm:text-lg font-semibold text-gray-900 dark:text-gray-100">{review.userName}</h4>
                                    <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-300">{new Date(review.date.toDate()).toLocaleDateString()} {new Date(review.date.toDate()).toLocaleTimeString()}</p>
                                </div>
                                <div className="flex mb-2">
                                    {[...Array(5)].map((_, index) => (
                                        <svg
                                            key={index}
                                            className={`w-4 h-4 sm:w-5 sm:h-5 ${review.rating > index ? 'text-yellow-400' : 'text-gray-300'}`}
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73-1.64 7.03L12 17.27z" />
                                        </svg>
                                    ))}
                                </div>
                                <p className="text-xs sm:text-sm text-gray-800 dark:text-gray-200">{review.text}</p>
                                {currentUser && review.userId === currentUser.uid && (
                                    <div className="review-actions mt-2 flex space-x-2">
                                        {/* <button
                                            onClick={() => {
                                                setReviewIdToEdit(review.id);
                                                setNewReviewText(review.text);
                                                setNewReviewRating(review.rating);
                                                setShowMenu(review.id);
                                            }}
                                            className="text-blue-600 dark:text-blue-400 text-xs sm:text-sm hover:underline"
                                        >
                                            Edit
                                        </button> */}
                                        <button
                                            onClick={() => handleReviewDelete(review.id)}
                                            className="text-red-600 dark:text-red-400 text-xs sm:text-sm hover:underline"
                                        >
                                            Delete
                                        </button>
                                    </div>
                                )}
                            </div>

                            {reviewIdToEdit === review.id && showMenu === review.id && (
                                <div className="edit-review-menu absolute top-full left-0 mt-2 p-4 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-md shadow-lg z-10">
                                    <h4 className="text-sm sm:text-base font-semibold text-gray-900 dark:text-gray-100 mb-2">Edit Your Review</h4>
                                    <textarea
                                        value={newReviewText}
                                        onChange={(e) => setNewReviewText(e.target.value)}
                                        placeholder="Edit your review..."
                                        rows="3"
                                        className="w-full border border-gray-300 rounded-md p-2 sm:p-3 mb-2 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-100 text-sm sm:text-base"
                                    />
                                    <div className="flex items-center mb-2">
                                        <label className="text-sm sm:text-base font-medium text-gray-900 dark:text-gray-100 mr-2">Rating:</label>
                                        <div className="flex items-center">
                                            {[...Array(5)].map((_, index) => (
                                                <svg
                                                    key={index}
                                                    onClick={() => setNewReviewRating(index + 1)}
                                                    className={`w-5 h-5 sm:w-6 sm:h-6 cursor-pointer ${newReviewRating > index ? 'text-yellow-400' : 'text-gray-300'}`}
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M12 17.27l6.18 3.73-1.64-7.03L22 9.24l-7.19-.62L12 2 9.19 8.62 2 9.24l5.46 4.73-1.64 7.03L12 17.27z" />
                                                </svg>
                                            ))}
                                        </div>
                                    </div>
                                    <button
                                        onClick={() => handleReviewEdit(review.id)}
                                        className="bg-blue-600 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-700"
                                    >
                                        Save Changes
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ReviewsSection;
