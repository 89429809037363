// src/components/LoaderSpinner/LoaderSpinner.js
import React from 'react';
import './LoaderSpinner.css'; // Import the spinner CSS

const LoaderSpinner = () => {
    return (
        <div className="spinner-overlay">
            <div className="spinner"></div>
        </div>
    );
};

export default LoaderSpinner;
