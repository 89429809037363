import { doc, getDoc } from '../../config/config'; // Ensure this is the correct path
import { db } from '../../config/config'; // Ensure this is the correct path
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { motion } from 'framer-motion';

const images = [
  "https://codewithmuhilan.com/Extra-Assets/HomeShowCase/ShowCaseImg1.jpg",
  "https://codewithmuhilan.com/Extra-Assets/HomeShowCase/ShowCaseImg2.jpg",
  "https://codewithmuhilan.com/Extra-Assets/HomeShowCase/ShowCaseImg3.png",
  "https://codewithmuhilan.com/Extra-Assets/HomeShowCase/ShowCaseImg4.jpg",
  "https://codewithmuhilan.com/Extra-Assets/HomeShowCase/ShowCaseImg5.png",
  "https://codewithmuhilan.com/Extra-Assets/HomeShowCase/ShowCaseImg6.jpg",
  "https://codewithmuhilan.com/Extra-Assets/HomeShowCase/ShowCaseImg7.jpg"
];

function shuffleArray(array) {
  let currentIndex = array.length, randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export default function HomeShowCase() {
  const [shuffledImages, setShuffledImages] = useState(images);
  const [content, setContent] = useState(null);
  const imageRefs = useRef([]);
  const titleRef = useRef(null);
  const cursorRef = useRef(null);

  useEffect(() => {
    if (imageRefs.current.length && imageRefs.current.every(ref => ref)) {
      const intervalId = setInterval(() => {
        gsap.to(imageRefs.current, {
          scale: 0.5,
          duration: 1,
          opacity: 0,
          onComplete: () => {
            setShuffledImages(prevImages => shuffleArray([...prevImages]));
            gsap.to(imageRefs.current, {
              scale: 1,
              duration: 1,
              opacity: 1,
            });
          }
        });
      }, 3000);
  
      return () => clearInterval(intervalId);
    }
  }, [shuffledImages]);
  


  useEffect(() => {
    const fetchContent = async () => {
      try {
        const contentRef = doc(db, 'homeShowcase', 'textContent');
        const contentSnap = await getDoc(contentRef);

        if (contentSnap.exists()) {
          setContent(contentSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchContent();
  }, []);

  useEffect(() => {
    if (content) {
      const text = content.title;
      const chars = text.split('');
      const html = chars.map((char, index) =>
        `<span class="char">${char}</span>`
      ).join('');
      if (titleRef.current) {
        titleRef.current.innerHTML = html;

        gsap.fromTo(
          titleRef.current.querySelectorAll('.char'),
          { opacity: 0, y: 20 },
          {
            opacity: 1,
            y: 0,
            duration: 0.05,
            stagger: 0.05,
            repeat: -1,
            repeatDelay: 1,
            yoyo: true,
          }
        );

        // Cursor blink animation
        gsap.to(cursorRef.current, {
          opacity: 0,
          repeat: -1,
          duration: 0.5,
          yoyo: true,
        });
      }
    }
  }, [content]);

  if (!content) {
    return null;
  }

  return (
    <div className="relative overflow-hidden bg-white">
      <div className="pb-80 pt-16 sm:pb-40 sm:pt-24 lg:pb-48 lg:pt-40">
        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          <div className="sm:max-w-lg">
            <motion.h1
              ref={titleRef}
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              {/* Title will be populated by JavaScript */}
              <span className="cursor" ref={cursorRef}>|</span>
            </motion.h1>
            <motion.p
              className="mt-4 text-xl text-gray-500"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              {content.description}
            </motion.p>
          </div>
          <div>
            <div className="mt-10">
              <div
                aria-hidden="true"
                className="pointer-events-none lg:absolute lg:inset-y-0 lg:mx-auto lg:w-full lg:max-w-7xl"
              >
                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                  <div className="flex items-center space-x-6 lg:space-x-8">
                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                      {shuffledImages.slice(0, 2).map((src, index) => (
                        <motion.div
                          key={index}
                          className="h-64 w-44 overflow-hidden rounded-lg"
                          ref={el => imageRefs.current[index] = el}
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.8, ease: "easeOut", delay: index * 0.2 }}
                        >
                          <img
                            alt=""
                            src={src}
                            className="h-full w-full object-contain object-center"
                          />
                        </motion.div>
                      ))}
                    </div>
                    <div id="ShowCaseImg" className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                      {shuffledImages.slice(2, 5).map((src, index) => (
                        <motion.div
                          key={index + 2}
                          className="h-64 w-44 overflow-hidden rounded-lg"
                          ref={el => imageRefs.current[index + 2] = el}
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.8, ease: "easeOut", delay: (index + 2) * 0.2 }}
                        >
                          <img
                            alt=""
                            src={src}
                            className="h-full w-full object-contain object-center"
                          />
                        </motion.div>
                      ))}
                    </div>
                    <div className="grid flex-shrink-0 grid-cols-1 gap-y-6 lg:gap-y-8">
                      {shuffledImages.slice(5).map((src, index) => (
                        <motion.div
                          key={index + 5}
                          className="h-64 w-44 overflow-hidden rounded-lg"
                          ref={el => imageRefs.current[index + 5] = el}
                          initial={{ opacity: 0, scale: 0.8 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.8, ease: "easeOut", delay: (index + 5) * 0.2 }}
                        >
                          <img
                            alt=""
                            src={src}
                            className="h-full w-full object-contain object-center"
                          />
                        </motion.div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <motion.a
                id="Regular-Btn"
                style={{ width: '185px' }}
                href="#"
                className="inline-block rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-center font-medium text-black hover:bg-indigo-700"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                {content.buttonText}
              </motion.a>
            </div>
          </div>
        </div>
      </div>

      <div className="relative overflow-hidden">
        <svg
          className="absolute inset-x-0 -bottom-48 -z-10 transform-gpu overflow-hidden text-gray-300 -translate-y-1/2 -translate-x-1/2 rotate-[-10deg] sm:-bottom-80 sm:translate-x-0 sm:rotate-[-15deg] lg:-bottom-48 lg:-translate-x-1/2 lg:rotate-[-20deg]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="f8b83ef4-928e-4d85-a687-08f89768f1d8"
              x={0}
              y={0}
              width={200}
              height={200}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={200} height={200} className="text-gray-200" />
              <path d="M-100 100l200-200M100 100L-100-100" stroke="currentColor" strokeWidth={2} />
            </pattern>
          </defs>
          <svg x={-100} y={-100} className="overflow-visible fill-current text-gray-200">
            <rect
              width={200}
              height={200}
              transform="rotate(45 100 100)"
              fill="url(#f8b83ef4-928e-4d85-a687-08f89768f1d8)"
            />
          </svg>
        </svg>
      </div>
    </div>
  );
}
