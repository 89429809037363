// src/Screens/EmailVerification.js
import React from 'react';
import { Link } from 'react-router-dom';

const EmailVerification = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 dark:bg-gray-900">
      <h1 className="text-2xl font-semibold text-gray-900 dark:text-white">Email Verification Required</h1>
      <p className="mt-4 text-gray-600 dark:text-gray-400">
        Your email address needs to be verified before you can access the site. Please check your inbox for the verification email.
      </p>
      <Link to="/Authentication" className="mt-6 text-blue-600 dark:text-blue-400">
        Go to Login
      </Link>
    </div>
  );
};

export default EmailVerification;
