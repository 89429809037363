import React, { useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { ShoppingCartIcon, XMarkIcon, LinkIcon, StarIcon, ArrowDownTrayIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useUser } from '../../contexts/UserContext'; // Import the UserContext hook
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate and useLocation hooks for redirection and URL access
import { db } from '../../config/config'; // Import Firestore database instance
import { collection, addDoc, query, where, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { toast } from 'react-toastify'; // Import the toast function

const ProductCard = ({ product }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [isInCart, setIsInCart] = useState(false); // New state for cart status
    const { user } = useUser(); // Use the UserContext to get the current user
    const navigate = useNavigate(); // Hook for navigation
    const location = useLocation(); // Hook for accessing the current URL

    useEffect(() => {
        if (user) {
            checkIfInCart();
        }
    }, [user, product.id]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const productIdFromURL = queryParams.get('productId');
        if (productIdFromURL === product.id) {
            openModal();
        }
    }, [location.search, product.id]);


    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setSuccessModalOpen(false); // Close success modal if open
    };

    const openSuccessModal = () => {
        setSuccessModalOpen(true);
    };

    const copyLinkToClipboard = () => {
        const link = `${window.location.origin}?productId=${product.id}`;
        navigator.clipboard.writeText(link)
            .then(() => toast.success('Product link copied to clipboard!'))
            .catch(err => toast.error('Failed to copy link:', err));
    };

    const getStarRating = (rating) => {
        const stars = [];
        const fullStars = Math.floor(rating);
        const hasHalfStar = rating % 1 >= 0.5;
        const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

        for (let i = 0; i < fullStars; i++) {
            stars.push('full');
        }
        if (hasHalfStar) {
            stars.push('half');
        }
        for (let i = 0; i < emptyStars; i++) {
            stars.push('empty');
        }

        return stars;
    };

    const starRating = getStarRating(product.rating);



    const handleAction = () => {
        if (!user) {
            // Redirect to login page if user is not logged in
            toast.error('Please "login" to buy the Product.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            // navigate('/Authentication');
            return;
        }

        // Convert price to string and handle it accordingly
        const priceString = String(product.price).toLowerCase();

        if (priceString === "free" || isNaN(parseFloat(product.price))) {
            // Handle direct download for free products
            downloadFile();
        } else {
            // Proceed with payment gateway for paid products
            handlePayment();
        }
    };

    const handlePayment = () => {
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Your Razorpay key id
            amount: parseFloat(product.price) * 100, // Amount in paise
            currency: 'INR',
            name: 'Your Company Name',
            description: 'Purchase Description',
            image: product.image, // Logo or image to display in Razorpay form
            handler: async function (response) {
                try {
                    // Handle successful payment here
                    await savePaymentDetails(user.email, product.id, response.razorpay_payment_id);

                    // Open success modal
                    openSuccessModal();
                    toast.success('Payment SuccessFully');
                } catch (error) {
                    toast.error('Error handling payment:', error);
                }
            },
            prefill: {
                name: user.name || '',
                email: user.email || '',
                contact: user.contact || ''
            },
            theme: {
                color: '#3399cc'
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    };

    const downloadFile = () => {
        if (product.link) {
            const link = document.createElement('a');
            link.href = product.link;
            link.target = '_blank';
            link.download = ''; // This attribute might trigger download behavior
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            // console.warn('No link provided for this product.');
        }
    };

    const savePaymentDetails = async (userEmail, productId, paymentId, number = null) => {
        try {
            // Reference to the 'payments' collection
            const paymentsRef = collection(db, 'payments');

            // Add a new document to the 'payments' collection
            await addDoc(paymentsRef, {
                userEmail,
                productId,
                paymentId,
                number,
                status: 'Completed',
                timestamp: new Date()
            });

            toast.success('Payment details saved to Firestore.');
        } catch (error) {
            toast.error('Error saving payment details to Firestore:', error);
        }
    };

    const checkIfInCart = async () => {
        try {
            const cartRef = collection(db, 'Cart');
            const q = query(cartRef, where('userEmail', '==', user.email), where('productId', '==', product.id));
            const querySnapshot = await getDocs(q);
            setIsInCart(!querySnapshot.empty);
        } catch (error) {
            toast.error('Error checking cart status:', error);
        }
    };

    const addToCart = async (productId) => {
        try {
            // Reference to the 'Cart' collection
            const cartRef = collection(db, 'Cart');

            // Add a new document to the 'Cart' collection
            await addDoc(cartRef, {
                userEmail: user.email,
                productId: productId
            });

            toast.success('Cart details saved to Firestore.');
            setIsInCart(true); // Update state to reflect cart status
        } catch (error) {
            toast.error('Error saving Cart details to Firestore:', error);
        }
    };

    const removeFromCart = async (productId) => {
        try {
            const cartRef = collection(db, 'Cart');
            const q = query(cartRef, where('userEmail', '==', user.email), where('productId', '==', productId));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });

            toast.info('Cart item removed from Firestore.');
            setIsInCart(false); // Update state to reflect cart status
        } catch (error) {
            toast.error('Error removing Cart details from Firestore:', error);
        }
    };

    const handleCartButtonClick = async (event) => {
        event.stopPropagation();  // Prevents the click event from propagating to parent elements

        if (!user) {
            // Redirect to login page if user is not logged in
            toast.error('Please "login" to add items to your cart.');
            // navigate('/Authentication');
            return;
        }

        try {
            if (isInCart) {
                await removeFromCart(product.id);
            } else {
                await addToCart(product.id);
            }
        } catch (error) {
            toast.error('Error processing cart action:', error);
        }
    };






    return (
        <div className="p-4 sm:p-6 bg-white dark:bg-gray-800  shadow-lg border border-gray-200 dark:border-gray-700 transition-transform transform hover:scale-105 ease-in-out duration-300 w-full ">
            <div
                className="relative cursor-pointer"
                onClick={openModal}
            >
                <img
                    src={product.image}
                    alt={product.title}
                    className="w-full h-64 object-contain rounded-md shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300 bg-black"
                    loading="lazy"
                />

                <div className="flex flex-col items-start mt-4">
                    <h1 className="text-xl sm:text-2xl font-extrabold text-gray-900 dark:text-gray-100 mb-2">{product.title}</h1>
                    <p className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4">Price - {product.price === "Free" ? "Free" : `₹${product.price}`}</p>

                    {/* Conditionally render the Add to Cart button based on the product price */}
                    {product.price !== "Free" && (
                        <button
                            onClick={handleCartButtonClick}
                            className={`bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center space-x-2 shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300 ${isInCart ? 'bg-green-600 hover:bg-green-700' : ''}`}
                        >
                            <ShoppingCartIcon className="h-5 w-5" />
                            <span>{isInCart ? 'Remove From Cart' : 'Add to Cart'}</span>
                        </button>
                    )}
                </div>
            </div>


            {/* Product Modal */}
            <Dialog open={isOpen} onClose={closeModal} className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50 transition-opacity duration-300">
                <div style={{ flexDirection: 'column' }} className="relative flex items-center justify-center bg-white dark:bg-gray-800 rounded-sm shadow-lg w-full lg:w-4/5 min-h-[90vh] overflow-auto">
                    <div className="absolute top-4 right-4 flex flex-col gap-2">
                        {/* Close Button */}
                        <button
                            onClick={closeModal}
                            aria-label="Close"
                            className="text-gray-500 dark:text-gray-400 hover:text-gray-700 z-50 dark:hover:text-gray-300 transition-colors duration-200 flex items-center justify-center p-2 rounded-full bg-gray-800 dark:bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700"
                        >
                            <XMarkIcon className="h-6 w-6 text-white dark:text-gray-800" />
                        </button>

                        {/* Copy Link Button */}
                        <button onClick={copyLinkToClipboard} aria-label="Copy Link" className="text-gray-500 z-50 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition-colors duration-200 flex items-center justify-center p-2 rounded-full bg-gray-800 dark:bg-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700">
                            <LinkIcon className="h-6 w-6 text-white dark:text-gray-800 " />
                        </button>
                    </div>
                    <div className="relative bg-gray-100 dark:bg-gray-700  rounded-lg   w-full max-w-5xl">
                        <div className="absolute top-4 right-4 flex flex-col gap-2">


                            {/* Close Button - Hidden on screens smaller than 900px */}
                            <button
                                onClick={closeModal}
                                aria-label="Close"
                                className="md:hidden text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition-colors duration-200 items-center justify-center p-2 rounded-full bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>

                            {/* Copy Link Button - Hidden on screens larger than 900px */}
                            <button
                                onClick={copyLinkToClipboard}
                                aria-label="Copy Link"
                                className="md:hidden text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 transition-colors duration-200 items-center justify-center p-2 rounded-full bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-700"
                            >
                                <LinkIcon className="h-6 w-6" />
                            </button>


                        </div>
                        <div className="p-4 sm:p-6 flex flex-col sm:flex-row items-center overflow-y-auto max-h-[calc(100vh-2rem)]">
                            <img
                                src={product.image}
                                alt={product.title}
                                className="w-full lg:w-1/2 h-auto object-contain rounded-lg shadow-md"
                            />
                            <div className="sm:ml-6 flex flex-col justify-between flex-1">
                                <h1 className="text-2xl font-extrabold text-gray-900 dark:text-gray-100 mb-2">{product.title}</h1>
                                <p className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">Price - {product.price === "Free" ? "Free" : `₹${product.price}`}</p>
                                <p className="text-gray-600 dark:text-gray-300 mb-4">{product.description}</p>
                                <div className="flex items-center space-x-1 mb-4">
                                    {starRating.map((star, index) => (
                                        <StarIcon
                                            key={index}
                                            className={`h-5 w-5 ${star === 'full'
                                                ? 'text-yellow-500'
                                                : star === 'half'
                                                    ? 'text-yellow-400'
                                                    : 'text-gray-300'
                                                }`}
                                            style={{ width: '1.25rem', height: '1.25rem' }}
                                        />
                                    ))}
                                </div>

                                <div className="flex space-x-4">
                                    <button
                                        onClick={handleAction}
                                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center space-x-2 shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300"
                                    >
                                        <ArrowDownTrayIcon className="h-5 w-5" />
                                        <span>{product.price === "Free" ? 'Download' : 'Buy Now'}</span>
                                    </button>
                                    {/* Conditionally render the Add to Cart button based on the product price */}
                                    {product.price !== "Free" && (
                                        <button
                                            onClick={handleCartButtonClick}
                                            className={`bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg flex items-center space-x-2 shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300 ${isInCart ? 'bg-green-600 hover:bg-green-700' : ''}`}
                                        >
                                            <ShoppingCartIcon className="h-5 w-5" />
                                            <span>{isInCart ? 'Remove in Cart' : 'Add to Cart'}</span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>


            {/* Success Modal */}
            <Dialog open={successModalOpen} onClose={closeModal} className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50 transition-opacity duration-300">
                <div
                    onClick={closeModal}
                    className="fixed inset-0"
                />
                <Dialog.Panel
                    className="relative bg-white dark:bg-gray-900 p-6 rounded-lg shadow-xl flex flex-col items-center space-y-6 md:space-y-8 w-full max-w-lg md:max-w-2xl h-auto max-h-[90vh] overflow-auto"
                    onClick={(e) => e.stopPropagation()}
                >
                    <button
                        onClick={closeModal}
                        className="absolute top-4 right-4 p-2 bg-gray-200 dark:bg-gray-800 rounded-full hover:bg-gray-300 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        aria-label="Close"
                    >
                        <XMarkIcon className="h-6 w-6 text-gray-900 dark:text-gray-100" />
                    </button>
                    <CheckCircleIcon className="h-16 w-16 text-green-500 mb-4" />
                    <h3 className="text-2xl md:text-3xl font-bold text-gray-900 dark:text-gray-100 text-center">Payment Successful</h3>
                    <p className="text-lg md:text-xl text-gray-700 dark:text-gray-400 text-center">Thank you for your purchase!</p>

                    <button
                        onClick={downloadFile}
                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg flex items-center space-x-2 shadow-md transition-transform transform hover:scale-105 ease-in-out duration-300"
                    >
                        <ArrowDownTrayIcon className="h-6 w-6" />
                        <span className="text-lg">Download File</span>
                    </button>

                    <p className="text-md md:text-md text-gray-700 dark:text-gray-400 text-center"><strong>Note:</strong> Your purchased products will be available on your profile <strong>My Products</strong>. You can also download your product from there.</p>

                </Dialog.Panel>
            </Dialog>

        </div>
    );
};

export default ProductCard;