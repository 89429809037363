import React, { useEffect, useState } from 'react';
import {
    BookOpenIcon,
    CodeBracketIcon,
    ShoppingBagIcon,
    CalendarIcon,
} from '@heroicons/react/24/outline';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import { collection, getDocs } from "../../config/config";
import { db } from "../../config/config";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const EbookOverview = () => {
    const [courseOverview, setCourseOverview] = useState({});
    const [enrollmentStats, setEnrollmentStats] = useState({ labels: [], data: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch course overview data
                const courseSnapshot = await getDocs(collection(db, "courseOverview"));
                const courseData = courseSnapshot.docs.map(doc => doc.data())[0];
                setCourseOverview(courseData);

                // Fetch enrollment statistics data
                const statsSnapshot = await getDocs(collection(db, "enrollmentStatistics"));
                const statsData = statsSnapshot.docs.map(doc => doc.data())[0];
                console.log('Fetched Enrollment Stats:', statsData); // Debugging line
                if (statsData) {
                    setEnrollmentStats({
                        labels: statsData.labels || [],
                        data: statsData.data || [],
                    });
                }
            } catch (error) {
                setError('Failed to fetch data');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const data = {
        labels: enrollmentStats.labels,
        datasets: [
            {
                label: 'Users Enrolled',
                data: enrollmentStats.data,
                backgroundColor: 'rgba(99, 102, 241, 0.6)',
                borderColor: 'rgba(99, 102, 241, 1)',
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'User Enrollment Over Time',
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                grid: {
                    borderColor: 'rgba(0, 0, 0, 0.1)',
                },
                ticks: {
                    stepSize: 20,
                },
            },
        },
    };

    if (loading) {
        return 
        // <p>Loading...</p>;
    }

    if (error) {
        return 
        // <p>{error}</p>;
    }

    return (
        <div className="mt-12 flex flex-col md:flex-row md:justify-between gap-8">
            <div className="flex-1 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold dark:text-white">Course Overview</h3>
                <div className="mt-4 space-y-4">
                    <div className="flex items-start">
                        <BookOpenIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
                        <div className="ml-2 md:ml-3">
                            <p className="font-semibold dark:text-white">Language:</p>
                            <p className="text-gray-600 dark:text-gray-400">{courseOverview.language}</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <CalendarIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
                        <div className="ml-2 md:ml-3">
                            <p className="font-semibold dark:text-white">Duration:</p>
                            <p className="text-gray-600 dark:text-gray-400">{courseOverview.duration}</p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <CodeBracketIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
                        <div className="ml-2 md:ml-3">
                            <p className="font-semibold dark:text-white">Topics Covered:</p>
                            <ul className="list-disc pl-4 text-gray-600 dark:text-gray-400">
                                {courseOverview.topicsCovered && courseOverview.topicsCovered.map((topic, index) => (
                                    <li key={index}>{topic}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <ShoppingBagIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
                        <div className="ml-2 md:ml-3">
                            <p className="font-semibold dark:text-white">Price:</p>
                            <p className="text-gray-600 dark:text-gray-400">{courseOverview.price}</p>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="mt-4 w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600"
                    >
                        Enroll Now
                    </button>
                </div>
            </div>
            <div className="flex-1 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-6 md:p-8 rounded-lg shadow-lg">
                <h3 className="text-xl md:text-2xl font-semibold dark:text-white">Enrollment Statistics</h3>
                <div className="mt-4">
                    <Bar data={data} options={options} className='dark:text-white' />
                </div>
            </div>
        </div>
    );
};

export default EbookOverview;



// import React from 'react';
// import {
//     BookOpenIcon,
//     CodeBracketIcon,
//     ShoppingBagIcon,
//     CalendarIcon,
// } from '@heroicons/react/24/outline';
// import { Bar } from 'react-chartjs-2';
// import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, Colors } from 'chart.js';

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

// const data = {
//     labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//     datasets: [
//         {
//             label: 'Users Enrolled',
//             data: [150, 70, 200, 120, 70, 180, 250],
//             backgroundColor: 'rgba(99, 102, 241, 0.6)',
//             borderColor: 'rgba(99, 102, 241, 1)',
//             borderWidth: 1,
//         },
//     ],
// };

// const options = {
//     responsive: true,
//     plugins: {
//         legend: {
//             position: 'top',
//         },
//         title: {
//             display: true,
//             text: 'User Enrollment Over Time',
//         },
//     },
//     scales: {
//         x: {
//             grid: {
//                 display: false,
//             },
//         },
//         y: {
//             grid: {
//                 borderColor: 'rgba(0, 0, 0, 0.1)',
//             },
//             ticks: {
//                 stepSize: 20,
//             },
//         },
//     },
// };

// const EbookOverview = () => {
//     return (
//         // <div className="flex flex-col md:flex-row md:justify-between gap-8 p-4 md:p-8">
//         <div className="mt-12 flex flex-col md:flex-row md:justify-between gap-8">
//             <div className="flex-1 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-6 md:p-8 rounded-lg shadow-lg">
//                 <h3 className="text-xl md:text-2xl font-semibold dark:text-white">Course Overview</h3>
//                 <div className="mt-4 space-y-4">
//                     <div className="flex items-start">
//                         <BookOpenIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
//                         <div className="ml-2 md:ml-3">
//                             <p className="font-semibold dark:text-white">Language:</p>
//                             <p className="text-gray-600 dark:text-gray-400">English</p>
//                         </div>
//                     </div>
//                     <div className="flex items-start">
//                         <CalendarIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
//                         <div className="ml-2 md:ml-3">
//                             <p className="font-semibold dark:text-white">Duration:</p>
//                             <p className="text-gray-600 dark:text-gray-400">3 Months (Self-paced)</p>
//                         </div>
//                     </div>
//                     <div className="flex items-start">
//                         <CodeBracketIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
//                         <div className="ml-2 md:ml-3">
//                             <p className="font-semibold dark:text-white">Topics Covered:</p>
//                             <ul className="list-disc pl-4 text-gray-600 dark:text-gray-400">
//                                 <li>HTML & CSS Basics</li>
//                                 <li>JavaScript Fundamentals</li>
//                                 <li>Advanced ReactJS</li>
//                                 <li>Components Based-Project</li>
//                             </ul>
//                         </div>
//                     </div>
//                     <div className="flex items-start">
//                         <ShoppingBagIcon className="h-5 w-5 md:h-6 md:w-6 text-indigo-500" />
//                         <div className="ml-2 md:ml-3">
//                             <p className="font-semibold dark:text-white">Price:</p>
//                             <p className="text-gray-600 dark:text-gray-400">$299</p>
//                         </div>
//                     </div>
//                     <button
//                         type="button"
//                         className="mt-4 w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600"
//                     >
//                         Enroll Now
//                     </button>
//                 </div>
//             </div>
//             <div className="flex-1 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-6 md:p-8 rounded-lg shadow-lg">
//                 <h3 className="text-xl md:text-2xl font-semibold dark:text-white">Enrollment Statistics</h3>
//                 <div className="mt-4">
//                     <Bar data={data} options={options} className='dark:text-white' />
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default EbookOverview;
