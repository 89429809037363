import CodeEditor from '../Components/CodeEditor/CodeEditor';
import { CodeBracketIcon, TagIcon, DocumentTextIcon, ExclamationCircleIcon, CheckBadgeIcon } from '@heroicons/react/24/outline';
import ParticlesBackground from '../Components/HomeMain/ParticlesBackground';

const Editor = () => {
  return (
    <div className='bg-gray-100 dark:bg-gray-900 min-h-screen xl:p-8'>
      {/* Main Code Editor Component */}
      <ParticlesBackground />
      <CodeEditor />

      {/* Note Section */}
      <div className='p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg mt-20 mx-4 xl:mx-8'>
        <h2 className='text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4 flex items-center'>
          <DocumentTextIcon className='h-6 w-6 text-indigo-600 dark:text-indigo-400 mr-2' />
          Important Notes
        </h2>
        <p className='text-gray-700 dark:text-gray-300 mb-4'>
          This code editor supports the following languages and features:
        </p>
        <ul className='space-y-4'>
          <li className='flex items-start'>
            <CodeBracketIcon className='h-5 w-5 text-green-600 dark:text-green-400 mr-2' />
            <span className='text-gray-700 dark:text-gray-300'>
              <strong>HTML:</strong> Structure and content of web pages.
            </span>
          </li>
          <li className='flex items-start'>
            <TagIcon className='h-5 w-5 text-blue-600 dark:text-blue-400 mr-2' />
            <span className='text-gray-700 dark:text-gray-300'>
              <strong>CSS:</strong> Styling and layout of web pages.
            </span>
          </li>
          <li className='flex items-start'>
            <CodeBracketIcon className='h-5 w-5 text-yellow-600 dark:text-yellow-400 mr-2' />
            <span className='text-gray-700 dark:text-gray-300'>
              <strong>JavaScript:</strong> Interactivity and dynamic content.
            </span>
          </li>
          <li className='flex items-start'>
            <ExclamationCircleIcon className='h-5 w-5 text-red-600 dark:text-red-400 mr-2' />
            <span className='text-gray-700 dark:text-gray-300'>
              <strong>Syntax Highlighting:</strong> Colors and highlights to distinguish different code elements.
            </span>
          </li>
          <li className='flex items-start'>
            <CheckBadgeIcon className='h-5 w-5 text-green-600 dark:text-green-400 mr-2' />
            <span className='text-gray-700 dark:text-gray-300'>
              <strong>Code Validation:</strong> Basic validation for syntax errors.
            </span>
          </li>
        </ul>
        <p className='text-gray-700 dark:text-gray-300 mt-4'>
          Ensure that your code adheres to the syntax and best practices for the supported languages to maximize the effectiveness and performance of the editor.
        </p>
      </div>

      <div className='h-10'></div>
    </div>
  );
};

export default Editor;
