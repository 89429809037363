import React, { useState, useEffect, useRef } from 'react';
import { Editor as MonacoEditor } from '@monaco-editor/react';
import { CodeBracketIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { MdDelete } from 'react-icons/md';

const Editor = () => {
  const [value, setValue] = useState('<h1>Heading</h1><p>Paragraph</p>');
  const [isCodeOpen, setIsCodeOpen] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);
  const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const iframeRef = useRef(null);

  const toggleCode = () => {
    setIsCodeOpen(!isCodeOpen);
  };

  const resetEditor = () => {
    setValue('');
  };

  useEffect(() => {
    const updateIframe = () => {
      if (iframeRef.current) {
        iframeRef.current.contentDocument.open();
        iframeRef.current.contentDocument.write(value);
        iframeRef.current.contentDocument.close();
        setDimensions({
          width: iframeRef.current.contentWindow.innerWidth,
          height: iframeRef.current.contentWindow.innerHeight,
        });
      }
    };

    updateIframe();
    window.addEventListener('resize', updateIframe);

    return () => {
      window.removeEventListener('resize', updateIframe);
    };
  }, [value]);

  return (
    <div className="relative top-10 mt-8 bg-white dark:bg-gray-800 p-4 md:p-6 lg:p-8 rounded-lg shadow-lg mb-4 mx-4 xl:mx-20 transition-transform duration-300 transform">
      <div className="flex flex-col md:flex-row items-start justify-between mb-4">
        <div className="flex items-center space-x-3 mb-4 md:mb-0">
          <CodeBracketIcon className="h-8 w-8 md:h-10 md:w-10 text-indigo-600 dark:text-indigo-400" />
          <span className="text-lg md:text-2xl font-bold text-gray-900 dark:text-gray-100">
            Code Editor
          </span>
        </div>
        <div className="flex items-center space-x-4">
          <button
            onClick={resetEditor}
            className="text-red-600 dark:text-red-400 hover:text-red-700 dark:hover:text-red-500 transition-colors duration-200"
          >
            <MdDelete className="h-8 w-8 md:h-10 md:w-10" />
          </button>
          <button
            onClick={toggleCode}
            className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-700 dark:hover:text-indigo-500 transition-colors duration-200"
          >
            {isCodeOpen ? (
              <ChevronUpIcon className="h-8 w-8 md:h-10 md:w-10" />
            ) : (
              <ChevronDownIcon className="h-8 w-8 md:h-10 md:w-10" />
            )}
          </button>
        </div>
      </div>

      {isCodeOpen && (
        <div className="flex flex-col md:flex-row gap-4">
          <div className={`flex-1 ${isPreviewOpen ? 'md:pr-4' : ''} ${isPreviewOpen ? 'md:w-1/2' : 'md:w-full'}`}>
            <div className="border border-gray-300 dark:border-gray-600 rounded-lg overflow-hidden shadow-lg bg-gray-50 dark:bg-gray-900 h-full">
              <MonacoEditor
                height="calc(100vh - 300px)" // Adjust as needed
                language="html"
                value={value}
                onChange={(newValue) => setValue(newValue || '')}
                options={{
                  minimap: { enabled: false },
                  wordWrap: 'on',
                  scrollBeyondLastLine: false,
                  fontSize: 14,
                }}
                className="w-full"
              />
            </div>
          </div>

          {isPreviewOpen && (
            <div className="flex-1 bg-gray-50 dark:bg-gray-900 border border-gray-300 dark:border-gray-600 rounded-lg p-4 shadow-lg overflow-hidden">
              <div className="border-b border-gray-300 dark:border-gray-600 mb-4 pb-2">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                    Preview:
                  </h3>
                  <div className="text-sm text-gray-700 dark:text-gray-300">
                    <span className="font-semibold">
                      Width: {dimensions.width}px x Height: {dimensions.height}px
                    </span>
                  </div>
                </div>
              </div>
              <iframe
                ref={iframeRef}
                title="Preview"
                className="w-full h-full border-none rounded-lg"
                style={{ height: 'calc(100vh - 300px)' }} // Adjust as needed
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Editor;
