import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaSearch } from 'react-icons/fa';

const NotFound = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 text-center p-4">
            <h1 className="text-6xl font-bold text-red-600 mb-4">404</h1>
            <h2 className="text-3xl font-semibold text-gray-800 mb-2">Page Not Found</h2>
            <p className="text-lg text-gray-600 mb-8">Oops! The page you are looking for does not exist.</p>

            <div className="flex flex-col items-center mb-6">
                <Link to="/" className="flex items-center bg-blue-600 text-white px-6 py-3 rounded-lg shadow-lg hover:bg-blue-700 transition">
                    <FaHome className="mr-2" /> Go Back Home
                </Link>
                <p className="mt-4 text-gray-500">or</p>
                <div className="relative mt-4 w-full max-w-sm">
                    <input
                        type="text"
                        className="w-full px-4 py-2 border rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-600"
                        placeholder="Search our site..."
                    />
                    <button className="absolute right-2 top-2 bg-blue-600 text-white px-4 py-2 rounded-lg shadow hover:bg-blue-700 transition">
                        <FaSearch />
                    </button>
                </div>
            </div>

            <div className="mt-8 text-gray-600">
                <h3 className="text-xl font-semibold mb-2">Here are some useful links:</h3>
                <ul className="flex flex-wrap justify-center space-x-4">
                    <li><Link to="/about" className="text-blue-600 hover:text-blue-800">About Us</Link></li>
                    <li><Link to="/contact" className="text-blue-600 hover:text-blue-800">Contact</Link></li>
                    <li><Link to="/services" className="text-blue-600 hover:text-blue-800">Services</Link></li>
                    <li><Link to="/blog" className="text-blue-600 hover:text-blue-800">Blog</Link></li>
                </ul>
            </div>
        </div>
    );
};

export default NotFound;
