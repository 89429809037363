import React, { useState, useEffect } from 'react';
import {
    StarIcon,
    BookOpenIcon,
    CodeBracketIcon,
    UserIcon,
    GlobeAltIcon,
    LightBulbIcon,
    ChatBubbleBottomCenterIcon,
    ClockIcon,

} from '@heroicons/react/24/outline';

const AdditionalResourses = () => {
    return (
        <div className="mt-12 grid grid-cols-1 gap-8 lg:grid-cols-3">
            <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-8 rounded-lg shadow-2xl">
                <h3 className="text-xl font-semibold dark:text-white">Additional Resources</h3>
                <div className="mt-4 space-y-4">
                    <div className="flex items-start">
                        <LightBulbIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3">
                            <p className="font-semibold dark:text-white">Exclusive E-books</p>
                            <p className="text-gray-600 dark:text-gray-400">
                                Access a collection of exclusive e-books to deepen your knowledge.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <ChatBubbleBottomCenterIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3">
                            <p className="font-semibold dark:text-white">Community Support</p>
                            <p className="text-gray-600 dark:text-gray-400">
                                Join a vibrant community of learners and instructors to exchange ideas and get support.
                            </p>
                        </div>
                    </div>
                    <div className="flex items-start">
                        <GlobeAltIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3">
                            <p className="font-semibold dark:text-white">Global Access</p>
                            <p className="text-gray-600 dark:text-gray-400">
                                Learn from anywhere in the world with our fully online and accessible course.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Course Highlights Section */}
            <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-8 rounded-lg shadow-2xl">
                <p className="font-semibold dark:text-white">Global Access</p>
                <h3 className="text-xl font-semibold  dark:text-white">Course Highlights</h3>
                <div className="mt-4 space-y-4">
                    <div className="flex items-start">
                        <CodeBracketIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3">
                            <p className="font-semibold dark:text-white">Hands-On Projects</p>
                            <p className="text-gray-600 dark:text-gray-400">
                                Work on hands-on projects that reflect real-world scenarios and challenges.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start">
                        <BookOpenIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3 flex-1">
                            <p className="font-semibold dark:text-white">Interactive Content</p>
                            <p className="text-gray-600 dark:text-gray-400 inline">
                                Engage with interactive exercises and real-world projects to solidify your understanding.
                            </p>
                            <button className="ml-3 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-700 rounded-full border border-indigo-600 shadow-md hover:shadow-xl hover:bg-gradient-to-r hover:from-indigo-600 hover:via-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center">
                                <span className="mr-2">
                                    <ClockIcon className="h-5 w-5 text-white" />
                                </span>
                                Coming Soon
                            </button>
                        </div>
                    </div>


                    <div className="flex items-start">
                        <UserIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3">
                            <p className="font-semibold dark:text-white">Expert Instructors</p>
                            <p className="text-gray-600 dark:text-gray-400">
                                Learn from industry professionals with years of experience in web development.
                            </p>
                            <button className="ml-3 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-700 rounded-full border border-indigo-600 shadow-md hover:shadow-xl hover:bg-gradient-to-r hover:from-indigo-600 hover:via-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center">
                                <span className="mr-2">
                                    <ClockIcon className="h-5 w-5 text-white" />
                                </span>
                                Coming Soon
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Certification & Support Section */}
            <div className="bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-8 rounded-lg shadow-2xl">
                <h3 className="text-xl font-semibold dark:text-white">Certification & Support</h3>
                <div className="mt-4 space-y-4">

                    <div className="flex items-start">
                        <GlobeAltIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3">
                            <p className="font-semibold dark:text-white">Global Network</p>
                            <p className="text-gray-600 dark:text-gray-400">
                                Connect with a global network of professionals and peers for ongoing support and opportunities.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-start">
                        <StarIcon className="h-6 w-6 text-indigo-500" />
                        <div className="ml-3">
                            <p className="font-semibold dark:text-white">Certification</p>
                            <p className="text-gray-600 dark:text-gray-400">
                                Receive a certificate upon completion that validates your skills and knowledge.
                            </p>
                            <button className="ml-3 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-700 rounded-full border border-indigo-600 shadow-md hover:shadow-xl hover:bg-gradient-to-r hover:from-indigo-600 hover:via-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center">
                                <span className="mr-2">
                                    <ClockIcon className="h-5 w-5 text-white" />
                                </span>
                                Coming Soon
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalResourses