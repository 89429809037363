import React from 'react'

const EbookContactForm = () => {
    return (
        <div className="mt-12 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold dark:text-white">Contact Us</h3>
            <form className="mt-4 space-y-4">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-900 dark:text-gray-100">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                        placeholder="Your Name"
                    />
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-900 dark:text-gray-100">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                        placeholder="Your Email"
                    />
                </div>
                <div>
                    <label htmlFor="message" className="block text-sm font-medium text-gray-900 dark:text-gray-100">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-gray-100"
                        placeholder="Your Message"
                    />
                </div>
                <button
                    type="submit"
                    className="mt-4 w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600"
                >
                    Send Message
                </button>
            </form>
        </div>
        )
}

export default EbookContactForm