import React, { useState } from 'react'; // Import React and useState
import { ChevronLeftIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'; // Import necessary icons
import { HomeIcon, BookOpenIcon, UserIcon, CogIcon } from '@heroicons/react/24/solid'; // Import necessary icons

const Sidebar = ({ topics = [], currentTopicIndex = 0, currentLessonIndex = 0, onTopicChange, onLessonChange }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleToggle = () => setIsCollapsed(!isCollapsed);

    return (
        <>
            <div
                className={`fixed top-0 right-0 h-full bg-white dark:bg-gray-900 text-gray-800 dark:text-gray-200 shadow-lg transition-transform duration-300 ease-in-out ${isCollapsed ? 'translate-x-full' : 'translate-x-0'} ${isCollapsed ? 'w-16' : 'w-80'} z-50`}
            >
                <div className="flex flex-col h-full">
                    <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800">
                        <div className="flex items-center space-x-2">
                            {!isCollapsed && (
                                <HomeIcon className="w-6 h-6 text-gray-800 dark:text-gray-200" />
                            )}
                            <h2 className={`text-lg font-semibold ${isCollapsed ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}>
                                Topics & Lessons
                            </h2>
                        </div>
                        <button
                            onClick={handleToggle}
                            className=" p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100 transition-colors duration-200"
                            aria-label={isCollapsed ? 'Open Sidebar' : 'Close Sidebar'}
                        >
                            {isCollapsed ? (
                                <Bars3Icon className="w-6 h-6 " />
                            ) : (
                                <XMarkIcon className="w-6 h-6" />
                            )}
                        </button>
                    </div>
                    <div className="flex-1 overflow-y-auto p-4">
                        {!isCollapsed && (
                            <div>
                                {topics.map((topic, topicIndex) => (
                                    <div key={topicIndex} className="mb-6">
                                        <h3
                                            className={`text-lg font-medium cursor-pointer ${topicIndex === currentTopicIndex ? 'text-blue-600' : 'text-gray-700'} hover:text-blue-700 dark:text-gray-300 dark:hover:text-blue-400 transition-colors duration-200`}
                                            onClick={() => onTopicChange(topicIndex)}
                                        >
                                            <BookOpenIcon className="inline-block w-5 h-5 mr-2" />
                                            {topic.title}
                                        </h3>
                                        {topicIndex === currentTopicIndex && (
                                            <ul className="mt-2 space-y-2">
                                                {topic.lessons.map((lesson, lessonIndex) => (
                                                    <li
                                                        key={lessonIndex}
                                                        className={`p-2 cursor-pointer ${lessonIndex === currentLessonIndex ? 'bg-blue-600 text-white' : 'text-gray-700 hover:bg-gray-200 dark:text-gray-300 dark:hover:bg-gray-700'} rounded-lg transition-colors duration-200`}
                                                        onClick={() => onLessonChange(lessonIndex)}
                                                    >
                                                        {lesson.title}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className="flex justify-center items-center border-t border-gray-200 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 p-4">
                        <button
                            className="flex items-center p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100 transition-colors duration-200"
                            aria-label="Settings"
                        >
                            <CogIcon className="w-6 h-6" />
                        </button>
                        <button
                            className="ml-4 flex items-center p-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100 transition-colors duration-200"
                            aria-label="Profile"
                        >
                            <UserIcon className="w-6 h-6" />
                        </button>
                    </div>
                </div>
            </div>

            {isCollapsed && (
                <div className="fixed top-18 xl:top-28 right-4 transition-opacity duration-300 ease-in-out" style={{ zIndex: '999' }}>
                    <button
                        onClick={handleToggle}
                        className=" Tutorial-Menuopen-Icon p-2 bg-gray-800 rounded-full text-gray-300 hover:bg-gray-700 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700 transition-colors duration-200"
                        aria-label="Open Sidebar"
                    >
                        <Bars3Icon className="w-6 h-6 " />
                    </button>
                </div>
            )}
        </>
    );
};

export default Sidebar;
