import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { SunIcon } from '@heroicons/react/24/solid';
import { PiMoonStarsBold } from 'react-icons/pi';
import { FaShoppingCart, FaCode } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../config/config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserPopover from '../UserPopover/UserPopover';
import useCartCount from '../../hooks/useCartCount';
import MainLogo from '../Assets/MuhilanOrgLogo.svg';
import LightLogo from '../Assets/MuhilanOrgLogoDark.svg'; // Light theme logo
import DarkLogo from '../Assets/MuhilanOrgLogo.svg'; // Dark theme logo
import '../../../src/App.css';
import './Header.css';


const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(() => {
        // Load the theme preference from localStorage, default to dark mode
        return localStorage.getItem('theme') === 'light' ? false : true;
    });
    const [user, setUser] = useState(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const popoverRef = useRef(null);
    const [activeLink, setActiveLink] = useState('/');
    const navigate = useNavigate();
    const location = useLocation(); // Hook to get the current location
    const cartCount = useCartCount();

    // Effect to apply dark mode class
    useEffect(() => {

        // Scroll to top when the component is mounted
        // window.scrollTo(0, 0);
        document.documentElement.classList.toggle('dark', darkMode);
        // Save the theme preference to localStorage
        localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    }, [darkMode]);

    // Effect to handle authentication state
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                if (!user.emailVerified) {
                    navigate('/verify-email');
                    signOut(auth);
                } else {
                    setUser(user);
                }
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    useEffect(() => {
        setActiveLink(location.pathname); // Update active link based on the current location
    }, [location]);

    // Handle logout
    const handleLogout = async () => {
        try {
            setPopoverOpen(false);
            setMobileMenuOpen(false);
            await signOut(auth);
            toast.success("Successfully logged out!");
            setUser(null);
            navigate('/');
        } catch (error) {
            console.error("Error logging out:", error);
            toast.error("Error logging out. Please try again.");
        }
    };

    // Handle click outside of popover to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setPopoverOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Toggle mobile menu
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };


    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };


    // Toggle dark mode on click and prevent page scroll
    const handleIconClick = (event) => {
        event.preventDefault(); // Prevent default behavior
        event.stopPropagation(); // Stop event from bubbling up
        setDarkMode(!darkMode); // Toggle dark mode
    };





    const navigation = [
        { name: 'Home', href: '/' },
        // { name: 'Component', href: '/Component' },
        { name: 'Ebook', href: '/Ebook' },
        { name: 'Shop', href: '/Shop' },
        { name: 'Learn', href: '/Learn' },
        // { name: 'About', href: '/About' },
    ];
    return (
        <div className={`App ${darkMode ? 'dark' : ''} flex item-center justify-center`}>
            <header className=" z-50 bg-transparent dark:bg-transparent">
                {/* <header className="fixed inset-x-0 top-0 z-50 bg-transparent dark:bg-transparent"> */}
                {/* <header className="fixed inset-x-0 top-0 z-50 bg-transparent dark:bg-transparent"> */}
                <nav aria-label="Global" className="flex items-center justify-between  p-6 lg:px-8">
                    <div id='left-section' className="flex items-center space-x-4 flex-1">
                        <Link to="/" className="-m-5.5 p-1.5">
                            {/* Conditional rendering of logos based on darkMode */}
                            <img
                                src={darkMode ? DarkLogo : LightLogo}
                                id='logo-img'
                                className="w-22 md:w-20 lg:w-22 xl:w-22"
                                alt="MuhilanOrg Logo"
                                onClick={() => {
                                    scrollToTop();
                                }}
                            />
                        </Link>

                        <div className=" hidden lg:flex flex-1 items-center justify-center space-x-8">
                            {navigation.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.href}
                                    id='Nav-Menus'
                                    className={`text-sm  leading-6  text-gray-900 dark:text-white nav-link ${activeLink === item.href ? 'active-link' : ''}`}
                                    onClick={() => {
                                        setActiveLink(item.href);
                                        scrollToTop();
                                    }}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </div>



                    <div className='right-section'>

                        <div className="flex items-center space-x-4">
                            {/* Cart Icon */}
                            <button id="hidden-mobile"
                                type="button"
                                className="relative flex items-center justify-center p-2 rounded-full bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 ease-in-out shadow-lg ring-1 ring-gray-200 dark:ring-gray-700"
                                onClick={() => {
                                    navigate('/Cart');
                                    scrollToTop();
                                }}                            >
                                <FaShoppingCart className="h-6 w-6" />
                                {cartCount > 0 && (
                                    <div className="absolute -top-1 -right-1 w-5 h-5 flex items-center justify-center bg-blue-600 text-white text-xs font-semibold rounded-full ring-2 ring-white dark:ring-gray-800 animate-pulse">
                                        {cartCount}
                                    </div>
                                )}
                            </button>

                            {/* Editor Icon */}
                            {/* <Link to="Editor" className='pr-1 pl-1' >
                            <FaCode className='h-6 w-6 text-black dark:text-white' />
                        </Link> */}

                            <div
                                onClick={handleIconClick}
                                className="relative flex items-center justify-center w-14 h-8 cursor-pointer"
                            >
                                <div
                                    className={`absolute transition-opacity duration-500 ease-in-out ${darkMode ? 'opacity-0' : 'opacity-100'}`}
                                >
                                    <SunIcon className="h-6 w-6 text-gray-800" />
                                </div>
                                <div
                                    className={`absolute transition-opacity duration-500 ease-in-out ${darkMode ? 'opacity-100' : 'opacity-0'}`}
                                >
                                    <PiMoonStarsBold className="h-6 w-6 text-white" />
                                </div>
                            </div>


                            <div className="hidden lg:flex items-center">
                                {user ? (
                                    <UserPopover
                                        user={user}
                                        popoverOpen={popoverOpen}
                                        setPopoverOpen={setPopoverOpen}
                                        handleLogout={handleLogout}
                                    />
                                ) : (
                                    <Link to="/Authentication" className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                                        Log in <span aria-hidden="true">&rarr;</span>
                                    </Link>
                                )}
                            </div>
                            <button
                                type="button"
                                className="lg:hidden p-2 text-gray-700 dark:text-gray-300"
                                onClick={toggleMobileMenu}
                            >
                                <span className="sr-only">Open menu</span>
                                {mobileMenuOpen ? (
                                    <XMarkIcon className="h-6 w-6" />
                                ) : (
                                    <Bars3Icon className="h-6 w-6" />
                                )}
                            </button>
                        </div>

                    </div>
                </nav>
            </header>

            {/* Mobile Menu */}

            <Dialog open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)} className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-70 dark:bg-opacity-70">
                <DialogPanel className="bg-white dark:bg-gray-900 text-gray-900 dark:text-white p-6">
                    <div className="flex items-center justify-between">
                        <Link to="/" >
                            {/* Conditional rendering of logos based on darkMode */}
                            <img
                                src={darkMode ? DarkLogo : LightLogo}
                                id='logo-img'
                                className="w-22 md:w-20 lg:w-22 xl:w-22"
                                alt="MuhilanOrg Logo"
                                onClick={() => {
                                    setMobileMenuOpen(false);
                                    scrollToTop();
                                }}
                            />
                        </Link>                        <button
                            type="button"
                            className="text-gray-700 dark:text-gray-300"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <XMarkIcon className="h-6 w-6" />
                            <span className="sr-only">Close menu</span>
                        </button>
                    </div>
                    <div className="mt-6 space-y-4">
                        {navigation.filter(item => item.name !== 'Home').map((item) => (
                            <Link
                                key={item.name}
                                to={item.href}
                                id='link'
                                className="block text-lg font-semibold text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 p-3 rounded-md"
                                onClick={() => {
                                    setMobileMenuOpen(false);
                                    scrollToTop();
                                }}
                            >
                                {item.name}
                            </Link>
                        ))}



                        {/* Cart Icon */}
                        <button
                            type="button"
                            className="relative flex items-center justify-center p-2 rounded-full bg-white dark:bg-gray-800 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 ease-in-out shadow-lg ring-1 ring-gray-200 dark:ring-gray-700"
                            onClick={() => {
                                navigate('/Cart');
                                setPopoverOpen(false);
                                scrollToTop();
                            }}
                        >
                            <FaShoppingCart className="h-6 w-6" />
                            {cartCount > 0 && (
                                <div className="absolute -top-1 -right-1 w-5 h-5 flex items-center justify-center bg-blue-600 text-white text-xs font-semibold rounded-full ring-2 ring-white dark:ring-gray-800 animate-pulse">
                                    {cartCount}
                                </div>
                            )}
                        </button>

                        {user ? (
                            <UserPopover
                                user={user}
                                popoverOpen={popoverOpen}
                                setPopoverOpen={setPopoverOpen}
                                handleLogout={handleLogout}
                            />
                        ) : (
                            <Link to="/Authentication" className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                                Log in <span aria-hidden="true">&rarr;</span>
                            </Link>
                        )}

                    </div>
                </DialogPanel>
            </Dialog>
        </div>
    )
}

export default Header
