import React, { useState, useEffect } from 'react';
import {
    BriefcaseIcon, AcademicCapIcon, MagnifyingGlassIcon
} from '@heroicons/react/24/outline';
import { classNames } from '../utils/classNames'; // Helper function for conditional class names
import MyComponent from './MyComponent';

const MyProducts = () => {
    const [activeTab, setActiveTab] = useState('projects');
    const [searchQuery, setSearchQuery] = useState('');
    const [filterCategory, setFilterCategory] = useState('all');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        setSearchQuery(''); // Reset search when tab changes
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleFilterChange = (category) => {
        setFilterCategory(category);
    };

    return (
        <div className="relative top-20 mb-20 p-6 bg-gray-200 dark:bg-gray-900 min-h-screen">

            {/* Tab Navigation */}
            <div className="flex items-center justify-center space-x-4 mb-8">
                <button
                    onClick={() => handleTabChange('projects')}
                    className={classNames(
                        'px-6 py-3 flex items-center space-x-3 rounded-lg font-medium transition-transform transform hover:scale-105 ease-in-out duration-300 shadow-md',
                        activeTab === 'projects' ? 'bg-blue-600 text-white' : 'bg-white text-blue-600 border border-blue-600'
                    )}
                >
                    <BriefcaseIcon className="h-6 w-6" />
                    <span>Projects</span>
                </button>
                <button
                    onClick={() => handleTabChange('courses')}
                    className={classNames(
                        'px-6 py-3 flex items-center space-x-3 rounded-lg font-medium transition-transform transform hover:scale-105 ease-in-out duration-300 shadow-md',
                        activeTab === 'courses' ? 'bg-blue-600 text-white' : 'bg-white text-blue-600 border border-blue-600'
                    )}
                >
                    <AcademicCapIcon className="h-6 w-6" />
                    <span>Courses</span>
                </button>
            </div>

            {/* Content */}
            <div className="mt-6">
                {activeTab === 'projects' && (
                    <div className="p-4">
                        {/* <h2 className="text-3xl font-semibold text-gray-900 dark:text-gray-100 mb-4 text-center">
                            Purchased Projects
                        </h2> */}
                        <MyComponent searchQuery={searchQuery} filterCategory={filterCategory} />
                    </div>
                )}
                {activeTab === 'courses' && (
                    <div className="p-4">
                        {/* <h2 className="text-3xl font-semibold text-gray-900 dark:text-gray-100 mb-4 text-center">
                            Enrolled Courses
                        </h2> */}
                        {/* Courses Grid */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                            {/* Placeholder for courses */}
                            <p className="text-gray-700 dark:text-gray-300">Course content will appear here.</p>
                            {/* Add more course cards as needed */}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default MyProducts;
