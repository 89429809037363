import React from 'react'
import EbookBuyMain from '../Components/EbookBuyMain/EbookBuyMain';

const Ebook = () => {

  return (
    <div>
      <EbookBuyMain />

    </div>
  )
}

export default Ebook