import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { FiEdit, FiDelete, FiSave, FiPlus } from 'react-icons/fi';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0Hr_y0i8bHG6TUyeeu6lF3HzBoa4tp1o",
    authDomain: "codewithmuhilandb.firebaseapp.com",
    projectId: "codewithmuhilandb",
    storageBucket: "codewithmuhilandb.appspot.com",
    messagingSenderId: "54571259121",
    appId: "1:54571259121:web:a07590b257308747128550",
    measurementId: "G-GZB15RRKD7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

Modal.setAppElement('#root');

const ProductSection = () => {
    const [products, setProducts] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingProductId, setEditingProductId] = useState(null);
    const [form, setForm] = useState({
        name: '',
        description: '',
        price: '',
        image: '',
        link: '',
        category: '' // Add category to form state
    });

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'Products'), (snapshot) => {
            const productData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setProducts(productData);
        }, (error) => {
            console.error("Error fetching products: ", error);
        });

        return () => unsubscribe();
    }, []);

    const handleEdit = (id) => {
        const product = products.find((product) => product.id === id);
        if (product) {
            setForm(product);
            setEditingProductId(id);
            setIsModalOpen(true);
        }
    };

    const handleDelete = (id) => {
        if (typeof id !== 'string') {
            console.error('ID must be a string');
            return;
        }

        const docRef = doc(db, 'Products', id);
        deleteDoc(docRef)
            .then(() => {
                console.log('Document successfully deleted!');
            })
            .catch((error) => console.error('Error deleting document: ', error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: name === 'price' ? parseFloat(value) || '' : value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate form fields before submission
        if (!form.name || !form.price || !form.image || !form.category) {
            console.error('Name, Price, Image URL, and Category are required');
            return;
        }

        try {
            if (editingProductId) {
                // Update existing product
                const docRef = doc(db, 'Products', editingProductId);
                await updateDoc(docRef, {
                    ...form,
                    price: parseFloat(form.price), // Ensure price is a number
                    id: editingProductId // Ensure id is included
                });
                console.log('Document successfully updated!');
            } else {
                // Add new product
                const newDocRef = await addDoc(collection(db, 'Products'), {
                    ...form,
                    price: parseFloat(form.price), // Ensure price is a number
                    id: '' // Initial placeholder for id
                });
                // Update the document with its ID
                await updateDoc(newDocRef, {
                    id: newDocRef.id
                });
                console.log('Document successfully added!');
            }

            setForm({ name: '', description: '', price: '', image: '', link: '', category: '' });
            setEditingProductId(null);
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error saving document: ', error);
        }
    };




    return (
        <div className="relative  flex h-screen bg-gray-200">


            <div className="flex-1 flex flex-col">

                {/* Main Content */}
                <div className="flex-1 p-6 overflow-auto">
                    {/* Product Form Button */}
                    <div className="mb-6 flex justify-end">
                        <button
                            onClick={() => {
                                setEditingProductId(null);
                                setForm({ name: '', description: '', price: '', image: '', link: '', category: '' });
                                setIsModalOpen(true);
                            }}
                            className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-4 py-2 rounded-md flex items-center hover:from-blue-600 hover:to-blue-700 transition"
                        >
                            <FiPlus className="mr-2" /> Add New Product
                        </button>
                    </div>

                    {/* Product List */}
                    <div style={{ marginBottom: '75px' }} className="bg-white shadow-lg rounded-lg p-6">
                        <h2 className="text-2xl font-bold mb-4 dark:text-white">Product List</h2>
                        <table className="w-full border-collapse">
                            <thead>
                                <tr className="bg-gray-300 border-b">
                                    <th className="border p-3 text-left">ID</th>
                                    <th className="border p-3 text-left">Name</th>
                                    <th className="border p-3 text-left">Description</th>
                                    <th className="border p-3 text-left">Price</th>
                                    <th className="border p-3 text-left">Category</th> {/* Add category header */}
                                    <th className="border p-3 text-left">Image</th>
                                    <th className="border p-3 text-left">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products.map((product) => (
                                    <tr key={product.id} className="transition-colors">
                                        <td className="border p-3 dark:text-white">{product.id}</td>
                                        <td className="border p-3 dark:text-white">{product.name}</td>
                                        <td className="border p-3 dark:text-white">{product.description}</td>
                                        <td className="border p-3 dark:text-white">{product.price}</td>
                                        <td className="border p-3 dark:text-white">{product.category}</td> {/* Add category value */}
                                        <td className="border p-3 dark:text-white">
                                            <img src={product.image} alt={product.name} className="w-24 h-24 object-cover rounded-md" />
                                        </td>
                                        <td className="border p-3 flex space-x-2">
                                            <button
                                                onClick={() => handleEdit(product.id)}
                                                className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-3 py-1 rounded-md hover:from-blue-600 hover:to-blue-700 transition"
                                            >
                                                <FiEdit />
                                            </button>
                                            <button
                                                onClick={() => handleDelete(product.id)}
                                                className="bg-gradient-to-r from-red-500 to-red-600 text-white px-3 py-1 rounded-md hover:from-red-600 hover:to-red-700 transition"
                                            >
                                                <FiDelete />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Product Form Modal */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                className="fixed inset-0 flex items-center justify-center p-4"
                overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-80"
                contentClassName="bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg p-8 max-w-4xl mx-auto shadow-xl overflow-hidden"
            >
                <div className="relative top-4 bg-white rounded-lg shadow-lg max-w-3xl w-full h-full max-h-[90vh] overflow-auto"
                    style={{ padding: '50px' }}
                >
                    <button
                        onClick={() => setIsModalOpen(false)}
                        className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 transition-colors duration-300"
                    >
                        <FaTimes size={24} />
                    </button>
                    <h2 className="text-3xl font-bold mb-8 text-center text-gray-900">
                        {editingProductId ? 'Edit Product' : 'Add New Product'}
                    </h2>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="space-y-2">
                            <label className="block text-gray-700 font-semibold">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={form.name}
                                onChange={handleChange}
                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="block text-gray-700 font-semibold">Description</label>
                            <textarea
                                name="description"
                                value={form.description}
                                onChange={handleChange}
                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                                rows="5"
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="block text-gray-700 font-semibold">Price</label>
                            <input
                                type="number"
                                name="price"
                                value={form.price}
                                onChange={handleChange}
                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="block text-gray-700 font-semibold">Image URL</label>
                            <input
                                type="text"
                                name="image"
                                value={form.image}
                                onChange={handleChange}
                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="block text-gray-700 font-semibold">Product Link</label>
                            <input
                                type="text"
                                name="link"
                                value={form.link}
                                onChange={handleChange}
                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                            />
                        </div>
                        <div className="space-y-2">
                            <label className="block text-gray-700 font-semibold">Category</label>
                            <input
                                type="text"
                                name="category"
                                value={form.category}
                                onChange={handleChange}
                                className="w-full p-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                                required
                            />
                        </div>
                        <div className="flex justify-end mt-6">
                            <button
                                type="submit"
                                className="bg-gradient-to-r from-blue-500 to-blue-600 text-white px-8 py-3 rounded-lg shadow-md hover:from-blue-600 hover:to-blue-700 transition-colors duration-300"
                            >
                                {editingProductId ? 'Save Changes' : 'Add Product'}
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>

        </div>

    );
};



export default ProductSection;
