import React, { useState } from 'react';
import { getFirestore, collection, onSnapshot, addDoc, doc,setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from "../../../config/config";

const EnrollmentStatisticsForm = ({ setEnrollmentStats }) => {
    const [formValues, setFormValues] = useState({ labels: '', data: '' });
    const [isEditing, setIsEditing] = useState(false);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const labels = formValues.labels.split(',').map(label => label.trim());
        const data = formValues.data.split(',').map(value => parseFloat(value.trim()));

        try {
            await setDoc(doc(db, "enrollmentStatistics", "stats"), {
                labels,
                data
            });
            setEnrollmentStats({ labels, data });
            setIsEditing(false);
        } catch (error) {
            console.error("Error updating statistics:", error);
        }
    };

    return (
        <div className="mt-8 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600 p-6 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold dark:text-white">Edit Enrollment Statistics</h3>
            <form onSubmit={handleSubmit} className="mt-4 space-y-4">
                <div>
                    <label htmlFor="labels" className="block text-sm font-semibold dark:text-white">Labels (comma-separated):</label>
                    <input
                        id="labels"
                        name="labels"
                        type="text"
                        value={formValues.labels}
                        onChange={handleFormChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-700 dark:text-white"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="data" className="block text-sm font-semibold dark:text-white">Data (comma-separated numbers):</label>
                    <input
                        id="data"
                        name="data"
                        type="text"
                        value={formValues.data}
                        onChange={handleFormChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm dark:bg-gray-700 dark:text-white"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="mt-4 w-full rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-600"
                >
                    Save Changes
                </button>
            </form>
        </div>
    );
};

export default EnrollmentStatisticsForm;
