import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const SaveCode = ({ htmlCode, cssCode, jsCode }) => {
    const saveCode = async () => {
        const zip = new JSZip();

        zip.file('index.html', htmlCode);
        zip.file('styles.css', cssCode);
        zip.file('script.js', jsCode);

        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, 'code.zip');
    };

    return (
        <button onClick={saveCode} className="p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-700">
            <FontAwesomeIcon icon={faDownload} className="w-5 h-5 text-gray-500 dark:text-gray-300" />
        </button>
    );
};

export default SaveCode;
