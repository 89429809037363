import React, { useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const ProjectSidebar = ({ onFilterChange, onSortChange }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSortOption, setSelectedSortOption] = useState('');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleFilterChange = (category) => {
        setSelectedCategory(category);
        onFilterChange(category); // Notify parent about the filter change
    };

    const handleSortChange = (option) => {
        setSelectedSortOption(option);
        onSortChange(option); // Notify parent about the sort option change
    };

    return (
        <>
            {/* Sidebar Toggle Icon */}
            <button
                onClick={toggleSidebar}
                className="fixed top-20 mt-6 right-4 z-50 bg-gray-800 text-white rounded-full p-2 shadow-lg"
            >
                <AiOutlineMenu className="h-6 w-6" />
            </button>

            {/* Sidebar Overlay */}
            <div
                className={`fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ${isSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                onClick={toggleSidebar}
            ></div>

            {/* Sidebar Content */}
            <div
                className={`fixed top-0 right-0 h-full w-64 bg-white dark:bg-gray-800 shadow-lg border-l border-gray-200 dark:border-gray-700 z-50 transform transition-transform duration-300 ${isSidebarOpen ? 'translate-x-0' : 'translate-x-full'}`}
            >
                <button
                    onClick={toggleSidebar}
                    className="absolute top-4 left-4 bg-gray-800 text-white rounded-full p-2 shadow-lg"
                >
                    <AiOutlineClose className="h-6 w-6" />
                </button>
                <h2 className="text-xl font-semibold text-gray-900 dark:text-gray-100 mb-4 p-6 pt-20">
                    {/* Filters */}
                </h2>
                {/* <div className="px-6 mb-4">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Category</h3>
                    <ul className="mt-2 space-y-2">
                        <li>
                            <button
                                onClick={() => handleFilterChange('Web Development')}
                                className={`text-blue-600 dark:text-blue-400 hover:underline ${selectedCategory === 'Web Development' ? 'font-bold' : ''}`}
                            >
                                Web Development
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleFilterChange('Mobile Apps')}
                                className={`text-blue-600 dark:text-blue-400 hover:underline ${selectedCategory === 'Mobile Apps' ? 'font-bold' : ''}`}
                            >
                                Mobile Apps
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleFilterChange('UI/UX Design')}
                                className={`text-blue-600 dark:text-blue-400 hover:underline ${selectedCategory === 'UI/UX Design' ? 'font-bold' : ''}`}
                            >
                                UI/UX Design
                            </button>
                        </li>
                    </ul>
                </div> */}
                <div className="px-6">
                    <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">Sort By</h3>
                    <ul className="mt-2 space-y-2">
                        <li>
                            <button
                                onClick={() => handleSortChange('Most Recent')}
                                className={`text-blue-600 dark:text-blue-400 hover:underline ${selectedSortOption === 'Most Recent' ? 'font-bold' : ''}`}
                            >
                                Most Recent
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleSortChange('Highest Rated')}
                                className={`text-blue-600 dark:text-blue-400 hover:underline ${selectedSortOption === 'Highest Rated' ? 'font-bold' : ''}`}
                            >
                                Highest Rated
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleSortChange('Price: High to Low')}
                                className={`text-blue-600 dark:text-blue-400 hover:underline ${selectedSortOption === 'Price: High to Low' ? 'font-bold' : ''}`}
                            >
                                Price: High to Low
                            </button>
                        </li>
                        <li>
                            <button
                                onClick={() => handleSortChange('Price: Low to High')}
                                className={`text-blue-600 dark:text-blue-400 hover:underline ${selectedSortOption === 'Price: Low to High' ? 'font-bold' : ''}`}
                            >
                                Price: Low to High
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default ProjectSidebar;
