import React from 'react';
import './CourseBanner.css';
import DarkModeVideo from '../Assets/DarkModeBanner.mp4'; // Path to dark mode video
import LightModeVideo from '../Assets/LightModeBanner.mp4'; // Path to light mode video

const CourseBanner = ({ darkMode }) => {
    const videoSrc = darkMode ? LightModeVideo : DarkModeVideo;

    return (
        <div className="course-banner">
            <video
                key={videoSrc} // This forces React to re-render the video when the source changes
                className="banner-video"
                src={videoSrc}
                autoPlay
                loop
                muted
                playsInline
            />
            <div className="banner-content">
                {/* Your banner content */}
            </div>
        </div>
    );
};

export default CourseBanner;
