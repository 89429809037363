import React, { useState, useEffect } from 'react';
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FaHtml5, FaCss3Alt, FaJsSquare, FaReact, FaNodeJs, FaPython, FaJava, FaCode } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai'; // Person icon
import { IoBookSharp } from "react-icons/io5";


const SlideableMenu = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMessageVisible, setIsMessageVisible] = useState(true);

    useEffect(() => {
        // Check local storage to see if the message should be hidden
        const messageHidden = localStorage.getItem('messageHidden');
        if (messageHidden) {
            setIsMessageVisible(false);
        }
    }, []);

    const handleCloseMessage = () => {
        setIsMessageVisible(false);
        localStorage.setItem('messageHidden', 'true');
    };

    const menuItems = [
        { name: 'HTML', href: '/htmlpage', icon: <FaHtml5 className="h-6 w-6" aria-hidden="true" /> },
        { name: 'CSS', href: '/NotFound', icon: <FaCss3Alt className="h-6 w-6" aria-hidden="true" /> },
        { name: 'JavaScript', href: '/NotFound', icon: <FaJsSquare className="h-6 w-6" aria-hidden="true" /> },
        { name: 'React', href: '/NotFound', icon: <FaReact className="h-6 w-6" aria-hidden="true" /> },
        { name: 'Node.js', href: '/NotFound', icon: <FaNodeJs className="h-6 w-6" aria-hidden="true" /> },
        { name: 'Python', href: '/NotFound', icon: <FaPython className="h-6 w-6" aria-hidden="true" /> },
        { name: 'Java', href: '/NotFound', icon: <FaJava className="h-6 w-6" aria-hidden="true" /> },
        { name: 'Code', href: '/NotFound', icon: <FaCode className="h-6 w-6" aria-hidden="true" /> },
    ];

    return (
        <div className="relative">
            {/* Popover message */}
            {isMessageVisible && (
                <div className="fixed bottom-20 right-4 z-50 flex items-center space-x-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 shadow-lg rounded-lg p-3 text-sm text-gray-900 dark:text-white transition-opacity duration-300 ease-in-out">
                    <div className="w-10 h-10 bg-gradient-to-r from-blue-500 to-blue-600 rounded-full flex items-center justify-center text-white">
                        <AiOutlineUser className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <div className="relative flex items-center">
                        <span className="ml-2">Start learning today!</span>
                        <div className="absolute left-[-10px] top-1/2 transform -translate-y-1/2 w-0 h-0 border-t-8 border-t-white dark:border-t-gray-800 border-r-8 border-r-transparent border-b-8 border-b-transparent border-l-8 border-l-transparent"></div>
                    </div>
                    <button
                        onClick={handleCloseMessage}
                        className="ml-2 p-1 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                    >
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
            )}

            {/* Menu Icon */}
            <div className="fixed bottom-6 right-6 z-50">
                <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="p-3 bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-full shadow-lg hover:from-blue-600 hover:to-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                >
                    <IoBookSharp className="h-8 w-8" aria-hidden="true" />

                </button>

                {isMenuOpen && (
                    <div
                        className="fixed inset-0 bg-black bg-opacity-30 z-40"
                        onClick={() => setIsMenuOpen(false)}
                    >
                        <div
                            className="fixed bottom-0 right-0 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 shadow-lg rounded-lg w-80 transform translate-y-4 transition-transform duration-300 ease-in-out"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <button
                                onClick={() => setIsMenuOpen(false)}
                                className="absolute top-2 right-2 p-2 text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
                            >
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                            <div className="flex flex-col py-4 px-6">
                                {menuItems.map((item) => (
                                    <a
                                        key={item.name}
                                        href={item.href}
                                        className="block text-gray-900 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 px-4 py-2 rounded transition-colors duration-300 flex items-center"
                                    >
                                        {item.icon}
                                        <span className="ml-2">{item.name}</span>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SlideableMenu;
