import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheckCircle, FaTimesCircle, FaShoppingCart } from 'react-icons/fa';

const PaymentConfirmationPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { paymentStatus, product, paymentId, amount } = location.state || {};

    useEffect(() => {
        // Redirect to home page if no payment details are available
        if (!location.state) {
            navigate('/');
        }
    }, [location, navigate]);

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900">
            <div className="max-w-4xl w-full bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 p-10 rounded-xl shadow-2xl">
                {paymentStatus === 'success' ? (
                    <div className="flex flex-col items-center">
                        <FaCheckCircle className="text-green-500 text-6xl mb-4 animate-bounce" />
                        <h1 className="text-4xl font-bold text-center text-green-500 mb-4">Payment Successful!</h1>
                        <p className="text-lg text-center mb-6">
                            Thank you for your purchase! Your payment has been processed successfully.
                        </p>

                        <div className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg p-6 w-full mb-6">
                            <h2 className="text-2xl font-semibold mb-4 text-center">Transaction Summary</h2>
                            <div className="grid grid-cols-2 gap-4 ">
                                <p><strong>Product:</strong> {product?.title}</p>
                                <p><strong>Payment ID:</strong> {paymentId}</p>
                                <p><strong>Amount Paid:</strong> ₹ {amount || '0.00'}</p>
                                <p><strong>Download Link:</strong>
                                    <a href={product?.Link} className="text-blue-500 hover:underline ml-2">Download Product</a>
                                </p>
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <button
                                className="bg-blue-500 text-white px-6 py-2 rounded-lg font-semibold hover:bg-blue-600 transition duration-300"
                                onClick={() => navigate('/shop')}>
                                Continue Shopping
                            </button>
                            <button
                                className="bg-gray-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-gray-700 transition duration-300"
                                onClick={() => navigate('/orders')}>
                                View Orders
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center">
                        <FaTimesCircle className="text-red-500 text-6xl mb-4 animate-bounce" />
                        <h1 className="text-4xl font-bold text-center text-red-500 mb-4">Payment Failed!</h1>
                        <p className="text-lg text-center mb-6">
                            We’re sorry, but there was an issue processing your payment. Please try again later.
                        </p>

                        <div className="bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg p-6 w-full mb-6">
                            <h2 className="text-2xl font-semibold mb-4 text-center">Transaction Summary</h2>
                            <div className="grid grid-cols-2 gap-4">
                                <p><strong>Product:</strong> {product?.title || 'Unknown Product'}</p>
                                <p><strong>Amount:</strong> ₹ {amount || '0.00'}</p>
                                <p><strong>Payment Status:</strong> Failed</p>
                            </div>
                        </div>

                        <div className="flex space-x-4">
                            <button
                                className="bg-red-500 text-white px-6 py-2 rounded-lg font-semibold hover:bg-red-600 transition duration-300"
                                onClick={() => navigate('/support')}>
                                Contact Support
                            </button>
                            <button
                                className="bg-gray-600 text-white px-6 py-2 rounded-lg font-semibold hover:bg-gray-700 transition duration-300"
                                onClick={() => navigate('/shop')}>
                                Return to Shop
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentConfirmationPage;
