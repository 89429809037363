import React, { useEffect, useRef, useState } from 'react';
import { ShoppingBagIcon, DevicePhoneMobileIcon, SparklesIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion'; // Import Framer Motion for animation

const services = [
  {
    icon: <ShoppingBagIcon className="h-12 w-12 text-indigo-600" />,
    title: "eCommerce Platforms",
    description: "Custom online stores with secure transactions and intuitive user interfaces.",
  },
  {
    icon: <DevicePhoneMobileIcon className="h-12 w-12 text-indigo-600" />,
    title: "Responsive Design",
    description: "Elegant, adaptive designs ensuring a seamless experience on any device.",
  },
  {
    icon: <SparklesIcon className="h-12 w-12 text-indigo-600" />,
    title: "Dynamic Animations",
    description: "Interactive, engaging animations using GSAP for a modern user experience.",
  },
  {
    icon: <GlobeAltIcon className="h-12 w-12 text-indigo-600" />,
    title: "Site Optimization",
    description: "Optimizing performance for fast, efficient, and reliable web experiences.",
  },
];

export default function HomeServices() {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  // Intersection Observer to detect when section is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.2 } // Trigger when 20% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="relative overflow-hidden bg-gradient-to-r from-teal-50 to-blue-50">
      {/* Animated Background */}
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <div className="h-full w-full bg-gradient-to-r from-purple-300 via-blue-300 to-teal-300 opacity-20 animate-pulse"></div>
      </div>

      <div className="pb-40 pt-16 sm:pb-32 sm:pt-24 lg:pb-40 lg:pt-32 bg-white dark:bg-gray-900">
        <div className="relative mx-auto max-w-7xl px-4 sm:static sm:px-6 lg:px-8">
          <motion.div
            className="text-center mb-12"
            initial={{ opacity: 0, y: -50 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, ease: 'easeOut' }}
          >
            <h2 className="text-3xl font-extrabold leading-tight tracking-tight text-black dark:text-white">
              Our Web Development Services
            </h2>
            <p className="text-base mt-4 text-gray-600 dark:text-gray-300">
              Professional solutions to elevate your online presence and performance.
            </p>
          </motion.div>

          <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4 lg:gap-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className="relative group service-card"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={isVisible ? { opacity: 1, scale: 1 } : {}}
                transition={{ duration: 0.8, ease: 'easeOut', delay: index * 0.2 }} // Add delay for each item
              >
                <div className="relative h-64 w-full bg-white rounded-lg shadow-lg overflow-hidden border border-gray-200 dark:border-gray-700 group-hover:shadow-2xl group-hover:rotate-3">
                  <div className="p-6 text-center flex flex-col items-center justify-center h-full">
                    {service.icon}
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                      {service.title}
                    </h3>
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-300">
                      {service.description}
                    </p>
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-t from-teal-600 to-teal-400 opacity-0 group-hover:opacity-20 transition-opacity duration-500"></div>
                </div>
              </motion.div>
            ))}
          </div>

          <motion.div
            className="flex justify-center text-center mt-12"
            initial={{ opacity: 0, y: 20 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.6, ease: 'easeOut' }}
          >
            <a
              id="Regular-Btn"
              style={{ width: '200px', fontWeight: '700' }}
              href="/contact"
              className="inline-block rounded-md border border-transparent bg-gradient-to-r from-indigo-600 to-indigo-800 px-8 py-3 text-center font-medium text-black shadow-lg hover:bg-gradient-to-l hover:from-indigo-700 hover:to-indigo-900 transition duration-300 transform hover:scale-105"
            >
              Get in Touch
            </a>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
