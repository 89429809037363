import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { FiEdit, FiDelete, FiPlus } from 'react-icons/fi';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC0Hr_y0i8bHG6TUyeeu6lF3HzBoa4tp1o",
  authDomain: "codewithmuhilandb.firebaseapp.com",
  projectId: "codewithmuhilandb",
  storageBucket: "codewithmuhilandb.appspot.com",
  messagingSenderId: "54571259121",
  appId: "1:54571259121:web:a07590b257308747128550",
  measurementId: "G-GZB15RRKD7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

Modal.setAppElement('#root');

const ProjectForm = () => {
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [form, setForm] = useState({
    title: '',
    description: '',
    price: '',
    image: '',
    link: '',
    category: '',
    client: '',
    clientQuote: '',
    compatibleBrowsers: '',
    documentation: '',
    features: '',
    gallery: '',
    highResolution: '',
    id: '',
    layout: '',
    overview: '',
    postUrl: '',
    published: '',
    rating: '',
    saleCount: '',
    techniques: '',
    videoUrl: ''
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'projects'), (snapshot) => {
      const productData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setProducts(productData);
    }, (error) => {
      console.error("Error fetching products: ", error);
    });

    return () => unsubscribe();
  }, []);

  const handleEdit = (id) => {
    const product = products.find((product) => product.id === id);
    if (product) {
      setForm(product);
      setEditingProductId(id);
      setIsModalOpen(true);
    }
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      const docRef = doc(db, 'projects', id);
      deleteDoc(docRef)
        .then(() => {
          console.log('Document successfully deleted!');
        })
        .catch((error) => console.error('Error deleting document: ', error));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!form.title || !form.price || !form.image || !form.category) {
      console.error('Title, Price, Image URL, and Category are required');
      return;
    }

    try {
      if (editingProductId) {
        const docRef = doc(db, 'projects', editingProductId);
        await updateDoc(docRef, {
          ...form,
          price: parseFloat(form.price),
          rating: parseFloat(form.rating),
          id: editingProductId
        });
        console.log('Document successfully updated!');
      } else {
        const newDocRef = await addDoc(collection(db, 'projects'), {
          ...form,
          price: parseFloat(form.price),
          rating: parseFloat(form.rating),
          id: ''
        });
        await updateDoc(newDocRef, {
          id: newDocRef.id
        });
        console.log('Document successfully added!');
      }

      setForm({
        title: '',
        description: '',
        price: '',
        image: '',
        link: '',
        category: '',
        client: '',
        clientQuote: '',
        compatibleBrowsers: '',
        documentation: '',
        features: '',
        gallery: '',
        highResolution: '',
        id: '',
        layout: '',
        overview: '',
        postUrl: '',
        published: '',
        rating: '',
        saleCount: '',
        techniques: '',
        videoUrl: ''
      });
      setEditingProductId(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving document: ', error);
    }
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    setIsDetailsOpen(true);
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <div className="flex-grow p-6">
        {/* <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-800">Product List</h2>
          <button
            onClick={() => {
              setEditingProductId(null);
              setForm({
                title: '',
                description: '',
                price: '',
                image: '',
                link: '',
                client: '',
                compatibleBrowsers: '',
                documentation: '',
                features: '',
                gallery: '',
                highResolution: '',
                projectId: '',
                layout: '',
                overview: '',
                postUrl: '',
                published: '',
                rating: '',
                saleCount: '',
                techniques: '',
                videoUrl: ''
              });
              setIsModalOpen(true);
            }}
            className="bg-blue-500 text-white px-4 py-2 rounded-md shadow-md hover:bg-blue-600 transition duration-200 flex items-center"
          >
            <FiPlus className="mr-2" /> Add New Product
          </button>
        </div> */}

        <div className="relative overflow-x-auto bg-white shadow-md rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-200 text-gray-600 uppercase text-xs leading-normal">
              <tr>
                <th className="py-2 px-4 text-left">ID</th>
                <th className="py-2 px-4 text-left">Title</th>
                <th className="py-2 px-4 text-left">Image</th>
                <th className="py-2 px-4 text-left">Price</th>
                <th className="py-2 px-4 text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-xs font-light">
              {products.map((product) => (
                <tr
                  key={product.projectId}
                  className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleRowClick(product)}
                >
                  <td className="py-2 px-4 text-left">{product.projectId}</td>
                  <td className="py-2 px-4 text-left">{product.title}</td>
                  <td className="py-2 px-4 text-left">
                    <img src={product.image} alt={product.title} className="w-16 h-16 object-cover" />
                  </td>
                  <td className="py-2 px-4 text-left">{product.price}</td>
                  <td className="py-2 px-4 text-left flex space-x-2">
                    <button
                      onClick={() => handleEdit(product.projectId)}
                      className="text-green-500 hover:text-green-700"
                    >
                      <FiEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(product.projectId)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FiDelete />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Product Details Modal */}
      <Modal
        isOpen={isDetailsOpen}
        onRequestClose={() => setIsDetailsOpen(false)}
        className="modal max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg overflow-y-scroll"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <button
          onClick={() => setIsDetailsOpen(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={24} />
        </button>
        <div className="flex flex-col space-y-4 "
          style={{ overflow: 'scroll', fontSize: '10px' }}>
          {selectedProduct && (
            <>
              <h2 className="text-2xl font-bold">{selectedProduct.title}</h2>
              <img src={selectedProduct.image} alt={selectedProduct.title} className="w-10 h-auto" />
              <p><strong>Price:</strong> {selectedProduct.price}</p>
              <p><strong>Description:</strong> {selectedProduct.description}</p>
              <p><strong>Category:</strong> {selectedProduct.category}</p>
              <p><strong>Client:</strong> {selectedProduct.client}</p>
              <p><strong>Client Quote:</strong> {selectedProduct.clientQuote}</p>
              <p><strong>Compatible Browsers:</strong> {selectedProduct.compatibleBrowsers}</p>
              <p><strong>Documentation:</strong> {selectedProduct.documentation}</p>
              <p><strong>Features:</strong> {selectedProduct.features}</p>
              <p><strong>Gallery:</strong> {selectedProduct.gallery}</p>
              <p><strong>High Resolution:</strong> {selectedProduct.highResolution}</p>
              <p><strong>ID:</strong> {selectedProduct.id}</p>
              <p><strong>Layout:</strong> {selectedProduct.layout}</p>
              <p><strong>Overview:</strong> {selectedProduct.overview}</p>
              <p><strong>Post URL:</strong> {selectedProduct.postUrl}</p>
              <p><strong>Published:</strong> {selectedProduct.published}</p>
              <p><strong>Rating:</strong> {selectedProduct.rating}</p>
              <p><strong>Sale Count:</strong> {selectedProduct.saleCount}</p>
              <p><strong>Techniques:</strong> {selectedProduct.techniques}</p>
              <p><strong>Video URL:</strong> {selectedProduct.videoUrl}</p>
            </>
          )}
        </div>
      </Modal>

      {/* Product Form Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="modal max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg overflow-y-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <button
          onClick={() => setIsModalOpen(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FaTimes size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4">
          {editingProductId ? 'Edit Product' : 'Add New Product'}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Title</label>
              <input
                type="text"
                name="title"
                value={form.title}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Image URL</label>
              <input
                type="text"
                name="image"
                value={form.image}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price</label>
              <input
                type="number"
                name="price"
                value={form.price}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
            </div>
            {/* <div>
              <label className="block text-sm font-medium text-gray-700">Category</label>
              <input
                type="text"
                name="category"
                value={form.category}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                required
              />
            </div> */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <textarea
                name="description"
                value={form.description}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                rows="4"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Link</label>
              <input
                type="text"
                name="link"
                value={form.link}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Client</label>
              <input
                type="text"
                name="client"
                value={form.client}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* <div>
              <label className="block text-sm font-medium text-gray-700">Client Quote</label>
              <input
                type="text"
                name="clientQuote"
                value={form.clientQuote}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div> */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Compatible Browsers</label>
              <input
                type="text"
                name="compatibleBrowsers"
                value={form.compatibleBrowsers}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Documentation</label>
              <input
                type="text"
                name="documentation"
                value={form.documentation}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Features</label>
              <input
                type="text"
                name="features"
                value={form.features}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Gallery</label>
              <input
                type="text"
                name="gallery"
                value={form.gallery}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">High Resolution</label>
              <input
                type="text"
                name="highResolution"
                value={form.highResolution}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">ID</label>
              <input
                type="text"
                name="id"
                value={form.projectId}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                readOnly
              />
            </div>
            {/* <div>
              <label className="block text-sm font-medium text-gray-700">Layout</label>
              <input
                type="text"
                name="layout"
                value={form.layout}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div> */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Overview</label>
              <input
                type="text"
                name="overview"
                value={form.overview}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* <div>
              <label className="block text-sm font-medium text-gray-700">Post URL</label>
              <input
                type="text"
                name="postUrl"
                value={form.postUrl}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div> */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Published</label>
              <input
                type="text"
                name="published"
                value={form.published}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Rating</label>
              <input
                type="number"
                name="rating"
                value={form.rating}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Sale Count</label>
              <input
                type="number"
                name="saleCount"
                value={form.saleCount}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Techniques</label>
              <input
                type="text"
                name="techniques"
                value={form.techniques}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Video URL</label>
              <input
                type="text"
                name="videoUrl"
                value={form.videoUrl}
                onChange={handleChange}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
            >
              {editingProductId ? 'Save Changes' : 'Add Product'}
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ProjectForm;
