import React, { useState, useEffect } from 'react';
import { Editor } from '@monaco-editor/react';
import Htmllessons from './Htmllessons.json'; // Ensure this path is correct
import Sidebar from './Sidebar'; // Import the Sidebar component
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { CodeBracketIcon, ArrowLeftCircleIcon, EyeIcon, ArrowRightCircleIcon, MoonIcon, SunIcon, SearchIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import SaveCode from './SaveCode';

const HtmlCourseViewPage = () => {
    const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
    const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
    const [selectedCodeType, setSelectedCodeType] = useState('html'); // 'html', 'css', 'js'
    const [htmlCode, setHtmlCode] = useState('');
    const [cssCode, setCssCode] = useState('');
    const [jsCode, setJsCode] = useState('');
    const [darkMode, setDarkMode] = useState(false);
    const [wordWrap, setWordWrap] = useState(true);
    const [showMainTitle, setShowMainTitle] = useState(true);

    useEffect(() => {
        const topic = Htmllessons[0]?.topics[currentTopicIndex];
        const lesson = topic?.lessons[currentLessonIndex];
        if (lesson) {
            setHtmlCode(lesson.htmlCode || '');
            setCssCode(lesson.cssCode || '');
            setJsCode(lesson.jsCode || '');
        }
    }, [currentTopicIndex, currentLessonIndex]);

    useEffect(() => {
        updateLiveOutput(htmlCode, cssCode, jsCode);
    }, [htmlCode, cssCode, jsCode]);

    useEffect(() => {
        if (currentLessonIndex > 0) {
            setShowMainTitle(false);
        }
    }, [currentLessonIndex]);

    const updateLiveOutput = (html, css, js) => {
        const htmlWithCss = `
        <html>
        <head>
            <style>${css}</style>
        </head>
        <body>
            ${html}
            <script>
                const originalConsoleLog = console.log;
                const originalAlert = window.alert;

                // Redirect console.log to the output container
                console.log = function(...args) {
                    const outputContainer = document.getElementById('outputConsole');
                    if (outputContainer) {
                        outputContainer.innerHTML += '<p>' + args.map(arg => typeof arg === 'object' ? JSON.stringify(arg) : arg).join(' ') + '</p>';
                    }
                    originalConsoleLog.apply(console, args);
                };

                // Redirect window.alert to the output container
                window.alert = function(message) {
                    const outputContainer = document.getElementById('outputConsole');
                    if (outputContainer) {
                        outputContainer.innerHTML += '<p>' + message + '</p>';
                    }
                    originalAlert(message);
                };
                
                ${js}
            </script>
        </body>
        </html>`;
        const iframe = document.getElementById('outputPreview');
        if (iframe) {
            iframe.contentDocument.open();
            iframe.contentDocument.write(htmlWithCss);
            iframe.contentDocument.close();
        }
    };

    const handleCodeChange = (value) => {
        if (selectedCodeType === 'html') {
            setHtmlCode(value);
        } else if (selectedCodeType === 'css') {
            setCssCode(value);
        } else if (selectedCodeType === 'js') {
            setJsCode(value);
        }
    };

    const handlePreviewClick = () => {
        const previewWindow = window.open("", "Preview", "width=800,height=600");
        previewWindow.document.open();
        previewWindow.document.write(`
        <html>
        <head>
            <style>${cssCode}</style>
        </head>
        <body>${htmlCode}</body>
        <script>${jsCode}</script></html>`);
        previewWindow.document.close();
    };

    const handlePrevLesson = () => {
        if (currentLessonIndex > 0) {
            setCurrentLessonIndex(currentLessonIndex - 1);
        }
    };

    const handleNextLesson = () => {
        const topic = Htmllessons[0]?.topics[currentTopicIndex];
        if (currentLessonIndex < topic.lessons.length - 1) {
            setCurrentLessonIndex(currentLessonIndex + 1);
        }
    };

    const handlePrevTopic = () => {
        if (currentTopicIndex > 0) {
            setCurrentTopicIndex(currentTopicIndex - 1);
            setCurrentLessonIndex(0);
        }
    };

    const handleNextTopic = () => {
        if (currentTopicIndex < Htmllessons[0]?.topics.length - 1) {
            setCurrentTopicIndex(currentTopicIndex + 1);
            setCurrentLessonIndex(0);
        }
    };

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const toggleWordWrap = () => {
        setWordWrap(!wordWrap);
    };

    const saveCode = () => {
        const code = {
            html: htmlCode,
            css: cssCode,
            js: jsCode
        };

        const fileName = `code.${selectedCodeType}`;
        const blob = new Blob([code[selectedCodeType]], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Revoke the object URL after use
        URL.revokeObjectURL(url);
    };

    const editorOptions = {
        fontSize: 14,
        minimap: { enabled: false },
        scrollBeyondLastLine: false,
        wordWrap: wordWrap ? 'on' : 'off',
        theme: darkMode ? 'vs-dark' : 'vs-light',
        lineNumbers: 'on',
    };

    const topic = Htmllessons[0]?.topics[currentTopicIndex];
    const lesson = topic?.lessons[currentLessonIndex];


    return (
        <div className={`flex flex-col md:flex-row ${darkMode ? 'bg-gray-900' : 'bg-gray-50'}`}>
            {/* Sidebar Component */}
            <Sidebar
                topics={Htmllessons[0]?.topics}
                currentTopicIndex={currentTopicIndex}
                currentLessonIndex={currentLessonIndex}
                onTopicChange={setCurrentTopicIndex}
                onLessonChange={setCurrentLessonIndex}
            />

            {/* Main Content */}
            <main className="flex-1 p-6 md:p-8 bg-white dark:bg-gray-800">
                <section id="Course-Section" className="relative bg-white dark:bg-gray-800 top-16 md:top-16 lg:top-20 xl:top-14 overflow-hidden py-8 sm:py-12 lg:py-16 rounded-lg shadow-lg">
                    <div className="max-w-6xl mx-auto p-4 md:p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                        {showMainTitle && (
                            <>
                                <h1 className="text-3xl md:text-4xl font-bold text-gray-900 dark:text-gray-100">{Htmllessons[0]?.title}</h1>
                                <p className="mt-2 md:mt-4 text-gray-700 dark:text-gray-300 text-base md:text-lg">
                                    {Htmllessons[0]?.description}
                                </p>
                            </>
                        )}

                        <div className="mt-8">
                            <h2 className="text-2xl md:text-3xl font-semibold text-gray-900 dark:text-gray-100">{topic?.title}</h2>
                            <h3 className="text-xl md:text-2xl font-semibold text-gray-900 dark:text-gray-100 mt-2 md:mt-4">{lesson?.title}</h3>
                            <p className="mt-2 text-gray-700 dark:text-gray-300 text-base md:text-lg">{lesson?.definition}</p>

                            <div className="mt-4">
                                <h4 className="text-lg md:text-xl font-semibold text-gray-900 dark:text-gray-100">Steps:</h4>
                                <ul className="list-disc list-inside mt-4 text-gray-700 dark:text-gray-300">
                                    {lesson?.steps.map((step, index) => (
                                        <li className='mt-2' key={index}># {step}</li>
                                    ))}
                                </ul>
                            </div>

                            <div className="mt-8 flex flex-col lg:flex-row lg:items-stretch">
                                {/* Code Editor */}
                                <div className="w-full lg:w-1/2 flex flex-col">
                                    <div className="flex items-center mb-2">
                                        <CodeBracketIcon className="h-6 w-6 text-gray-500 dark:text-gray-300 mr-2" />
                                        <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">Code Editor</span>
                                    </div>
                                    <div className="flex items-center justify-between p-4 bg-gray-200 dark:bg-gray-800 rounded-t-lg">
                                        {/* Code Type Toggle */}
                                        <div className="flex items-center">
                                            <button
                                                onClick={() => setSelectedCodeType('html')}
                                                className={`px-4 py-2 border rounded-l-lg ${selectedCodeType === 'html' ? 'bg-indigo-600 text-white' : 'bg-white text-gray-700'} border-r-0`}
                                            >
                                                HTML
                                            </button>
                                            <button
                                                onClick={() => setSelectedCodeType('css')}
                                                className={`px-4 py-2 border ${selectedCodeType === 'css' ? 'bg-indigo-600 text-white' : 'bg-white text-gray-700'} border-r-0`}
                                            >
                                                CSS
                                            </button>
                                            <button
                                                onClick={() => setSelectedCodeType('js')}
                                                className={`px-4 py-2 border rounded-r-lg ${selectedCodeType === 'js' ? 'bg-indigo-600 text-white' : 'bg-white text-gray-700'}`}
                                            >
                                                JS
                                            </button>
                                        </div>

                                        {/* Right Side Menu */}
                                        <div className="flex items-center space-x-2">
                                            <button onClick={toggleDarkMode} className="p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-700">
                                                {darkMode ? <SunIcon className="w-5 h-5 text-gray-500 dark:text-gray-300" /> : <MoonIcon className="w-5 h-5 text-gray-500 dark:text-gray-300" />}
                                            </button>
                                            <button onClick={toggleWordWrap} className="p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-700">
                                                <span className="sr-only">Toggle Word Wrap</span>
                                                {wordWrap ? <EyeIcon className="w-5 h-5 text-gray-500 dark:text-gray-300" /> : <EyeIcon className="w-5 h-5 text-gray-500 dark:text-gray-300" />}
                                            </button>
                                            <button onClick={saveCode} className="p-2 rounded-full hover:bg-gray-300 dark:hover:bg-gray-700">
                                                <FontAwesomeIcon icon={faDownload} className="w-5 h-5 text-gray-500 dark:text-gray-300" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <Editor
                                            height="400px"
                                            language={selectedCodeType}
                                            value={selectedCodeType === 'html' ? htmlCode : selectedCodeType === 'css' ? cssCode : jsCode}
                                            onChange={handleCodeChange}
                                            options={editorOptions}
                                        />
                                    </div>
                                </div>

                                {/* Output Preview */}
                                <div className="w-full lg:w-1/2 flex flex-col mt-4 lg:mt-0 lg:ml-4">
                                    <div className="flex items-center mb-2">
                                        <EyeIcon className="h-6 w-6 text-gray-500 dark:text-gray-300 mr-2" />
                                        <span className="text-lg font-semibold text-gray-900 dark:text-gray-100">Output Preview</span>
                                    </div>
                                    <iframe
                                        id="outputPreview"
                                        title="Output Preview"
                                        className="flex-1 border-2 border-gray-300 dark:border-gray-700 rounded-lg"
                                        style={{ height: '500px', width: '100%' }}
                                    />
                                    <div id="outputConsole" className="p-2  text-gray-900 dark:text-gray-100 h-100 overflow-y-auto border-t border-gray-300 dark:border-gray-600">
                                        {/* Console output will be appended here */}
                                    </div>
                                    <button
                                        onClick={handlePreviewClick}
                                        className="mt-2 p-2 bg-blue-600 text-white rounded-lg flex items-center"
                                    >
                                        <ArrowRightCircleIcon className="h-5 w-5 mr-2" />
                                        Open in New Window
                                    </button>
                                </div>
                            </div>
                        </div>


                        {lesson?.examples && lesson.examples.length > 0 && (
                            <section id="Examples" className="mt-12">
                                <h2 className="text-2xl md:text-3xl font-semibold text-gray-900 dark:text-gray-100">Examples</h2>
                                {/* <p className="mt-4 text-gray-700 dark:text-gray-300">
                                    In this section, we provide practical examples that demonstrate the application of advanced HTML techniques. Each example is designed to showcase how these techniques can be used effectively in real-world scenarios. Review the code snippets below to see these concepts in action and understand their practical utility in web development.
                                </p> */}
                                <div className="mt-6">
                                    {lesson.examples.map((example, index) => (
                                        <div key={index} className="mb-8 p-6 border border-gray-300 dark:border-gray-700 rounded-lg shadow-md dark:shadow-gray-900">
                                            {example.description && (
                                                <p className="mb-4 text-gray-700 dark:text-gray-300">{example.description}</p>
                                            )}
                                            {example.code && (
                                                <SyntaxHighlighter language="html" style={oneDark} className="mt-4 rounded-lg overflow-x-auto">
                                                    {example.code}
                                                </SyntaxHighlighter>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}






                        {/* Navigation Buttons */}
                        <div className="mt-8 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
                            <button
                                onClick={handlePrevLesson}
                                disabled={currentLessonIndex === 0}
                                className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-semibold transition-transform transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ArrowLeftCircleIcon className="w-5 h-5 inline-block mr-2" />
                                Previous Lesson
                            </button>
                            <button
                                onClick={handleNextLesson}
                                disabled={currentLessonIndex >= (topic?.lessons.length || 0) - 1}
                                className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-semibold transition-transform transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ArrowRightCircleIcon className="w-5 h-5 inline-block mr-2" />
                                Next Lesson
                            </button>
                        </div>
                        <div className="mt-4 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4">
                            <button
                                onClick={handlePrevTopic}
                                disabled={currentTopicIndex === 0}
                                className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-semibold transition-transform transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ArrowLeftCircleIcon className="w-5 h-5 inline-block mr-2" />
                                Previous Topic
                            </button>
                            <button
                                onClick={handleNextTopic}
                                disabled={currentTopicIndex >= (Htmllessons[0]?.topics.length || 0) - 1}
                                className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-semibold transition-transform transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <ArrowRightCircleIcon className="w-5 h-5 inline-block mr-2" />
                                Next Topic
                            </button>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default HtmlCourseViewPage;
