import React from 'react'
import {
    BookOpenIcon,
    ClockIcon

} from '@heroicons/react/24/outline';

const RelatedCourses = () => {
    return (
        <div className="mt-12 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold dark:text-white">Related Courses</h3>
            <div className="mt-4 space-y-4">
                <div className="flex items-start">
                    <BookOpenIcon className="h-6 w-6 text-indigo-500" />
                    <div className="ml-3">
                        <p className="font-semibold dark:text-white">Advanced JavaScript</p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Dive deeper into JavaScript with advanced techniques and best practices.
                        </p>
                        <button className="ml-3 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-700 rounded-full border border-indigo-600 shadow-md hover:shadow-xl hover:bg-gradient-to-r hover:from-indigo-600 hover:via-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center">
                            <span className="mr-2">
                                <ClockIcon className="h-5 w-5 text-white" />
                            </span>
                            Coming Soon
                        </button>
                    </div>
                </div>
                <div className="flex items-start">
                    <BookOpenIcon className="h-6 w-6 text-indigo-500" />
                    <div className="ml-3">
                        <p className="font-semibold dark:text-white">ReactJS for Beginners</p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Learn the fundamentals of ReactJS with hands-on projects and real-world examples.
                        </p>
                        <button className="ml-3 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-700 rounded-full border border-indigo-600 shadow-md hover:shadow-xl hover:bg-gradient-to-r hover:from-indigo-600 hover:via-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center">
                                <span className="mr-2">
                                    <ClockIcon className="h-5 w-5 text-white" />
                                </span>
                                Coming Soon
                            </button>
                    </div>
                </div>
                <div className="flex items-start">
                    <BookOpenIcon className="h-6 w-6 text-indigo-500" />
                    <div className="ml-3">
                        <p className="font-semibold dark:text-white">Full Stack Web Development</p>
                        <p className="text-gray-600 dark:text-gray-400">
                            Gain expertise in both front-end and back-end development with a comprehensive full stack course.
                        </p>
                        <button className="ml-3 px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-700 rounded-full border border-indigo-600 shadow-md hover:shadow-xl hover:bg-gradient-to-r hover:from-indigo-600 hover:via-indigo-700 hover:to-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-transform transform hover:scale-105 flex items-center">
                                <span className="mr-2">
                                    <ClockIcon className="h-5 w-5 text-white" />
                                </span>
                                Coming Soon
                            </button>
                    </div>
                </div>
            </div>
        </div>)
}

export default RelatedCourses