import React from 'react';

const InstructorInfo = () => {
    return (
        <div className="mt-12 bg-gradient-to-r from-white via-gray-100 to-gray-200 dark:from-gray-800 dark:via-gray-700 dark:to-gray-600bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg">
        <div className="flex-shrink-0 w-32 h-32 bg-indigo-500 rounded-full overflow-hidden border-4 border-white dark:border-gray-800">
            <img src="https://cdn.buymeacoffee.com/uploads/profile_pictures/2023/11/PIXxBAJGpUj1wI7F.png@300w_0e.webp" alt="Instructor" className="w-full h-full object-cover" />
        </div>
        <div className="lg:ml-8 mt-6 lg:mt-0 flex flex-col items-start">
            <h3 className="text-3xl font-semibold text-gray-900 dark:text-gray-100 flex items-center space-x-2">
                <svg className="w-6 h-6 text-indigo-500 dark:text-indigo-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6a4 4 0 00-4 4v4a4 4 0 004 4 4 4 0 004-4V10a4 4 0 00-4-4zM8 14h8M6 6h12M12 16v2m0-6v2m0 4h6M6 16h6"></path>
                </svg>
                <span>Muhilan J</span>
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mt-2 text-lg leading-relaxed">
                Founder of <span className="font-semibold text-indigo-600 dark:text-indigo-400">MuhilanOrg , Freelancer</span> and admin of <span className="font-semibold text-indigo-600 dark:text-indigo-400">CodeWithMuhilan</span> social media channels.
            </p>
            <div className="mt-4 space-y-2">
                <p className="flex items-center text-gray-600 dark:text-gray-400">
                    <svg className="w-5 h-5 text-indigo-500 dark:text-indigo-300 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10l4.293-4.293a1 1 0 00-1.414-1.414L13 8.586 8.707 4.293A1 1 0 007.293 5.707L12 10l-4.293 4.293a1 1 0 001.414 1.414L12 11.414l4.293 4.293a1 1 0 001.414-1.414L13 10z"></path>
                    </svg>
                    300,000+ Instagram Followers
                </p>
                <p className="flex items-center text-gray-600 dark:text-gray-400">
                    <svg className="w-5 h-5 text-indigo-500 dark:text-indigo-300 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 10h-5V6H6v8h4v4h7a5 5 0 005-5V8a5 5 0 00-5-5H9a5 5 0 00-5 5v6h5v-4h4v-4h4v4h-4v4z"></path>
                    </svg>
                    10 Million+ Global Fanbase
                </p>
                <p className="flex items-center text-gray-600 dark:text-gray-400">
                    <svg className="w-5 h-5 text-indigo-500 dark:text-indigo-300 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v18m9-9H3"></path>
                    </svg>
                    Mentored 5,000+ Students & Professionals
                </p>
            </div>
        </div>
    </div>

    )
}

export default InstructorInfo