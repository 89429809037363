import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { BriefcaseIcon, LightBulbIcon, LifebuoyIcon } from '@heroicons/react/24/outline';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { useInView } from 'react-intersection-observer';
import '../AboutBrand/AboutBrand.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  datasets: [
    {
      label: 'Client Growth',
      data: [10, 20, 15, 25, 30, 35, 40],
      borderColor: '#4F46E5',
      backgroundColor: 'rgba(79, 70, 229, 0.2)',
      fill: true,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `${context.dataset.label}: ${context.raw} clients`;
        },
      },
    },
  },
};

export default function AboutBrand() {
  const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: false });
  const { ref: highlightsRef, inView: highlightsInView } = useInView({ triggerOnce: false });
  const { ref: statsRef, inView: statsInView } = useInView({ triggerOnce: false });
  const { ref: graphRef, inView: graphInView } = useInView({ triggerOnce: false });
  const { ref: testimonialsRef, inView: testimonialsInView } = useInView({ triggerOnce: false });

  const titleControls = useAnimation();
  const highlightsControls = useAnimation();
  const statsControls = useAnimation();
  const graphControls = useAnimation();
  const testimonialsControls = useAnimation();

  React.useEffect(() => {
    if (titleInView) {
      titleControls.start({ opacity: 1, y: 0 });
    } else {
      titleControls.start({ opacity: 0, y: 20 });
    }
  }, [titleInView, titleControls]);

  React.useEffect(() => {
    if (highlightsInView) {
      highlightsControls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, ease: 'easeOut', delay: i * 0.2 },
      }));
    } else {
      highlightsControls.start({ opacity: 0, y: 20 });
    }
  }, [highlightsInView, highlightsControls]);

  React.useEffect(() => {
    if (statsInView) {
      statsControls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, ease: 'easeOut', delay: i * 0.2 },
      }));
    } else {
      statsControls.start({ opacity: 0, y: 20 });
    }
  }, [statsInView, statsControls]);

  React.useEffect(() => {
    if (graphInView) {
      graphControls.start({ opacity: 1, y: 0 });
    } else {
      graphControls.start({ opacity: 0, y: 20 });
    }
  }, [graphInView, graphControls]);

  React.useEffect(() => {
    if (testimonialsInView) {
      testimonialsControls.start(i => ({
        opacity: 1,
        y: 0,
        transition: { duration: 0.8, ease: 'easeOut', delay: i * 0.2 },
      }));
    } else {
      testimonialsControls.start({ opacity: 0, y: 20 });
    }
  }, [testimonialsInView, testimonialsControls]);

  return (
    <section className="relative py-16 sm:py-20 bg-gray-200 dark:bg-gray-900">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-center space-y-8 sm:space-y-12 lg:space-y-16">
          {/* Logo and Title */}
          <motion.div
            className="text-center"
            ref={titleRef}
            animate={titleControls}
            initial={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
          >
            <h2 className="text-3xl sm:text-4xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
              About MuhilanOrg
            </h2>
            <p className="text-base sm:text-lg mt-4 text-gray-600 dark:text-gray-300">
              Welcome to MuhilanOrg, a premier solopreneur company dedicated to offering top-notch training and development services in web development. 
              Our mission is to empower individuals and businesses by providing expert guidance and innovative solutions tailored to meet your specific needs.
            </p>
          </motion.div>

          {/* Professional Highlights */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 sm:gap-8" ref={highlightsRef}>
            {[
              {
                icon: <BriefcaseIcon className="h-12 w-12 text-indigo-600" />,
                title: 'Expert Training',
                description: 'Tailored training programs designed to enhance your web development skills and industry knowledge.',
              },
              {
                icon: <LightBulbIcon className="h-12 w-12 text-indigo-600" />,
                title: 'Innovative Solutions',
                description: 'Cutting-edge development practices and solutions to bring your web projects to life with modern technology.',
              },
              {
                icon: <LifebuoyIcon className="h-12 w-12 text-indigo-600" />,
                title: 'Dedicated Support',
                description: 'Comprehensive support and consultation to ensure the success and optimization of your web projects.',
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                className="flex items-start p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105"
                custom={index}
                animate={highlightsControls}
              >
                {item.icon}
                <div className="ml-4 sm:ml-6">
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">{item.title}</h4>
                  <p className="mt-2 text-sm sm:text-base text-gray-600 dark:text-gray-300">
                    {item.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          {/* Stats Section */}
          <div className="text-center mt-10 sm:mt-12" ref={statsRef}>
            <motion.h3
              className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white mb-6 sm:mb-8"
              animate={statsControls}
            >
              Our Achievements
            </motion.h3>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8">
              {[
                { value: '5,000+', label: 'Students Mentored' },
                { value: '200+', label: 'Clients Served' },
                { value: '10M+', label: 'Global Reach' },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="flex flex-col items-center p-4 sm:p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg"
                  custom={index}
                  animate={statsControls}
                >
                  <span className="text-2xl sm:text-3xl font-extrabold text-indigo-600">{item.value}</span>
                  <h4 className="text-sm sm:text-lg font-semibold text-gray-900 dark:text-white mt-1 sm:mt-2">{item.label}</h4>
                </motion.div>
              ))}
            </div>
          </div>

          {/* Graph Section */}
          <div className="text-center mt-10 sm:mt-12" ref={graphRef}>
            <motion.h3
              className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white mb-6 sm:mb-8"
              animate={graphControls}
            >
              Client Growth Over Time
            </motion.h3>
            <motion.div animate={graphControls} className="max-w-3xl mx-auto">
              <Line data={data} options={options} />
            </motion.div>
          </div>

          {/* Testimonials Section */}
          <div className="text-center mt-10 sm:mt-12" ref={testimonialsRef}>
            <motion.h3
              className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white mb-6 sm:mb-8"
              animate={testimonialsControls}
            >
              What Our Clients Say
            </motion.h3>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8">
              {[
                {
                  name: 'Jane Doe',
                  feedback: 'MuhilanOrg has revolutionized the way I approach web development. The training programs are top-notch!',
                  role: 'Software Engineer',
                },
                {
                  name: 'John Smith',
                  feedback: 'I had an amazing experience working with MuhilanOrg. They provided excellent solutions and support for my web projects.',
                  role: 'Tech Entrepreneur',
                },
                {
                  name: 'Emily Johnson',
                  feedback: 'The level of expertise and dedication at MuhilanOrg is unmatched. Highly recommend their services!',
                  role: 'Freelance Developer',
                },
              ].map((item, index) => (
                <motion.div
                  key={index}
                  className="flex flex-col items-center p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:scale-105"
                  custom={index}
                  animate={testimonialsControls}
                >
                  <p className="text-sm sm:text-base text-gray-600 dark:text-gray-300 mb-4">
                    "{item.feedback}"
                  </p>
                  <h4 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">{item.name}</h4>
                  <p className="text-sm sm:text-base text-indigo-600">{item.role}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
