import React, { useEffect, useState } from 'react';
import { doc, getDoc } from '../../config/config'; // Ensure this is the correct path
import { db } from '../../config/config'; // Ensure this is the correct path

import ParticlesBackground from './ParticlesBackground';
import '../HomeMain/HomeMain.css';
import { IoIosArrowForward } from 'react-icons/io';
import MainLogoCarousel from '../MainLogoCarousel/MainLogoCarousel';
import { motion } from 'framer-motion';

const HomeMain = () => {
    const [content, setContent] = useState(null);

    useEffect(() => {
        const fetchContent = async () => {
            const contentRef = doc(db, 'homeContent', 'mainContent');
            const contentSnap = await getDoc(contentRef);

            if (contentSnap.exists()) {
                setContent(contentSnap.data());
            } else {
                console.log("No such document!");
            }
        };

        fetchContent();
    }, []);

    if (!content) {
        return null; // Change from `return` to `return null` to avoid rendering an empty component
    }

    return (
        <div className="relative isolate overflow-hidden bg-white dark:bg-black hide-cursor">
            <ParticlesBackground />
            <div className="relative px-6 pt-0 lg:px-8">
                <div
                    aria-hidden="true"
                    className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                    <div
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#93dbff] to-[#00fffc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                    />
                </div>
                <div className="mx-auto max-w-2xl py-32 sm:py-38 lg:py-56">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                        <motion.div
                            initial={{ opacity: 0, y: 30 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.6, ease: "easeOut" }}
                            className="relative rounded-full px-3 py-1 text-sm leading-6 ring-1 text-black dark:text-white"
                        >
                            {content.announcement}{' '}
                            <a href="/Ebook" className="font-semibold text-indigo-600">
                                <span aria-hidden="true" className="absolute inset-0" />
                                Read more <span aria-hidden="true">&rarr;</span>
                            </a>
                        </motion.div>
                    </div>
                    <motion.div
                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        <div className="flex justify-center">
                            <motion.h1
                                id="Home-Main-Headings"
                                className="text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-6xl text-center"
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.8, ease: "easeOut" }}
                            >
                                <span className="Main-Grad-Text">{content.title}</span>
                            </motion.h1>
                        </div>

                        <motion.p
                            id="Home-Main-Headings"
                            className="mt-6 text-lg leading-8 text-center text-gray-600 dark:text-gray-400"
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.8, ease: "easeOut" }}
                        >
                            {content.description}
                        </motion.p>
                        <div className="mt-10 flex flex-col sm:flex-row sm:items-center sm:justify-center gap-4">
                            <motion.a
                                href="/Ebook"
                                id="Regular-Btn"
                                className="flex items-center justify-center text-center rounded-md px-6 py-3 text-sm font-semibold text-black bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-400 transition duration-300 ease-in-out shadow-lg w-full sm:w-[calc(50%-0.5rem)]"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, ease: "easeOut", delay: 0.2 }}
                            >
                                {content.button1Text} <IoIosArrowForward className="ml-2" />
                            </motion.a>
                            <motion.a
                                href="/Shop"
                                id="Regular-Btn"
                                className="flex items-center justify-center text-center rounded-md px-6 py-3 text-sm font-semibold text-black bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-400 transition duration-300 ease-in-out shadow-lg w-full sm:w-[calc(50%-0.5rem)]"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, ease: "easeOut", delay: 0.4 }}
                            >
                                {content.button2Text} <IoIosArrowForward className="ml-2" />
                            </motion.a>
                        </div>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.8, ease: "easeOut", delay: 0.6 }}
                        >
                            <MainLogoCarousel />
                        </motion.div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default HomeMain;
