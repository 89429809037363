import React, { useState, useEffect } from 'react';
import HomeMain from '../Components/HomeMain/HomeMain';
import HomeShowCase from '../Components/HomeShowCase/HomeShowCase';
// import FeaturedProducts from '../Components/HomeFeatureProduct/HomeFeatureProduct';
import HomeFeatureProjects from '../Components/HomeFeatureProduct/HomeFeatureProduct';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/config';
import HomeServices from '../Components/HomeServices/HomeServices';
import AboutMe from '../Components/AboutMe/AboutMe';
import AboutBrand from '../Components/AboutBrand/AboutBrand';

const Home = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Products'));
                const productsArray = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    return {
                        id: doc.id,
                        title: data.name || 'Untitled',
                        category: data.category || 'Unknown',
                        description: data.description || '',
                        image: data.image || '',
                        link: data.link || '',
                        price: typeof data.price === 'number' ? data.price : parseFloat(data.price) || 0,
                        ...data
                    };
                });
                setProducts(productsArray);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <div className="relative bg-white dark:bg-black shadow-lg">
            <HomeMain />

            <div className='grid gap-20'>
                <HomeShowCase />
                {/* <FeaturedProducts products={products} /> */}
                <HomeFeatureProjects  />
                <AboutMe />
                <AboutBrand />
                <HomeServices />

            </div>
        </div>
    );
}

export default Home;
