import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCircle, FaCog, FaSignOutAlt, FaBox } from 'react-icons/fa';
import { motion } from 'framer-motion';

const UserPopover = ({ user, popoverOpen, setPopoverOpen, handleLogout }) => {
  const popoverRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        setPopoverOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside); // Add touchstart for mobile devices
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside); // Remove it during cleanup
    };
  }, [setPopoverOpen]);


  return (
    <div className="relative" ref={popoverRef}>
      <button
        onClick={() => setPopoverOpen(!popoverOpen)}
        className="flex items-center space-x-3 p-2 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-300"
      >
        {user.photoURL ? (
          <img
            src={user.photoURL}
            alt="User Profile"
            className="h-10 w-10 rounded-full border-2 border-gray-300 dark:border-gray-600"
          />
        ) : (
          <FaUserCircle className="h-10 w-10 rounded-full border-2 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-300" />
        )}

        {/* <span className="text-gray-900 dark:text-gray-100 text-sm font-semibold">{user.displayName || 'No Name'}</span> */}
      </button>
      {popoverOpen && (
        <motion.div
          className="absolute right-0 mt-2 w-72 max-w-xs bg-white border border-gray-300 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700 z-50"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
          style={{ zIndex: 9999 }} // Ensure the popover stays on top
        >
          <div className="p-4 flex items-center space-x-4 border-b border-gray-300 dark:border-gray-700">
            {user.photoURL ? (
              <img
                src={user.photoURL}
                alt="User Profile"
                className="h-16 w-16 rounded-full border-2 border-gray-300 dark:border-gray-600"
              />
            ) : (
              <FaUserCircle className="text-gray-800 dark:text-gray-300 text-4xl" />
            )}
            <div className="flex flex-col">
              <p className="text-xl font-semibold text-gray-900 dark:text-gray-100">{user.displayName || 'User'}</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">{user.email || 'No email'}</p>
            </div>
          </div>
          <div className="py-2 flex flex-col">
            <Link
              to="/MyProducts"
              className="flex items-center px-4 py-3 text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 rounded-md"
            >
              <FaBox className="mr-3 text-gray-600 dark:text-gray-400 text-xl" />
              <span className="text-sm font-medium">My Products</span>
            </Link>
            {/* <Link
              to="/Settings"
              className="flex items-center px-4 py-3 text-gray-900 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-300 rounded-md"
            >
              <FaCog className="mr-3 text-gray-600 dark:text-gray-400 text-xl" />
              <span className="text-sm font-medium">Settings</span>
            </Link> */}
            <button
              style={{ zIndex: '999' }}
              onClick={handleLogout}
              className="flex items-center w-full px-4 py-3 text-left text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900 transition-colors duration-300 rounded-md"
            >
              <FaSignOutAlt className="mr-3 text-red-600 dark:text-red-400 text-xl" />
              <span className="text-sm font-medium"
              >Logout</span>
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default UserPopover;
