import React, { useState, useEffect, useRef } from 'react';
import { Container, InputAdornment, TextField, Typography, Grid, Card, CardContent, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ProjectCard from '../ProjectCard/ProjectCard';
import ProjectSidebar from '../ProjectSidebar/ProjectSidebar';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/config';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../config/config';
import LoaderSpinner from '../LoaderSpinner/LoaderSpinner';

const ProjectPage = () => {
    const [user] = useAuthState(auth);
    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState({ matched: [], unmatched: [] });
    const [visibleProjects, setVisibleProjects] = useState([]);  // To store visible projects for infinite scroll
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false); // For showing loader while fetching more projects
    const [error, setError] = useState(null);
    const [filterCategory, setFilterCategory] = useState('');
    const [sortOption, setSortOption] = useState('');
    const [page, setPage] = useState(1);  // Pagination state to track current page
    const loadMoreRef = useRef(null); // Ref to track scroll event

    const PROJECTS_PER_PAGE = 9;  // Define how many projects to load per scroll

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'projects'));
                const fetchedProjects = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setProjects(fetchedProjects);
                setFilteredProjects({ matched: fetchedProjects, unmatched: [] });
                setVisibleProjects(fetchedProjects.slice(0, PROJECTS_PER_PAGE));  // Load initial 9 projects
            } catch (err) {
                setError('Failed to fetch projects');
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    useEffect(() => {
        const getFilteredProjects = () => {
            let matched = projects;
            let unmatched = [];

            // Apply filtering based on searchTerm and filterCategory
            if (searchTerm || filterCategory) {
                matched = projects.filter(project => {
                    const title = project.title || '';
                    const matchesSearch = title.toLowerCase().includes(searchTerm.toLowerCase());
                    const matchesCategory = !filterCategory || project.category === filterCategory;
                    return matchesSearch && matchesCategory;
                });

                unmatched = projects.filter(project => {
                    const title = project.title || '';
                    const matchesSearch = title.toLowerCase().includes(searchTerm.toLowerCase());
                    const matchesCategory = !filterCategory || project.category === filterCategory;
                    return !matchesSearch || !matchesCategory;
                });
            }

            // Apply sorting to matched projects
            if (sortOption) {
                matched.sort((a, b) => {
                    switch (sortOption) {
                        case 'Most Recent':
                            return new Date(b.date) - new Date(a.date);
                        case 'Highest Rated':
                            return b.rating - a.rating;
                        case 'Price: High to Low':
                            return b.price - a.price;
                        case 'Price: Low to High':
                            return a.price - b.price;
                        default:
                            return 0;
                    }
                });
            }

            return { matched, unmatched };
        };

        const { matched, unmatched } = getFilteredProjects();
        setFilteredProjects({ matched, unmatched });
        setVisibleProjects(matched.slice(0, page * PROJECTS_PER_PAGE));  // Load projects per page
    }, [searchTerm, filterCategory, sortOption, projects, page]);

    useEffect(() => {
        const handleScroll = () => {
            if (loadMoreRef.current) {
                const { bottom } = loadMoreRef.current.getBoundingClientRect();
                if (bottom <= window.innerHeight) {
                    loadMoreProjects();
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [projects, page]);

    const loadMoreProjects = () => {
        if (loadingMore) return; // Prevent multiple calls if already loading

        if (filteredProjects.matched.length > visibleProjects.length) {
            setLoadingMore(true);
            setTimeout(() => {
                setPage(prevPage => prevPage + 1);
                setLoadingMore(false);
            }, 500); // Simulate a loading delay of 1 second
        }
    };

    const handleSearchClear = () => {
        setSearchTerm('');
    };

    if (loading) {
        return (
            <Container>
                <div className="flex justify-center items-center h-screen">
                    <LoaderSpinner /> {/* Show loading spinner */}
                </div>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <div className="flex justify-center items-center h-screen text-red-500">
                    <Typography color="error">Error loading projects: {error}</Typography> {/* Display error message */}
                </div>
            </Container>
        );
    }

    const hasMatchedProjects = filteredProjects.matched.length > 0;
    const hasUnmatchedProjects = filteredProjects.unmatched.length > 0;

    return (
        <div className="relative top-10 flex flex-col container mx-auto max-w-6xl w-full sm:w-[90%]">
            {/* Sidebar as an overlay */}
            <ProjectSidebar
                onFilterChange={setFilterCategory}
                onSortChange={setSortOption}
            />

            {/* Main content area */}
            <div className="relative top-10 flex-1">
                <Typography variant="h4" gutterBottom className="mb-6 text-gray-900 dark:text-gray-100">
                    Projects
                </Typography>

                {/* Search Bar */}
                <TextField
                    variant="outlined"
                    placeholder="Search projects..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon color="action" />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            searchTerm && (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleSearchClear}>
                                        <ClearIcon color="action" />
                                    </IconButton>
                                </InputAdornment>
                            )
                        ),
                    }}
                    fullWidth
                    sx={{ mb: 4, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1 }}
                />

                {/* Projects Grid */}
                {hasMatchedProjects ? (
                    <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                        {visibleProjects.map((project) => (
                            <Grid item key={project.id} xs={12} sm={6} md={4} lg={3} xl={3}>
                                <Link to={`/Shop/${project.id}`} style={{ textDecoration: 'none' }}>
                                    <div className="flex flex-col h-full">
                                        <ProjectCard project={project} className="flex flex-col justify-between h-full" />
                                    </div>
                                </Link>
                            </Grid>
                        ))}
                    </div>
                ) : (
                    <Container>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" align="center">
                                    No matched projects found
                                </Typography>
                            </CardContent>
                        </Card>
                    </Container>
                )}

                {/* Loader for more projects */}
                {loadingMore && (
                    <div className="flex justify-center py-4">
                        <LoaderSpinner />
                    </div>
                )}

                {/* Display unmatched projects at the bottom */}
                {hasUnmatchedProjects && (
                    <Container className="mt-2">
                        <Typography variant="h6" gutterBottom className="mb-4 text-gray-900 dark:text-gray-100">
                            Related Projects
                        </Typography>
                        <div className="grid gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                            {filteredProjects.unmatched.map((project) => (
                                <Grid item key={project.id} xs={12} sm={6} md={4} lg={3} xl={3}>
                                    <Link to={`/Shop/${project.id}`} style={{ textDecoration: 'none' }}>
                                        <div className="flex flex-col h-full">
                                            <ProjectCard project={project} className="flex flex-col justify-between h-full" />
                                        </div>
                                    </Link>
                                </Grid>
                            ))}
                        </div>
                    </Container>
                )}

                {/* Ref for detecting the bottom of the list */}
                <div ref={loadMoreRef} className="py-4"></div>

                {/* Footer Space */}
                <div className="h-24 bg-gray-200 dark:bg-gray-900 mt-auto" />
            </div>
        </div>
    );
};

export default ProjectPage;









