import React from 'react';
import { motion } from 'framer-motion';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import muhilanOrgLogo from '../Components/Assets/MuhilanOrgLogoDark.svg'; // Replace with actual path

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const data = {
  labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
  datasets: [
    {
      label: 'Client Growth',
      data: [10, 20, 15, 25, 30, 35, 40],
      borderColor: '#4F46E5',
      backgroundColor: 'rgba(79, 70, 229, 0.2)',
      fill: true,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          return `${context.dataset.label}: ${context.raw} clients`;
        },
      },
    },
  },
};

export default function About() {
  return (
    <section className="relative py-20 bg-gray-700 dark:bg-gray-900">
      <div className="relative max-w-7xl mx-auto px-8 lg:px-12">
        <div className="flex flex-col items-center space-y-16">
          {/* Logo and Title */}
          <motion.div
            className="text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: 'easeOut' }}
          >
            <img src={muhilanOrgLogo} alt="MuhilanOrg Logo" className="mx-auto h-24 w-auto mb-8" />
            <h2 className="text-5xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white mt-6">
              About MuhilanOrg
            </h2>
            <p className="text-xl mt-6 text-gray-600 dark:text-gray-300 max-w-4xl mx-auto">
              Welcome to MuhilanOrg, a premier solopreneur company dedicated to offering top-notch training and development services in web development. 
              Our mission is to empower individuals and businesses by providing expert guidance and innovative solutions tailored to meet your specific needs.
            </p>
            <p className="text-xl text-gray-600 dark:text-gray-300 max-w-4xl mx-auto mt-4">
              At MuhilanOrg, we specialize in a wide range of web development services, from comprehensive training programs to hands-on development projects. 
              Whether you're looking to enhance your skills, launch a new web project, or optimize your existing online presence, we are here to support you every step of the way.
            </p>
          </motion.div>

          {/* Mission and Vision */}
          <div className="text-center mt-16">
            <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-12">Our Mission & Vision</h3>
            <div className="max-w-6xl mx-auto">
              <div className="bg-white dark:bg-gray-800 p-10 rounded-lg shadow-lg">
                <h4 className="text-2xl font-semibold text-gray-900 dark:text-white">Mission</h4>
                <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
                  Our mission is to empower individuals and organizations by providing world-class web development training and solutions that drive growth and innovation.
                </p>
                <h4 className="text-2xl font-semibold text-gray-900 dark:text-white mt-10">Vision</h4>
                <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
                  We envision a world where every individual and business has the skills and resources to thrive in the digital landscape, creating impactful and meaningful web experiences.
                </p>
              </div>
            </div>
          </div>

          {/* Team Section */}
          <div className="text-center mt-16">
            <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-12">Meet Our Team</h3>
            <div className="flex flex-wrap justify-center gap-12">
              {/* Team Member 1 */}
              <div className="flex flex-col items-center max-w-xs p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <img src="path/to/team-member1.jpg" alt="Team Member 1" className="h-28 w-28 rounded-full object-cover mb-6" />
                <h4 className="text-xl font-semibold text-gray-900 dark:text-white">John Doe</h4>
                <p className="text-gray-600 dark:text-gray-300">CEO & Founder</p>
                <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
                  John is the visionary behind MuhilanOrg, leading with a passion for innovation and excellence in web development.
                </p>
              </div>
              {/* Team Member 2 */}
              <div className="flex flex-col items-center max-w-xs p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
                <img src="path/to/team-member2.jpg" alt="Team Member 2" className="h-28 w-28 rounded-full object-cover mb-6" />
                <h4 className="text-xl font-semibold text-gray-900 dark:text-white">Jane Smith</h4>
                <p className="text-gray-600 dark:text-gray-300">Lead Developer</p>
                <p className="mt-4 text-lg text-gray-600 dark:text-gray-300">
                  Jane brings expertise in modern web technologies and a track record of successful projects and solutions.
                </p>
              </div>
            </div>
          </div>

          {/* Graph Section */}
          <div className="text-center mt-16">
            <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-12">Client Growth Over Time</h3>
            <div className="w-full max-w-4xl mx-auto">
              <Line data={data} options={options} />
            </div>
          </div>

          {/* Testimonials */}
          <div className="text-center mt-16">
            <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-12">What Our Clients Say</h3>
            <div className="flex flex-col items-center space-y-8">
              <blockquote className="text-lg text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-3xl">
                “MuhilanOrg transformed our web presence with their expert guidance and innovative solutions. Highly recommend!”
                <footer className="mt-4 text-gray-500 dark:text-gray-400">- Client Name, Position</footer>
              </blockquote>
              <blockquote className="text-lg text-gray-700 dark:text-gray-300 bg-gray-100 dark:bg-gray-800 p-8 rounded-lg shadow-lg max-w-3xl">
                “The training programs offered were top-notch and provided real value. Our team’s skills have improved significantly.”
                <footer className="mt-4 text-gray-500 dark:text-gray-400">- Another Client, Position</footer>
              </blockquote>
            </div>
          </div>

          {/* Contact Information */}
          <div className="text-center mt-16">
            <h3 className="text-3xl font-bold text-gray-900 dark:text-white mb-12">Get In Touch</h3>
            <div className="bg-white dark:bg-gray-800 p-10 rounded-lg shadow-lg max-w-lg mx-auto">
              <h4 className="text-2xl font-semibold text-gray-900 dark:text-white">Contact Us</h4>
              <form className="mt-8 space-y-6">
                <input
                  type="text"
                  placeholder="Your Name"
                  className="w-full p-4 border border-gray-300 dark:border-gray-700 rounded-lg"
                />
                <input
                  type="email"
                  placeholder="Your Email"
                  className="w-full p-4 border border-gray-300 dark:border-gray-700 rounded-lg"
                />
                <textarea
                  placeholder="Your Message"
                  rows="5"
                  className="w-full p-4 border border-gray-300 dark:border-gray-700 rounded-lg"
                ></textarea>
                <button
                  type="submit"
                  className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold hover:bg-indigo-700 transition-all"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
